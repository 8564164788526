import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_CITY_RESOURCES,
  GET_COUNTRY_RESOURCES,
  GET_DISTRICT_RESOURCES,
} from "./actionTypes";

import {
  getCitiesError,
  getCitiesSuccess,
  getCountriesError,
  getCountriesSuccess,
  getDistrictsError,
  getDistrictsSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import { getCities, getCountries, getDistricts } from "helpers/Api/locationApi";

function* fetchCountries() {
  try {
    const response = yield call(getCountries);
    if (response.success) {
      const countries = response.body.map((country, i) => {
        return {
          label: country.label,
          code: country.dialCode,
          value: country.id,
          key: country.id,
        };
      });
      yield put(getCountriesSuccess(countries));
    } else {
      yield put(getCountriesError(response.message));
    }
  } catch (error) {
    yield put(getCountriesError(error));
  }
}

function* fetchCities() {
  try {
    const response = yield call(getCities);
    if (response.success) {
      const cities = response.body.map((city, i) => {
        return { label: city.label, value: city.id };
      });
      yield put(getCitiesSuccess(cities));
    } else {
      yield put(getCitiesError(response.message));
    }
  } catch (error) {
    yield put(getCitiesError(error));
  }
}

function* fetchDistricts() {
  try {
    const response = yield call(getDistricts);
    if (response.success) {
      const districts = response.body.map((city, i) => {
        return { label: city.label, value: city.id };
      });
      yield put(getDistrictsSuccess(districts));
    } else {
      yield put(getDistrictsError(response.message));
    }
  } catch (error) {
    yield put(getDistrictsError(error));
  }
}

function* locationSaga() {
  yield takeEvery(GET_COUNTRY_RESOURCES, fetchCountries);
  yield takeEvery(GET_CITY_RESOURCES, fetchCities);
  yield takeEvery(GET_DISTRICT_RESOURCES, fetchDistricts);
}

export default locationSaga;
