import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getWithdrawalRequests } from "../../store/wallet/actions";
// datatable related plugins
import "./datatables.scss";
import React, { Component } from "react";
import { Badge, Card, CardBody, CardTitle, Col, Row } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { normalizeDate } from "../../helpers/core_helper";
import Balance from "../../components/Saver/Balance";

class WithdrawalRequestsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      formModal: false,
      formModalEdit: false,
      showInfo: false,
    };
  }

  componentDidMount() {
    const { onGetRequests } = this.props;
    onGetRequests();
  }

  render() {
    const { SearchBar } = Search;

    let columns = [
      {
        dataField: "id",
        text: "#Ref",
        sort: true,
        formatter: (cellContent, row, idx) => (
          <>
            <span className="text-body fw-bold">#WIR{row.id}</span>
          </>
        ),
      },
      {
        dataField: "amount",
        text: this.props.t("Amount"),
        formatter: (cellContent, row) => {
          return Math.abs(row.amount);
        },
        sort: true,
      },
      {
        dataField: "state",
        text: this.props.t("State"),
        sort: true,
        formatter: (cellContent, row) => {
          let color = "danger";
          switch (row.state) {
            case "PENDING":
              color = "warning";
              break;
            case "ACCEPTED":
              color = "success";
              break;
          }
          return (
            <Badge
              className={"font-size-12 badge-soft-" + color}
              color={color}
              pill
            >
              {this.props.t(row.state)}
            </Badge>
          );
        },
      },
      {
        dataField: "deniedReason",
        text: this.props.t("Denied reason"),
        sort: false,
      },
      {
        dataField: "createdAt",
        text: this.props.t("Created at"),
        sort: true,
        formatter: (cellContent, row) => normalizeDate(row.createdAt),
      },
    ];

    const defaultSorted = [
      {
        dataField: "createdAt",
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.requests.length, // replace later with size(customers),
      custom: true,
    };

    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="float-end">
                  <Balance />
                </div>
                <CardTitle className="h4">
                  {this.props.t("My withdrawal request")}
                </CardTitle>
                <p className="card-title-desc"> .</p>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={this.state.requests}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={this.props.requests}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  //selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

WithdrawalRequestsTable.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  t: PropTypes.any,
  requests: PropTypes.any,
  onGetRequests: PropTypes.func,
};

const mapStateToProps = state => ({
  requests: state.Wallet.withdrawalRequests,
  loading: state.Wallet.loading,
  error: state.Wallet.error,
});
const mapDispatchToProps = dispatch => ({
  onGetRequests: () => dispatch(getWithdrawalRequests()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(WithdrawalRequestsTable))
);
