import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

//i18n
import { withTranslation } from "react-i18next";
import { isVendor } from "../../helpers/session_helper";

class VendorMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!isVendor()) {
      return "";
    }

    return (
      <React.Fragment>
        <li>
          <Link to="/#" className="has-arrow">
            <i className="bx bx-wallet" />
            <span>{this.props.t("Wallet")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/wallet/history">
                {this.props.t("Transaction History")}
              </Link>
            </li>
            <li>
              <Link to="/wallet/withdrawal-requests">
                {this.props.t("My withdrawal request")}
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <i className="bx bx-store" />
            <span>{this.props.t("XPayLater")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="k">{this.props.t("Products")}</Link>
            </li>
            <li>
              <Link to="/xpaylater/product/add">
                {this.props.t("Add Product")}
              </Link>
            </li>
            <li>
              <Link to="k">{this.props.t("Orders")}</Link>
            </li>
            <li>
              <Link to="l">{this.props.t("Cart")}</Link>
            </li>
            <li>
              <Link to="/xpaylater/products">{this.props.t("Shops")}</Link>
            </li>
          </ul>
        </li>
      </React.Fragment>
    );
  }
}

VendorMenu.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(VendorMenu));
