import {
  API_FAIL,
  API_SUCCESS,
  GET_CHARTS_DATA,
  GET_CLIENT_DASHBOARD,
  GET_CLIENT_DASHBOARD_FAIL,
  GET_CLIENT_DASHBOARD_SUCCESS,
  GET_MICROFINANCE_DASHBOARD,
  GET_MICROFINANCE_DASHBOARD_FAIL,
  GET_MICROFINANCE_DASHBOARD_SUCCESS,
  GET_SAVER_DASHBOARD,
  GET_SAVER_DASHBOARD_FAIL,
  GET_SAVER_DASHBOARD_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  chartsData: {
    deposit: "loading",
    withdrawal: "loading",
    balanceSinceLastMonthDifference: 0,
    balanceSinceLastMonthDifferencePercent: 0,
    balance: 0,
    collectors: "loading",
    customers: "loading",
    chartInfo: {
      series: [
        {
          type: "area",
          name: "XPayLater",
          data: []
        },
        {
          type: "area",
          name: "Dépôt",
          data: []
        },
        {
          type: "line",
          name: "Retrait",
          data: []
        }
      ],
      xaxis: null,
      months:[
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]
    },
    xpayLater: "loading"
  },
  loading: false
};

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            chartsData: action.payload.data
          };

        default:
          return state;
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_CHARTS_DATA:
          return {
            ...state,
            earningChartDataError: action.payload.error
          };

        default:
          return state;
      }
    case GET_SAVER_DASHBOARD:
      return {
        ...state,
        loading: true
      };

    case GET_SAVER_DASHBOARD_SUCCESS:
      return {
        ...state,
        chartsData: action.payload,
        loading: false
      };

    case GET_SAVER_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case GET_CLIENT_DASHBOARD:
      return {
        ...state,
        loading: true,
        phone: action.payload
      };

    case GET_CLIENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        chartsData: action.payload,
        loading: false
      };

    case GET_CLIENT_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case GET_MICROFINANCE_DASHBOARD:
      return {
        ...state,
        loading: true
      };

    case GET_MICROFINANCE_DASHBOARD_SUCCESS:
      return {
        ...state,
        chartsData: action.payload,
        loading: false
      };

    case GET_MICROFINANCE_DASHBOARD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default Dashboard;
