import {
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_CLAIM,
  VERIFY_ACCOUNT_CLAIM_SUCCESS,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_FAILURE,
} from "./actionTypes";

export const verifyError = error => {
  return {
    type: VERIFY_FAILURE,
    payload: error,
  };
};

export const verifyAccountClaim = username => {
  return {
    type: VERIFY_ACCOUNT_CLAIM,
    payload: { username },
  };
};

export const verifyAccount = (username, code, history) => {
  return {
    type: VERIFY_ACCOUNT,
    payload: { username, code, history },
  };
};

export const verifyAccountClaimSuccess = message => {
  return {
    type: VERIFY_ACCOUNT_CLAIM_SUCCESS,
    payload: message,
  };
};

export const verifyAccountSuccess = message => {
  return {
    type: VERIFY_ACCOUNT_SUCCESS,
    payload: message,
  };
};
