export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESSFUL = "REGISTER_USER_SUCCESSFUL";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";

export const ACCOUNT_VERIFICATION = "ACCOUNT_VERIFICATION";
export const ACCOUNT_VERIFICATION_SUCCESSFUL =
  "ACCOUNT_VERIFICATION_SUCCESSFUL";
export const ACCOUNT_VERIFICATION_FAILED = "ACCOUNT_VERIFICATION_FAILED";

export const REGISTER_MICROFINANCE = "REGISTER_MICROFINANCE";
export const REGISTER_MICROFINANCE_SUCCESSFUL =
  "REGISTER_MICROFINANCE_SUCCESSFUL";
export const REGISTER_MICROFINANCE_FAILED = "REGISTER_MICROFINANCE_FAILED";

export const REGISTER_VENDOR = "REGISTER_VENDOR";
