import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { getAccount, getAccountName } from "../../helpers/session_helper";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getBalance } from "../../store/wallet/actions";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

class WalletStatsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search) ;


      this.setState({
        username: params.get("username"),
        name: params.get("name"),
      });
    this.toggleSubscribeModal.bind(this);
  }

  toggleSubscribeModal = () => {
    this.setState(prevState => ({
      subscribeModal: !prevState.subscribeModal,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="d-flex align-items-start">
              <div className="me-4">
                <i className="mdi mdi-account-circle text-primary h1" />
              </div>

              <div className="flex-grow-1">
                <div className="text-muted">
                  <h5>{this.state.name}</h5>
                  <p className="mb-1">{this.state.username}</p>
                  {/*<p className="mb-0">Id no: {this.props.wallet.id}</p>*/}
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody className="border-top">
            <Row>
              <Col sm="6">
                <div>
                  <p className="text-muted mb-2">
                    {this.props.t("Balance available")}
                  </p>
                  <h5>XAF {this.props.wallet.balance}</h5>
                </div>
              </Col>
              <Col sm="6">
                <div className="text-sm-end mt-4 mt-sm-0">
                  <p className="text-muted mb-2">Since last month</p>
                  <h5>
                    XAF{" "}
                    {this.props.wallet.balanceSinceLastMonthDifference > 0
                      ? "+"
                      : ""}{" "}
                    {this.props.wallet.balanceSinceLastMonthDifference}
                    {"  "}
                    <span
                      className={
                        "badge" +
                        (this.props.wallet.balanceSinceLastMonthDifference > 0
                          ? " bg-success"
                          : "bg-danger") +
                        " ms-1 align-bottom"
                      }
                    >
                      {this.props.wallet.balanceSinceLastMonthDifference > 0
                        ? "+"
                        : "-"}{" "}
                      {this.props.wallet.balanceSinceLastMonthDifferencePercent}{" "}
                      %
                    </span>
                  </h5>
                </div>
              </Col>
            </Row>
          </CardBody>

          <CardBody className="border-top">
            <p className="text-muted mb-4">In this month</p>
            <div className="text-center">
              <Row>
                <Col sm="4">
                  <div>
                    <div className="font-size-24 text-primary mb-2">
                      <i className="bx bx-money" />
                    </div>

                    <p className="text-muted mb-2">XPayLater</p>
                    <h5>XAF {this.props.wallet.xpayLater}</h5>

                  </div>
                </Col>
                <Col sm="4">
                  <div className="mt-4 mt-sm-0">
                    <div className="font-size-24 text-primary mb-2">
                      <i className="bx bx-import" />
                    </div>

                    <p className="text-muted mb-2">
                      {" "}
                      {this.props.t("Deposit")}
                    </p>
                    <h5>XAF {this.props.wallet.deposit}</h5>

                  </div>
                </Col>
                <Col sm="4">
                  <div className="mt-4 mt-sm-0">
                    <div className="font-size-24 text-primary mb-2">
                      <i className="bx bx-export" />
                    </div>

                    <p className="text-muted mb-2">
                      {this.props.t("Withdrawal")}
                    </p>
                    <h5>XAF {this.props.wallet.withdrawal}</h5>

                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

WalletStatsDetail.propTypes = {
  t: PropTypes.any,
  wallet: PropTypes.any,
  name: PropTypes.any,
  username: PropTypes.any,
  isMenu: PropTypes.bool,
  loading: PropTypes.bool,
  toggleMenu: PropTypes.func,
  balance: PropTypes.any,
  subscribeError: PropTypes.any,
  onGetBalance: PropTypes.func,
  subscribeManager: PropTypes.func,
};

const mapStateToProps = state => ({
  balance: state.Wallet.balance,
  subscribeError: state.Manager.error,
  loading: state.Manager.loading,
});

const mapDispatchToProps = dispatch => ({
  onGetBalance: () => dispatch(getBalance()),
  subscribeManager: data => dispatch(subscribe(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WalletStatsDetail))
);
