import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

class ButtonLoader extends Component {
  getLoad() {
    return this.props.loading ? (
      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
    ) : (
      ""
    );
  }

  render() {
    return <React.Fragment>{this.getLoad()}</React.Fragment>;
  }
}

ButtonLoader.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
};

export default ButtonLoader;
