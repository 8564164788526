import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import { affiliateStatus } from "../../store/saver/actions";
import { isAffiliate, isSaverOnly } from "../../helpers/session_helper";
import { Alert } from "reactstrap";

class MicrofinanceAffiliation extends Component {
  componentDidMount() {
    const { checkAffiliateStatus } = this.props;
    if (isSaverOnly() && !isAffiliate()) {
      checkAffiliateStatus();
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.error && this.props.error ? (
          <Alert color="warning">
            <i className="mdi mdi-alert-outline me-2"></i> {this.props.error}{" "}
            <Link to="/affiliate/request">
              {this.props.t("Subscribe saver")}
            </Link>
            {/*{" "} | {" "}*/}
            {/*<Link to="/affiliate/request">*/}
            {/*  {this.props.t("Or browser saver request")}*/}
            {/*</Link>*/}
          </Alert>
        ) : null}
      </React.Fragment>
    );
  }
}

MicrofinanceAffiliation.propTypes = {
  error: PropTypes.any,
  message: PropTypes.any,
  history: PropTypes.object,
  loading: PropTypes.bool,
  checkAffiliateStatus: PropTypes.func,
  t: PropTypes.any,
};

const mapStateToProps = state => ({
  error: state.Saver.statusError,
});

const mapDispatchToProps = dispatch => ({
  checkAffiliateStatus: () => dispatch(affiliateStatus()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(MicrofinanceAffiliation))
);
