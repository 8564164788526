import {
  MANAGER_SUBSCRIBE,
  MANAGER_SUBSCRIBE_FAILURE,
  MANAGER_SUBSCRIBE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  error: "",
};

const collectors = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MANAGER_SUBSCRIBE:
      return {
        ...state,
        loading: true,
      };

    case MANAGER_SUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case MANAGER_SUBSCRIBE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default collectors;
