import {
  COLLECTOR_COLLABORATION_MY_CLIENTS,
  COLLECTOR_COLLABORATION_MY_CLIENTS_FAILURE,
  COLLECTOR_COLLABORATION_MY_CLIENTS_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST,
  COLLECTOR_COLLABORATION_REQUEST_FAILURE,
  COLLECTOR_COLLABORATION_REQUEST_RECEIVE,
  COLLECTOR_COLLABORATION_REQUEST_RECEIVE_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST_SENT,
  COLLECTOR_COLLABORATION_REQUEST_SENT_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST_STATUS,
  COLLECTOR_COLLABORATION_REQUEST_STATUS_FAILURE,
  COLLECTOR_COLLABORATION_REQUEST_STATUS_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST_UPDATE,
  COLLECTOR_COLLABORATION_REQUEST_UPDATE_FAILURE,
  COLLECTOR_COLLABORATION_REQUEST_UPDATE_SUCCESS,
} from "./actionTypes";

export const collaboration = microfinanceId => ({
  type: COLLECTOR_COLLABORATION_REQUEST,
  payload: { microfinanceId },
});

export const collaborationSuccess = body => ({
  type: COLLECTOR_COLLABORATION_REQUEST_SUCCESS,
  payload: body,
});

export const collaborationFailure = error => ({
  type: COLLECTOR_COLLABORATION_REQUEST_FAILURE,
  payload: error,
});

export const microfinanceTreatRequest = (id, state, reason) => ({
  type: COLLECTOR_COLLABORATION_REQUEST_UPDATE,
  payload: { id, state, reason },
});

export const microfinanceTreatRequestSuccess = body => ({
  type: COLLECTOR_COLLABORATION_REQUEST_UPDATE_SUCCESS,
  payload: body,
});

export const microfinanceTreatRequestFailure = error => ({
  type: COLLECTOR_COLLABORATION_REQUEST_UPDATE_FAILURE,
  payload: error,
});

export const collaborationStatus = () => ({
  type: COLLECTOR_COLLABORATION_REQUEST_STATUS,
});

export const collaborationStatusSuccess = body => ({
  type: COLLECTOR_COLLABORATION_REQUEST_STATUS_SUCCESS,
  payload: { body },
});

export const collaborationStatusFailure = error => ({
  type: COLLECTOR_COLLABORATION_REQUEST_STATUS_FAILURE,
  payload: error,
});

export const sentCollaborationRequests = () => ({
  type: COLLECTOR_COLLABORATION_REQUEST_SENT,
});

export const sentCollaborationRequestsSuccess = requests => ({
  type: COLLECTOR_COLLABORATION_REQUEST_SENT_SUCCESS,
  payload: requests,
});

export const sentCollaborationRequestsFailure = error => ({
  type: COLLECTOR_COLLABORATION_REQUEST_SENT_SUCCESS,
  payload: error,
});

export const myClients = () => ({
  type: COLLECTOR_COLLABORATION_MY_CLIENTS,
});

export const myClientsSuccess = requests => ({
  type: COLLECTOR_COLLABORATION_MY_CLIENTS_SUCCESS,
  payload: requests,
});

export const myClientsFailure = error => ({
  type: COLLECTOR_COLLABORATION_MY_CLIENTS_FAILURE,
  payload: error,
});

export const receiveCollaborationRequests = state => ({
  type: COLLECTOR_COLLABORATION_REQUEST_RECEIVE,
  payload: { state },
});

export const receiveCollaborationRequestsSuccess = requests => ({
  type: COLLECTOR_COLLABORATION_REQUEST_RECEIVE_SUCCESS,
  payload: requests,
});

export const receiveCollaborationRequestsFailure = error => ({
  type: COLLECTOR_COLLABORATION_REQUEST_RECEIVE_SUCCESS,
  payload: error,
});
