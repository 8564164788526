import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Countdown
import Countdown from "react-countdown";
import Slidewithcaption from "./slidewithcaption";

class Section extends Component {
  constructor() {
    super();
    this.renderer.bind(this);
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>You are good to go!</span>;
    } else {
      // Render a countdown
      return (
        <>
          <div className="coming-box">
            {days}
            <span>Days</span>
          </div>
          <div className="coming-box">
            {hours}
            <span>Hours</span>
          </div>
          <div className="coming-box">
            {minutes}
            <span>Minutes</span>
          </div>
          <div className="coming-box">
            {seconds}
            <span>Seconds</span>
          </div>
        </>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className="section hero-section bg-ico-hero" id="home">
          <div className="bg-overlay bg-primary" />
          <Container>
            <Row className="align-items-center">
              <Col lg="5">
                <div className="text-white-50">
                  <h1 className="text-white font-weight-semibold mb-3 hero-title">
                    L’Appli qui développe le potentiel de l’informel
                  </h1>
                  <p className="font-size-14">
                    Payez en 2 ou 3X – Sécurisez votre Micro-épargne-
                    Transférerez vos fonds en toute sécurité.
                  </p>

                  <div className="button-items mt-4">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://api.whatsapp.com/send?phone=19893751820&text=join%20nearly-drive"
                      className="btn btn-success"
                    >
                      <i className="mdi mdi-whatsapp"></i> WhatsApp
                    </a>{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.nycorp.loan2cash"
                      className="btn btn-light"
                    >
                      <i className="mdi mdi-google-play"></i> Google Play
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg="6" md="9" sm="11" className="ms-lg-auto">
                <Slidewithcaption />
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
