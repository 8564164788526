import * as url from "./backendUrl";
import { get, patch, post } from "../api_helper";

// Saver status Method
const getSaverAffiliateStatus = data =>
  get(url.GET_SAVER_AFFILIATE_STATUS, data);
const getSaverAffiliateRequestsSent = data =>
  get(url.GET_SAVER_AFFILIATE_SENT, data);
const getSaverAffiliateRequestsReceive = data =>
  get(url.GET_SAVER_AFFILIATE_RECEIVE, { params: data });
const getMicrofinance = data => get(url.GET_MICROFINANCE, data);
const postSaverAffiliate = data =>
  post(url.POST_SAVER_AFFILIATE, null, { params: data });
const postManagerAffiliate = data =>
  post(url.POST_MANAGER_ASSIGN, null, { params: data });
const patchSaverAffiliate = data =>
  patch(url.PATCH_SAVER_AFFILIATE, null, { params: data });

export {
  getSaverAffiliateStatus,
  getSaverAffiliateRequestsSent,
  getMicrofinance,
  postSaverAffiliate,
  getSaverAffiliateRequestsReceive,
  patchSaverAffiliate,
  postManagerAffiliate,
};
