import * as url from "./backendUrl";
import { get } from "../api_helper";

// country resources
const getCountries = data => get(url.GET_COUNTRIES, data);

// city resources
const getCities = data => get(url.GET_CITIES, data);

// city resources
const getDistricts = data => get(url.GET_DISTRICTS, data);

export { getCountries, getCities, getDistricts };
