import {
  GET_BALANCE_FAIL,
  GET_BALANCE_SUCCESS,
  GET_CLIENT_TRANSACTION_HISTORY,
  GET_CLIENT_TRANSACTION_HISTORY_FAIL,
  GET_CLIENT_TRANSACTION_HISTORY_SUCCESS,
  GET_MICROFINANCE_WITHDRAWAL_REQUEST,
  GET_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL,
  GET_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS,
  GET_PAYMENT_PROVIDER,
  GET_PAYMENT_PROVIDER_FAIL,
  GET_PAYMENT_PROVIDER_SUCCESS,
  GET_TRANSACTION_HISTORY,
  GET_TRANSACTION_HISTORY_FAIL,
  GET_TRANSACTION_HISTORY_SUCCESS,
  GET_WITHDRAWAL_REQUEST,
  GET_WITHDRAWAL_REQUEST_FAIL,
  GET_WITHDRAWAL_REQUEST_SUCCESS,
  MAKE_DEPOSIT,
  MAKE_DEPOSIT_FAIL,
  MAKE_DEPOSIT_SUCCESS,
  MAKE_INTERMEDIATE_DEPOSIT,
  MAKE_INTERMEDIATE_DEPOSIT_FAIL,
  MAKE_INTERMEDIATE_DEPOSIT_SUCCESS,
  MAKE_WITHDRAWAL_REQUEST,
  MAKE_WITHDRAWAL_REQUEST_FAIL,
  MAKE_WITHDRAWAL_REQUEST_SUCCESS,
  TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST,
  TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL,
  TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  transactions: [],
  withdrawalRequests: [],
  receiveWithdrawalRequests: [],
  transaction: {},
  providers: [],
  loadingDeposit: false,
  loadingIntermediateDeposit: false,
  loadingWithdrawal: false,
  loadingTraitWithdrawal: false,
  balance: "Loading",
  error: {},
  depositError: "",
  depositIntermediateError: "",
  withdrawalError: "",
  traitWithdrawalError: "",
  loading: false,
  depositSuccess: false,
  depositIntermediateSuccess: false,
};

const wallet = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: true,
      };

    case GET_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
      };

    case GET_TRANSACTION_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_CLIENT_TRANSACTION_HISTORY:
      return {
        ...state,
        loading: true,
        phone: action.payload
      };

    case GET_CLIENT_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        loading: false,
      };

    case GET_CLIENT_TRANSACTION_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_WITHDRAWAL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawalRequests: action.payload,
        loading: false,
      };

    case GET_WITHDRAWAL_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_MICROFINANCE_WITHDRAWAL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        receiveWithdrawalRequests: action.payload,
        loading: false,
      };

    case GET_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case GET_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload,
      };

    case GET_BALANCE_FAIL:
      return {
        ...state,
        balance: action.payload,
      };

    case GET_PAYMENT_PROVIDER:
      return {
        ...state,
        loading: true,
      };

    case GET_PAYMENT_PROVIDER_SUCCESS:
      return {
        ...state,
        providers: action.payload,
        loading: false,
      };

    case GET_PAYMENT_PROVIDER_FAIL:
      return {
        ...state,
        depositError: action.payload,
        loading: false,
      };

    case MAKE_DEPOSIT:
      return {
        ...state,
        loadingDeposit: true,
      };

    case MAKE_DEPOSIT_SUCCESS:
      return {
        ...state,
        transaction: action.payload,
        loadingDeposit: false,
        depositSuccess: true,
      };

    case MAKE_DEPOSIT_FAIL:
      return {
        ...state,
        depositError: action.payload,
        loadingDeposit: false,
      };

    case MAKE_INTERMEDIATE_DEPOSIT:
      return {
        ...state,
        loadingIntermediateDeposit: true,
      };

    case MAKE_INTERMEDIATE_DEPOSIT_SUCCESS:
      return {
        ...state,
        transaction: action.payload,
        loadingIntermediateDeposit: false,
        depositIntermediateSuccess: "Success",
      };

    case MAKE_INTERMEDIATE_DEPOSIT_FAIL:
      return {
        ...state,
        depositIntermediateError: action.payload,
        loadingIntermediateDeposit: false,
      };

    case MAKE_WITHDRAWAL_REQUEST:
      return {
        ...state,
        loadingWithdrawal: true,
      };

    case MAKE_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        transaction: action.payload,
        loadingWithdrawal: false,
      };

    case MAKE_WITHDRAWAL_REQUEST_FAIL:
      return {
        ...state,
        withdrawalError: action.payload,
        loadingWithdrawal: false,
      };
    case TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST:
      return {
        ...state,
        loadingTraitWithdrawal: true,
      };

    case TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS:
      location.reload();
      return {
        ...state,
        transaction: action.payload,
        loadingTraitWithdrawal: false,
      };

    case TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL:
      return {
        ...state,
        traitWithdrawalError: action.payload,
        loadingTraitWithdrawal: false,
      };

    default:
      return state;
  }
};

export default wallet;
