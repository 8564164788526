import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import WalletStats from "./Saver/walletStats";
import WalletOverView from "./Saver/walletOverView";
import { isEmpty } from "lodash";
import "assets/scss/datatables.scss";
import WithdrawalRequestsTable from "../../components/Saver/WithdrawalRequestsTable";
import { getSaverDashboard } from "../../store/dashboard/actions";

class Saver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenu: false,
      activeTab: "1",
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    const { onGetWallet } = this.props;
    onGetWallet();
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }));
  }

  render() {
    if (!this.props.visible) return null;
    else {
      const {
        chartData,
        //wallet: { walletHistory }
      } = this.props;
      const { activeTab, isMenu } = this.state;

      return (
        <React.Fragment>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Dashboards"
            breadcrumbItem={this.props.t("Saver")}
          />

          {!isEmpty(chartData) && (
            <Row>
              <Col xl="4">
                <WalletStats
                  wallet={chartData}
                  isMenu={isMenu}
                  toggleMenu={this.toggleMenu}
                />
              </Col>
              <Col xl="8">
                <WalletOverView wallet={chartData} />
              </Col>
            </Row>
          )}
          {isEmpty(chartData) && (
            <Row>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <div>
                      <span className="placeholder col-6"></span>
                      <span className="placeholder w-75"></span>
                      <span
                        className="placeholder"
                        style={{ width: "25%" }}
                      ></span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="8">
                <Card height={320}>
                  <CardBody>
                    <div>
                      <span className="placeholder col-6"></span>
                      <span className="placeholder w-75"></span>
                      <span
                        className="placeholder"
                        style={{ width: "25%" }}
                      ></span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col lg="12">
              <WithdrawalRequestsTable />
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

Saver.propTypes = {
  visible: PropTypes.bool,
  t: PropTypes.any,
  wallet: PropTypes.any,
  chartData: PropTypes.any,
  onGetWallet: PropTypes.func,
};

const mapStateToProps = state => ({
  wallet: {
    id: "#SK0234",
    userName: "Henry Wells",
    email: "henrywells@abc.com",
    availableBalance: "XAF 9148.23",
    lastMonthDifference: "+ XAF 0",
    lastMonthDifferencePercent: "+ 0 %",
    send: "XAF 0",
    receive: "XAF 0",
    withdraw: "XAF 0",
    series: [
      {
        type: "area",
        name: "Deposit",
        data: [],
      },
      {
        type: "area",
        name: "XPayLater",
        data: [],
      },
      {
        type: "line",
        name: "Withdrawal",
        data: [],
      },
    ],
    options: {
      chart: { toolbar: { show: !1 } },
      dataLabels: { enabled: !1 },
      stroke: { curve: "smooth", width: 2, dashArray: [0, 0, 3] },
      fill: { type: "solid", opacity: [0.15, 0.05, 1] },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      colors: ["#f1b44c", "#3452e1", "#50a5f1"],
    },
    walletHistory: [
      {
        id: "#SK3215",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3216",
        date: "04 Mar, 2020",
        type: "Sell",
        currency: "Ethereum",
        amount: "0.00413 ETH",
        amountinUSD: "$ 2123.01",
      },
      {
        id: "#SK3217",
        date: "04 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3218",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3219",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3220",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3221",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3222",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3223",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3224",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3225",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
      {
        id: "#SK3226",
        date: "03 Mar, 2020",
        type: "Buy",
        currency: "Bitcoin",
        amount: "1.00952 BTC",
        amountinUSD: "$ 9067.62",
      },
    ],
  },
  chartData: state.Dashboard.chartsData,
});

const mapDispatchToProps = dispatch => ({
  onGetWallet: () => dispatch(getSaverDashboard()),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Saver))
);
