import {
  SAVER_AFFILIATE,
  SAVER_AFFILIATE_FAILURE,
  SAVER_AFFILIATE_RECEIVE,
  SAVER_AFFILIATE_RECEIVE_SUCCESS,
  SAVER_AFFILIATE_SENT,
  SAVER_AFFILIATE_SENT_SUCCESS,
  SAVER_AFFILIATE_STATUS,
  SAVER_AFFILIATE_STATUS_FAILURE,
  SAVER_AFFILIATE_STATUS_SUCCESS,
  SAVER_AFFILIATE_SUCCESS,
  SAVER_AFFILIATE_UPDATE,
  SAVER_AFFILIATE_UPDATE_FAILURE,
  SAVER_AFFILIATE_UPDATE_SUCCESS,
  SAVER_MICROFINANCE_LIST,
  SAVER_MICROFINANCE_LIST_SUCCESS,
} from "./actionTypes";

export const affiliate = microfinanceId => ({
  type: SAVER_AFFILIATE,
  payload: { microfinanceId },
});

export const affiliateSuccess = body => ({
  type: SAVER_AFFILIATE_SUCCESS,
  payload: body,
});

export const affiliateFailure = error => ({
  type: SAVER_AFFILIATE_FAILURE,
  payload: error,
});

export const traiteRequest = (id, state, reason) => ({
  type: SAVER_AFFILIATE_UPDATE,
  payload: { id, state, reason },
});

export const traiteRequestSuccess = body => ({
  type: SAVER_AFFILIATE_UPDATE_SUCCESS,
  payload: body,
});

export const traiteRequestFailure = error => ({
  type: SAVER_AFFILIATE_UPDATE_FAILURE,
  payload: error,
});

export const affiliateStatus = () => ({
  type: SAVER_AFFILIATE_STATUS,
});

export const affiliateStatusSuccess = body => ({
  type: SAVER_AFFILIATE_STATUS_SUCCESS,
  payload: { body },
});

export const affiliateStatusFailure = error => ({
  type: SAVER_AFFILIATE_STATUS_FAILURE,
  payload: error,
});

export const sentAffiliateRequests = () => ({
  type: SAVER_AFFILIATE_SENT,
});

export const sentAffiliateRequestsSuccess = requests => ({
  type: SAVER_AFFILIATE_SENT_SUCCESS,
  payload: requests,
});

export const sentAffiliateRequestsFailure = error => ({
  type: SAVER_AFFILIATE_SENT_SUCCESS,
  payload: error,
});

export const receiveAffiliateRequests = state => ({
  type: SAVER_AFFILIATE_RECEIVE,
  payload: { state },
});

export const receiveAffiliateRequestsSuccess = requests => ({
  type: SAVER_AFFILIATE_RECEIVE_SUCCESS,
  payload: requests,
});

export const receiveAffiliateRequestsFailure = error => ({
  type: SAVER_AFFILIATE_RECEIVE_SUCCESS,
  payload: error,
});

export const getMicrofinanceList = () => ({
  type: SAVER_MICROFINANCE_LIST,
  payload: true,
});

export const getMicrofinanceListSuccess = items => ({
  type: SAVER_MICROFINANCE_LIST_SUCCESS,
  payload: items,
});

export const getMicrofinanceListFailure = error => ({
  type: SAVER_MICROFINANCE_LIST_SUCCESS,
  payload: error,
});
