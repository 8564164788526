import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import { userResetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import ForgetPassword from "../../store/auth/forgetpwd/reducer";

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    if (this.props.location && this.props.location.state) {
      this.setState({
        username: queryParams.get("username"),
      });
      this.props.username = queryParams.get("username");
    }
  }

  render() {
    const queryParams = new URLSearchParams(window.location.search);

    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Reset password to continue to loan2cash.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.resetError && this.props.resetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.props.resetError}
                        </Alert>
                      ) : null}
                      {this.props.resetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.resetSuccessMsg}
                        </Alert>
                      ) : null}

                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          username: (this.state && this.state.username) || "",
                        }}
                        validationSchema={Yup.object().shape({
                          password: Yup.string().required(),
                          code: Yup.string().required(),
                          confirm_password: Yup.string()
                            .label("confirm password")
                            .required()
                            .oneOf(
                              [Yup.ref("password"), null],
                              "Passwords must match"
                            ),
                        })}
                        onSubmit={values => {
                          values = {
                            ...values,
                            username: queryParams.get("username"),
                          };
                          this.props.userResetPassword(
                            values,
                            this.props.history
                          );
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="username" className="form-label">
                                Code
                              </Label>
                              <Field
                                name="code"
                                type="number"
                                className={
                                  "form-control" +
                                  (errors.code && touched.code
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="code"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="username" className="form-label">
                                Password
                              </Label>
                              <Field
                                name="password"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="username" className="form-label">
                                Password confirmation
                              </Label>
                              <Field
                                name="confirm_password"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.confirm_password &&
                                  touched.confirm_password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="confirm_password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="text-end">
                              <button
                                className="btn btn-primary w-md"
                                type="submit"
                              >
                                Reset
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link to="login" className="fw-medium text-primary">
                      Login
                    </Link>{" "}
                  </p>
                  <p className="mb-0">
                    © {new Date().getFullYear()} loan2cash. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger" /> by SAS 2 Cash
                    Enterprise
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ResetPasswordPage.propTypes = {
  resetError: PropTypes.func,
  resetSuccessMsg: PropTypes.string,
  history: PropTypes.object,
  userResetPassword: PropTypes.any,
  username: PropTypes.any,
};

const mapStateToProps = state => {
  const { resetError, resetSuccessMsg } = state.ForgetPassword;
  return { resetError, resetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userResetPassword })(ResetPasswordPage)
);
