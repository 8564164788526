import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Landing from "../pages/Landing";
import AffiliateRequest from "../pages/saver/AffiliateRequest";
import UsernameVerification from "../pages/Authentication/UsernameVerification";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import KYCWallet from "../pages/Wallet/kyc-application";
import Error404 from "../pages/Errors/Error404";
import WalletHistory from "../pages/Wallet/WalletHistory";
import CashInOut from "../pages/Wallet/CashInOut";
import WithdrawalRequests from "../pages/Wallet/WithdrawalRequests";
import ReceiveWithdrawalRequests from "../pages/Microfinance/WithdrawalRequests";
import RegisterMicrofinance from "../pages/Authentication/RegisterMicrofinance";
import CollectorRequest from "../pages/collector/CollectorRequest";
import CollectorList from "../pages/collector/CollectorList";
import CollectorClient from "../pages/collector/CollectorClient";
import ManagerCollect from "../pages/collector/ManagerCollect";
import EcommerceProducts from "../pages/Shop/Products/EcommerceProducts";
import EcommerceProductDetail from "../pages/Shop/Products/EcommerceProductDetail";
import RegisterVendor from "../pages/Authentication/RegisterVendor";
import AddEditProduct from "../pages/Shop/Products/AddEditProduct";
import ResetPassword from "../pages/Authentication/ResetPassword";
import CommitteesView from "../pages/Microfinance/CommitteesView";
import Clients from "../pages/saver/Clients";
import ClientDetail from "../pages/saver/ClientDetail";
import LoanRequest from "../pages/XPayLater/LoanRequest";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/affiliate/request", component: AffiliateRequest },
  { path: "/customers", component: Clients },
  { path: "/customers/profile", component: ClientDetail },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/kyc-verification", component: KYCWallet },
  { path: "/wallet/history", component: WalletHistory },
  { path: "/wallet/cash-in-out", component: CashInOut },
  { path: "/wallet/withdrawal-requests", component: WithdrawalRequests },

  { path: "/collector/requests", component: CollectorRequest },
  { path: "/collaboration/requests", component: CollectorRequest },
  { path: "/collaborators", component: CollectorList },
  { path: "/collaborators/clients", component: CollectorClient },
  { path: "/collaborators/clients/collect", component: ManagerCollect },

  { path: "/settings/committees", component: CommitteesView },

  { path: "/xpaylater/loan/request", component: LoanRequest },
  { path: "/xpaylater/products", component: EcommerceProducts },
  { path: "/xpaylater/product/details/:id", component: EcommerceProductDetail },
  { path: "/xpaylater/product/add", component: AddEditProduct },
  { path: "/xpaylater/product/edit/:id", component: AddEditProduct },

  {
    path: "/microfinance/withdrawal-requests",
    component: ReceiveWithdrawalRequests,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  //{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPassword },
  { path: "/register/microfinance", component: RegisterMicrofinance },
  { path: "/register/vendor", component: RegisterVendor },
  { path: "/register", component: Register },
  { path: "/username-verification", component: UsernameVerification },
  { path: "/home", component: Landing },
  { path: "/404", component: Error404 },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
];

export { authProtectedRoutes, publicRoutes };
