export const SAVER_AFFILIATE = "SAVER_AFFILIATE";
export const SAVER_AFFILIATE_SUCCESS = "SAVER_AFFILIATE_SUCCESS";
export const SAVER_AFFILIATE_FAILURE = "SAVER_AFFILIATE_FAILURE";

export const SAVER_AFFILIATE_UPDATE = "SAVER_AFFILIATE_UPDATE";
export const SAVER_AFFILIATE_UPDATE_SUCCESS = "SAVER_AFFILIATE_UPDATE_SUCCESS";
export const SAVER_AFFILIATE_UPDATE_FAILURE = "SAVER_AFFILIATE_UPDATE_FAILURE";

export const SAVER_AFFILIATE_STATUS = "SAVER_AFFILIATE_STATUS";
export const SAVER_AFFILIATE_STATUS_SUCCESS = "SAVER_AFFILIATE_STATUS_SUCCESS";
export const SAVER_AFFILIATE_STATUS_FAILURE = "SAVER_AFFILIATE_STATUS_FAILURE";

export const SAVER_AFFILIATE_SENT = "SAVER_AFFILIATE_SENT";
export const SAVER_AFFILIATE_SENT_SUCCESS = "SAVER_AFFILIATE_SENT_SUCCESS";
export const SAVER_AFFILIATE_SENT_FAILURE = "SAVER_AFFILIATE_SENT_FAILURE";

export const SAVER_AFFILIATE_RECEIVE = "SAVER_AFFILIATE_RECEIVE";
export const SAVER_AFFILIATE_RECEIVE_SUCCESS =
  "SAVER_AFFILIATE_RECEIVE_SUCCESS";
export const SAVER_AFFILIATE_RECEIVE_FAILURE =
  "SAVER_AFFILIATE_RECEIVE_FAILURE";

export const SAVER_MICROFINANCE_LIST = "SAVER_MICROFINANCE_LIST";
export const SAVER_MICROFINANCE_LIST_SUCCESS =
  "SAVER_MICROFINANCE_LIST_SUCCESS";
export const SAVER_MICROFINANCE_LIST_FAILURE =
  "SAVER_MICROFINANCE_LIST_FAILURE˚";
