import React, { Component } from "react";
//Verification code package
import AuthCode from "react-auth-code-input";

import { Link, withRouter } from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import { ErrorMessage, Form, Formik } from "formik";
// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import PropTypes from "prop-types";
import {
  verifyAccount,
  verifyAccountClaim,
} from "../../store/auth/verification/actions";
import { connect } from "react-redux";
import * as Yup from "yup";

class UsernameVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resend: false,
    };
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    if (this.props.location && this.props.location.state)
      if (this.props.location.state.resend) {
        this.props.onVerifyClaim(queryParams.get("username"));
      }
  }

  render() {
    const queryParams = new URLSearchParams(window.location.search);
    //meta title
    document.title = "Two Step Verification | loan2cash";
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mb-5 text-muted">
                  <Link to="dashboard" className="d-block auth-logo">
                    <img
                      src={logodark}
                      alt=""
                      height="20"
                      className="auth-logo-dark mx-auto"
                    />
                    <img
                      src={logolight}
                      alt=""
                      height="20"
                      className="auth-logo-light mx-auto"
                    />
                  </Link>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardBody>
                    <div className="p-2">
                      <div className="text-center">
                        <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                          </div>
                        </div>
                        <div className="p-2 mt-4">
                          <h4>Verify your {queryParams.get("type")}</h4>
                          <p className="mb-5">
                            Please enter the 6 digit code sent to{" "}
                            <span className="font-weight-semibold">
                              {queryParams.get("username")}
                            </span>
                          </p>
                          <Formik
                            initialValues={{
                              code: (this.state && this.state.code) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              code: Yup.number()
                                .min(100000)
                                .max(999999)
                                .required(),
                            })}
                            validateOnChange={true}
                            onSubmit={values => {
                              this.props.onVerify(
                                queryParams.get("username"),
                                values.code,
                                this.props.history
                              );
                            }}
                          >
                            {({
                              errors,
                              status,
                              touched,
                              values,
                              setValues,
                            }) => (
                              <Form className="form-horizontal">
                                {this.props.error && this.props.error ? (
                                  <Alert color="danger">
                                    {this.props.error}
                                  </Alert>
                                ) : null}

                                {this.props.message && this.props.message ? (
                                  <Alert color="success">
                                    {this.props.message}
                                  </Alert>
                                ) : null}

                                <Row>
                                  <Col xs={12}>
                                    <FormGroup className="verification">
                                      <label
                                        htmlFor="digit1-input"
                                        className="sr-only"
                                      >
                                        Dight 1
                                      </label>
                                      <AuthCode
                                        characters={6}
                                        onChange={value =>
                                          setValues({ code: value })
                                        }
                                        className={
                                          "form-control form-control-lg text-center" +
                                          (errors.code && touched.code
                                            ? " is-invalid"
                                            : "")
                                        }
                                        allowedCharacters="^[0-9]"
                                        inputStyle={{
                                          width: "45px",
                                          height: "45px",
                                          padding: "8px",
                                          borderRadius: "8px",
                                          fontSize: "16px",
                                          textAlign: "center",
                                          marginRight: "15px",
                                          border: "1px solid #ced4da",
                                          textTransform: "uppercase",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="code"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <div className="mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-success w-md"
                                  >
                                    {this.props.loading ? (
                                      <i
                                        className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"
                                        if
                                      ></i>
                                    ) : (
                                      ""
                                    )}{" "}
                                    Confirm
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Did&apos;t receive a code ?{" "}
                    <Link
                      to="#"
                      onClick={event => {
                        event.preventDefault();
                        this.props.onVerifyClaim(queryParams.get("username"));
                      }}
                      className="fw-medium text-primary"
                    >
                      {" "}
                      Resend{" "}
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UsernameVerification.propTypes = {
  error: PropTypes.any,
  message: PropTypes.any,
  history: PropTypes.object,
  loading: PropTypes.bool,
  onVerify: PropTypes.func,
  onVerifyClaim: PropTypes.func,
  username: PropTypes.any,
};

const mapStateToProps = state => ({
  error: state.AccountVerification.error,
  message: state.AccountVerification.message,
  loading: state.AccountVerification.loading,
});

const mapDispatchToProps = dispatch => ({
  onVerify: (username, code, history) =>
    dispatch(verifyAccount(username, code, history)),
  onVerifyClaim: username => {
    dispatch(verifyAccountClaim(username));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsernameVerification)
);
