import {
  GET_BALANCE,
  GET_BALANCE_FAIL,
  GET_BALANCE_SUCCESS,
  GET_CLIENT_TRANSACTION_HISTORY,
  GET_CLIENT_TRANSACTION_HISTORY_SUCCESS,
  GET_MICROFINANCE_WITHDRAWAL_REQUEST,
  GET_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL,
  GET_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS,
  GET_PAYMENT_PROVIDER,
  GET_PAYMENT_PROVIDER_FAIL,
  GET_PAYMENT_PROVIDER_SUCCESS,
  GET_TRANSACTION_HISTORY,
  GET_TRANSACTION_HISTORY_SUCCESS,
  GET_WITHDRAWAL_REQUEST,
  GET_WITHDRAWAL_REQUEST_FAIL,
  GET_WITHDRAWAL_REQUEST_SUCCESS,
  MAKE_DEPOSIT,
  MAKE_DEPOSIT_FAIL,
  MAKE_DEPOSIT_SUCCESS,
  MAKE_INTERMEDIATE_DEPOSIT,
  MAKE_INTERMEDIATE_DEPOSIT_FAIL,
  MAKE_INTERMEDIATE_DEPOSIT_SUCCESS,
  MAKE_WITHDRAWAL_REQUEST,
  MAKE_WITHDRAWAL_REQUEST_FAIL,
  MAKE_WITHDRAWAL_REQUEST_SUCCESS,
  TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST,
  TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL,
  TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS
} from "./actionTypes";

export const getTransactions = () => ({
  type: GET_TRANSACTION_HISTORY
});

export const getTransactionsSuccess = transactions => ({
  type: GET_TRANSACTION_HISTORY_SUCCESS,
  payload: transactions
});

export const getTransactionsFailure = error => ({
  type: GET_TRANSACTION_HISTORY_SUCCESS,
  payload: error
});

export const getClientTransactions = phone => ({
  type: GET_CLIENT_TRANSACTION_HISTORY,
  payload: { phone }
});

export const getClientTransactionsSuccess = transactions => ({
  type: GET_CLIENT_TRANSACTION_HISTORY_SUCCESS,
  payload: transactions
});

export const getClientTransactionsFailure = error => ({
  type: GET_CLIENT_TRANSACTION_HISTORY_SUCCESS,
  payload: error
});

export const getWithdrawalRequests = () => ({
  type: GET_WITHDRAWAL_REQUEST
});

export const getWithdrawalRequestsSuccess = requests => ({
  type: GET_WITHDRAWAL_REQUEST_SUCCESS,
  payload: requests
});

export const getWithdrawalRequestsFailure = error => ({
  type: GET_WITHDRAWAL_REQUEST_FAIL,
  payload: error
});

export const getMicrofinanceWithdrawalRequests = state => ({
  type: GET_MICROFINANCE_WITHDRAWAL_REQUEST,
  payload: { state }
});

export const getMicrofinanceWithdrawalRequestsSuccess = requests => ({
  type: GET_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS,
  payload: requests
});

export const getMicrofinanceWithdrawalRequestsFailure = error => ({
  type: GET_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL,
  payload: error
});

export const getBalance = () => ({
  type: GET_BALANCE
});

export const getBalanceSuccess = balance => ({
  type: GET_BALANCE_SUCCESS,
  payload: balance
});

export const getBalanceFailure = message => ({
  type: GET_BALANCE_FAIL,
  payload: message
});

export const getProvider = () => ({
  type: GET_PAYMENT_PROVIDER
});

export const getProviderSuccess = providers => ({
  type: GET_PAYMENT_PROVIDER_SUCCESS,
  payload: providers
});

export const getProvidersFailure = message => ({
  type: GET_PAYMENT_PROVIDER_FAIL,
  payload: message
});

export const makeDeposit = (data, history) => ({
  type: MAKE_DEPOSIT,
  payload: { data, history }
});

export const makeDepositSuccess = transaction => ({
  type: MAKE_DEPOSIT_SUCCESS,
  payload: transaction
});

export const makeDepositFailure = message => ({
  type: MAKE_DEPOSIT_FAIL,
  payload: message
});

export const makeIntermediateDeposit = (data, history) => ({
  type: MAKE_INTERMEDIATE_DEPOSIT,
  payload: { data, history }
});

export const makeIntermediateDepositSuccess = transaction => ({
  type: MAKE_INTERMEDIATE_DEPOSIT_SUCCESS,
  payload: transaction
});

export const makeIntermediateDepositFailure = message => ({
  type: MAKE_INTERMEDIATE_DEPOSIT_FAIL,
  payload: message
});

export const makeWithdrawal = (data, history) => ({
  type: MAKE_WITHDRAWAL_REQUEST,
  payload: { data, history }
});

export const makeWithdrawalSuccess = transaction => ({
  type: MAKE_WITHDRAWAL_REQUEST_SUCCESS,
  payload: transaction
});

export const makeWithdrawalFailure = message => ({
  type: MAKE_WITHDRAWAL_REQUEST_FAIL,
  payload: message
});

export const traitWithdrawal = (id, state, reason) => ({
  type: TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST,
  payload: { id, state, reason }
});

export const traitWithdrawalSuccess = transaction => ({
  type: TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS,
  payload: transaction
});

export const traitWithdrawalFailure = message => ({
  type: TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL,
  payload: message
});
