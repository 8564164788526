import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";

class WalletOverView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="clearfix">
              {/*<div className="float-end">*/}
              {/*  <div className="input-group input-group-sm">*/}
              {/*    <select*/}
              {/*      //value={seletedMonth}*/}
              {/*      onChange={(e) => {*/}
              {/*        //this.setState({ ...this.state, seletedMonth: e.target.value });*/}
              {/*        //this.props.onGetEarningChartsData(e.target.value);*/}
              {/*      }}*/}
              {/*      className="form-select form-select-sm">*/}
              {/*      <option value="1">Jan</option>*/}
              {/*      <option value="dec">Dec</option>*/}
              {/*      <option value="nov">Nov</option>*/}
              {/*      <option value="oct">Oct</option>*/}
              {/*    </select>*/}
              {/*    <label className="input-group-text">Month</label>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <h4 className="card-title mb-4">Overview</h4>
            </div>

            <div>
              <div id="overview-chart" className="apex-charts" dir="ltr">
                <ReactApexChart
                  series={this.props.wallet.chartInfo.series}
                  options={{
                    chart: { toolbar: { show: true } },
                    dataLabels: { enabled: false },
                    stroke: { curve: "smooth", width: 2, dashArray: [0, 0, 3] },
                    fill: { type: "solid", opacity: [0.15, 0.05, 1] },
                    grid: {
                      borderColor: "#f1f1f1",
                    },
                    xaxis: {
                      categories: this.props.wallet.chartInfo.months,
                    },
                    colors: ["#f1b44c", "#5BC190", "#f46a6a"],
                  }}
                  type="area"
                  height={320}
                  className="apex-charts"
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

WalletOverView.propTypes = {
  wallet: PropTypes.any,
};

export default WalletOverView;
