import React, { Component } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

//Import Components
import Accordian from "./accordian";

class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="faqs">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <div className="small-title">FAQs</div>
                  <h4>Frequently asked questions</h4>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <div className="vertical-nav">
                  <Row>
                    <Col lg="2" sm="4">
                      <Nav pills className="flex-column">
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                        >
                          <i className="bx bx-help-circle nav-icon d-block mb-2" />
                          <p className="font-weight-bold mb-0">
                            FAQ Généralités
                          </p>
                        </NavLink>

                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggleTab("2");
                          }}
                        >
                          <i className="bx bx-receipt nav-icon d-block mb-2" />
                          <p className="font-weight-bold mb-0">FAQ Clients </p>
                        </NavLink>

                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggleTab("3");
                          }}
                        >
                          <i className="bx bx-timer d-block nav-icon mb-2" />
                          <p className="font-weight-bold mb-0">
                            FAQ Partenaires{" "}
                          </p>
                        </NavLink>
                      </Nav>
                    </Col>
                    <Col lg="10" sm="8">
                      <Card>
                        <CardBody>
                          <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1" id="buy">
                              <h4 className="card-title mb-4">
                                FAQ Généralités
                              </h4>

                              {/* accoridan */}
                              <Accordian
                                question1="Comment devrais-je comme connecter en tant qu’utilisateur ?"
                                answer1="vous vous connectez avec votre numéro de téléphone."
                                question2="Quelles pièces sont exigées à l’inscription en tant qu’utilisateur ?"
                                answer2="Nos exigeons de charger certaines pièces au moment de bénéficier de certains services"
                                question3="Les partenaires se connecte aussi avec un numéro de téléphone ?"
                                answer3="Non les partenaires utilisent une adresse mail pour s’inscrire et se connecter."
                                question4="Quelles documents sont exigées comme partenaires pour s’inscrire ?"
                                answer4="lors de l’inscription des partenaires Microfinance, non vérifions qu’ils sont agréés avant d’accepter une collaboration."
                              />
                            </TabPane>

                            <TabPane tabId="2">
                              <h4 className="card-title mb-4">FAQ Clients </h4>

                              <Accordian
                                question1="Ou sont logé les fonds épargnés ?"
                                answer1="Nous ne faisons pas de l’épargne mais nous transférons directement vos fonds à votre Établissement de Microfinance. "
                                question2="Que payons-nous pour bénéficier de ce service ?"
                                answer2="Vous ne payez aucun frais supplémentaire en dehors des frais de collecte tels que pratiqués par les agents de collecte habituels."
                                question3="Comment utiliser le service de paiement en 3X ?"
                                answer3="Vous devez parcourir directement notre boutique."
                                question4="Quels sont les coûts du paiement 3X ?"
                                answer4="Le service est gratuit si vous remboursez vos achats dans les délais."
                                question5="Comment utilisez la solution si je n’ai pas de connexion ou pas de smartphones ?"
                                answer5="Votre agent de collecte peut vous assister à faire vos transactions."
                              />
                            </TabPane>

                            <TabPane tabId="3">
                              <h4 className="card-title mb-4">
                                FAQ Partenaires{" "}
                              </h4>

                              <Accordian
                                question1="Quel est mon intérêt comme partenaire à vendre sur votre plateforme ?"
                                answer1="Nous vous offrons la possibilité de vendre à plus de personnes et d’accroitre votre chiffre d’affaires. "
                                question2="Et si le client ne me paie pas ?"
                                answer2="Vous êtes entièrement payé par la plateforme au moment de la commande du client. "
                                question3="Devons-nous avoir un site de e-commerce ?"
                                answer3="Non vous avez une interface dédiée."
                                question4="Quels avantages comme EMF de faire partenaire avec vous ?"
                                answer4="Nous aidons vos clients à sécuriser leurs collectes et à bénéficier d’autres services "
                              />
                            </TabPane>
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default FAQs;
