import {
  SAVER_AFFILIATE,
  SAVER_AFFILIATE_FAILURE,
  SAVER_AFFILIATE_RECEIVE_FAILURE,
  SAVER_AFFILIATE_RECEIVE_SUCCESS,
  SAVER_AFFILIATE_SENT,
  SAVER_AFFILIATE_SENT_FAILURE,
  SAVER_AFFILIATE_SENT_SUCCESS,
  SAVER_AFFILIATE_STATUS_FAILURE,
  SAVER_AFFILIATE_STATUS_SUCCESS,
  SAVER_AFFILIATE_SUCCESS,
  SAVER_AFFILIATE_UPDATE,
  SAVER_AFFILIATE_UPDATE_FAILURE,
  SAVER_AFFILIATE_UPDATE_SUCCESS,
  SAVER_MICROFINANCE_LIST,
  SAVER_MICROFINANCE_LIST_FAILURE,
  SAVER_MICROFINANCE_LIST_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  saverRequests: [],
  saverRequest: {},
  microfinanceList: [],
  loadingData: false,
  loadingMicrofinance: false,
  loadingSubscribe: false,
  loadingUpdate: false,
  close: false,
  error: "",
  statusError: "",
  updateError: "",
};

const savers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVER_AFFILIATE:
      return {
        ...state,
        loadingSubscribe: true,
      };
    case SAVER_AFFILIATE_SUCCESS:
      return {
        ...state,
        saverRequests: [...state.saverRequests, action.payload],
        loadingSubscribe: false,
        close: true,
      };

    case SAVER_AFFILIATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingSubscribe: false,
      };
    case SAVER_AFFILIATE_UPDATE:
      return {
        ...state,
        loadingUpdate: true,
      };
    case SAVER_AFFILIATE_UPDATE_SUCCESS:
      location.reload();
      return {
        ...state,
        saverRequests: [state.saverRequests],
        loadingUpdate: false,
        close: true,
      };

    case SAVER_AFFILIATE_UPDATE_FAILURE:
      return {
        ...state,
        updateError: action.payload,
        loadingUpdate: false,
      };

    case SAVER_AFFILIATE_RECEIVE_SUCCESS:
      return {
        ...state,
        saverRequests: action.payload,
      };

    case SAVER_AFFILIATE_RECEIVE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case SAVER_AFFILIATE_STATUS_SUCCESS:
      return {
        ...state,
      };

    case SAVER_AFFILIATE_STATUS_FAILURE:
      return {
        ...state,
        statusError: action.payload,
      };

    case SAVER_AFFILIATE_SENT:
      return {
        ...state,
        loadingData: false,
      };

    case SAVER_AFFILIATE_SENT_SUCCESS:
      return {
        ...state,
        loadingData: false,
        saverRequests: action.payload,
      };

    case SAVER_AFFILIATE_SENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingData: false,
      };

    case SAVER_MICROFINANCE_LIST:
      return {
        ...state,
        loadingMicrofinance: false,
      };

    case SAVER_MICROFINANCE_LIST_SUCCESS:
      return {
        ...state,
        loadingMicrofinance: false,
        microfinanceList: action.payload,
      };

    case SAVER_MICROFINANCE_LIST_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingMicrofinance: false,
      };

    default:
      return state;
  }
};

export default savers;
