import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_BALANCE,
  GET_CLIENT_TRANSACTION_HISTORY,
  GET_MICROFINANCE_WITHDRAWAL_REQUEST,
  GET_PAYMENT_PROVIDER,
  GET_TRANSACTION_HISTORY,
  GET_WITHDRAWAL_REQUEST,
  MAKE_DEPOSIT,
  MAKE_INTERMEDIATE_DEPOSIT,
  MAKE_WITHDRAWAL_REQUEST,
  TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST
} from "./actionTypes";

import {
  getBalanceFailure,
  getBalanceSuccess,
  getClientTransactionsFailure,
  getClientTransactionsSuccess,
  getMicrofinanceWithdrawalRequestsFailure,
  getMicrofinanceWithdrawalRequestsSuccess,
  getProvidersFailure,
  getProviderSuccess,
  getTransactionsFailure,
  getTransactionsSuccess,
  getWithdrawalRequestsFailure,
  getWithdrawalRequestsSuccess,
  makeDepositFailure,
  makeDepositSuccess,
  makeIntermediateDepositFailure,
  makeIntermediateDepositSuccess,
  makeWithdrawalFailure,
  makeWithdrawalSuccess,
  traitWithdrawalFailure,
  traitWithdrawalSuccess
} from "./actions";

//Include Both Helper File with needed methods
import {
  getBalance,
  getMyWithdrawalRequests,
  getPaymentProviders,
  getReceivesWithdrawalRequests,
  getTransactions,
  getClientTransactions,
  postManagerDeposit,
  postSelfDeposit,
  postWithdrawalRequest,
  treatReceiveWithdrawalRequest
} from "helpers/Api/walletApi";

function* fetchTransactionHistory() {
  try {
    const response = yield call(getTransactions);
    if (response.success) {
      yield put(getTransactionsSuccess(response.body));
    } else {
      yield put(getTransactionsFailure(response.message));
    }
  } catch (error) {
    yield put(getTransactionsFailure(error));
  }
}

function* fetchClientTransactionHistory({ payload: { phone } }) {
  try {
    const response = yield call(getClientTransactions, phone);
    if (response.success) {
      yield put(getClientTransactionsSuccess(response.body));
    } else {
      yield put(getClientTransactionsFailure(response.message));
    }
  } catch (error) {
    yield put(getClientTransactionsFailure(error));
  }
}

function* fetchMyWithdrawalRequest() {
  try {
    const response = yield call(getMyWithdrawalRequests);
    if (response.success) {
      yield put(getWithdrawalRequestsSuccess(response.body));
    } else {
      yield put(getWithdrawalRequestsFailure(response.message));
    }
  } catch (error) {
    yield put(getWithdrawalRequestsFailure(error));
  }
}

function* fetchMicrofinanceWithdrawalRequest({ payload: { state } }) {
  try {
    const response = yield call(getReceivesWithdrawalRequests, {
      state: state
    });
    if (response.success) {
      yield put(getMicrofinanceWithdrawalRequestsSuccess(response.body));
    } else {
      yield put(getMicrofinanceWithdrawalRequestsFailure(response.message));
    }
  } catch (error) {
    yield put(getMicrofinanceWithdrawalRequestsFailure(error));
  }
}

function* fetchBalance() {
  try {
    const response = yield call(getBalance);
    if (response.success) {
      yield put(getBalanceSuccess(response.body));
    } else {
      yield put(getBalanceFailure(response.message));
    }
  } catch (error) {
    yield put(getTransactionsFailure(error));
  }
}

function* makeSelfDeposit({ payload: { data, history } }) {
  try {
    const response = yield call(postSelfDeposit, data);
    if (response.success) {
      history.push("/wallet/history");
      yield put(makeDepositSuccess(response.body));
    } else {
      yield put(makeDepositFailure(response.message));
    }
  } catch (error) {
    yield put(makeDepositFailure(error));
  }
}

function* makeIntermediateDeposit({ payload: { data, history } }) {
  try {
    const response = yield call(postManagerDeposit, data);
    if (response.success) {
      yield put(makeIntermediateDepositSuccess(response.body));
    } else {
      yield put(makeIntermediateDepositFailure(response.message));
    }
  } catch (error) {
    yield put(makeIntermediateDepositFailure(error));
  }
}

function* makeWithdrawalRequest({ payload: { data, history } }) {
  try {
    const response = yield call(postWithdrawalRequest, data);
    if (response.success) {
      yield put(makeWithdrawalSuccess(response.body));
      history.push("/wallet/withdrawal-requests");
    } else {
      yield put(makeWithdrawalFailure(response.message));
    }
  } catch (error) {
    yield put(makeWithdrawalFailure(error));
  }
}

function* traitWithdrawalRequest({ payload: { id, state, reason } }) {
  try {
    const response = yield call(treatReceiveWithdrawalRequest, {
      requestId: id,
      accept: state,
      reason: reason
    });
    if (response.success) {
      yield put(traitWithdrawalSuccess(response.body));
    } else {
      yield put(traitWithdrawalFailure(response.message));
    }
  } catch (error) {
    yield put(traitWithdrawalFailure(error));
  }
}

function* fetchPaymentProvider() {
  try {
    const response = yield call(getPaymentProviders);
    if (response.success) {
      yield put(getProviderSuccess(response.body));
    } else {
      yield put(getProvidersFailure(response.message));
    }
  } catch (error) {
    yield put(getProvidersFailure(error));
  }
}

function* walletSaga() {
  yield takeEvery(GET_TRANSACTION_HISTORY, fetchTransactionHistory);
  yield takeEvery(GET_CLIENT_TRANSACTION_HISTORY, fetchClientTransactionHistory);
  yield takeEvery(GET_BALANCE, fetchBalance);
  yield takeEvery(MAKE_DEPOSIT, makeSelfDeposit);
  yield takeEvery(MAKE_INTERMEDIATE_DEPOSIT, makeIntermediateDeposit);
  yield takeEvery(GET_PAYMENT_PROVIDER, fetchPaymentProvider);
  yield takeEvery(MAKE_WITHDRAWAL_REQUEST, makeWithdrawalRequest);
  yield takeEvery(GET_WITHDRAWAL_REQUEST, fetchMyWithdrawalRequest);
  yield takeEvery(
    GET_MICROFINANCE_WITHDRAWAL_REQUEST,
    fetchMicrofinanceWithdrawalRequest
  );
  yield takeEvery(
    TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST,
    traitWithdrawalRequest
  );
}

export default walletSaga;
