import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

//i18n
import { withTranslation } from "react-i18next";
import { isMicrofinance, isSaverOnly } from "../../helpers/session_helper";

class MicrofinanceMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!isMicrofinance()) {
      return "";
    }

    return (
      <React.Fragment>
        <li>
          <Link to="/#" className="has-arrow">
            <i className="bx bxs-user-detail" />
            <span>{this.props.t("Customers")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/affiliate/request">
                {this.props.t("Subscribe saver request list")}
              </Link>
            </li>
            <li>
              <Link to="/microfinance/withdrawal-requests">
                {this.props.t("Withdrawal request")}
              </Link>
            </li>
            <li>
              <Link to="/customers">{this.props.t("Customers")}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/#" className="has-arrow">
            <i className="bx bxs-file" />
            <span>{this.props.t("XPayLater")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/xpaylater/loan/request">
                {this.props.t("Loan request")}
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/#" className="has-arrow">
            <i className="bx bx-receipt" />
            <span>{this.props.t("Collector")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/collaboration/requests">
                {this.props.t("Collector collaboration requests")}
              </Link>
            </li>
            <li>
              <Link to="/collaborators">{this.props.t("Collaborators")}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/#" className="has-arrow">
            <i className="bx bx-cog" />
            <span>{this.props.t("Settings")}</span>
          </Link>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <Link to="/settings/committees">
                {this.props.t("Committee")}
              </Link>
            </li>
          </ul>
        </li>
      </React.Fragment>
    );
  }
}

MicrofinanceMenu.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(MicrofinanceMenu));
