import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  getMicrofinanceWithdrawalRequests,
  traitWithdrawal,
} from "../../store/wallet/actions";
// datatable related plugins
import "./datatables.scss";
import React, { Component } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { normalizeDate } from "../../helpers/core_helper";
import Select from "react-select";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { traiteRequest } from "../../store/saver/actions";

class WithdrawalRequestsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      formModal: false,
      formModalEdit: false,
      showInfo: false,
    };
    this.toggleRequest = this.toggleRequest.bind(this);
  }

  componentDidMount() {
    const { onGetRequests } = this.props;
    onGetRequests();
  }

  getStateOptions() {
    return [
      { label: this.props.t("PENDING"), value: "PENDING" },
      { label: this.props.t("ACCEPTED"), value: "ACCEPTED" },
      { label: this.props.t("DENIED"), value: "DENIED" },
    ];
  }

  toggleRequest() {
    this.setState({
      formModalEdit: !this.state.formModalEdit,
    });
  }

  handleRequestClick = request => {
    this.setState({
      request: request,
    });
    this.toggleRequest();
  };

  render() {
    const { SearchBar } = Search;

    let columns = [
      {
        dataField: "id",
        text: "#Ref",
        sort: true,
        formatter: (cellContent, row, idx) => (
          <>
            <span className="text-body fw-bold">#WIR{row.id}</span>
          </>
        ),
      },
      {
        dataField: "saver",
        text: this.props.t("Lastname"),
        formatter: (cellContent, row) => {
          return row.saver.user.lastName + " " + row.saver.user.firstName;
        },
        sort: true,
      },
      // {
      //   dataField: "phone",
      //   text: this.props.t("Phone"),
      //   formatter: (cellContent, row) => {
      //     return row.saver.user.phone;
      //   },
      //   sort: true
      // },
      {
        dataField: "receiverPhone",
        text: this.props.t("Receiver Phone"),
        sort: true,
      },
      {
        dataField: "amount",
        text: this.props.t("Amount"),
        formatter: (cellContent, row) => {
          return Math.abs(row.amount) + " XAF";
        },
        sort: true,
      },
      {
        dataField: "state",
        text: this.props.t("State"),
        sort: true,
        formatter: (cellContent, row) => {
          let color = "danger";
          switch (row.state) {
            case "PENDING":
              color = "warning";
              break;
            case "ACCEPTED":
              color = "success";
              break;
          }
          return (
            <Badge
              className={"font-size-12 badge-soft-" + color}
              color={color}
              pill
            >
              {this.props.t(row.state)}
            </Badge>
          );
        },
      },
      {
        dataField: "deniedReason",
        text: this.props.t("Denied reason"),
        sort: false,
      },
      {
        dataField: "createdAt",
        text: this.props.t("Created at"),
        sort: true,
        formatter: (cellContent, row) => normalizeDate(row.createdAt),
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        formatter: (cellContent, request) => (
          <>
            <div className="d-flex gap-3">
              <Button className="text-success">
                <i
                  className="mdi mdi-eye font-size-12"
                  id="view"
                  // onClick={() => this.toggleInfo()}
                />
              </Button>
              {request.state !== "PENDING" ? (
                ""
              ) : (
                <Button className="btn-success">
                  <i
                    className="mdi mdi-pencil font-size-12"
                    id="update"
                    onClick={() => this.handleRequestClick(request)}
                  />
                </Button>
              )}
            </div>
          </>
        ),
      },
    ];

    const defaultSorted = [
      {
        dataField: "createdAt",
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.requests.length, // replace later with size(customers),
      custom: true,
    };

    return (
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Col md={3} className="float-end">
                  <Select
                    onChange={select => {
                      this.props.onGetRequests(select.value);
                    }}
                    selected={this.getStateOptions()[0]}
                    options={this.getStateOptions()}
                    classNamePrefix="select2-selection"
                  />
                </Col>
                <CardTitle className="h4">
                  {this.props.t("Withdrawal request")}
                </CardTitle>
                <p className="card-title-desc"> .</p>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={this.state.requests}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={this.props.requests}
                      bootstrap4
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col sm="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  //selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Modal
                            isOpen={this.state.formModalEdit}
                            className={this.props.className}
                          >
                            <ModalHeader toggle={this.toggleRequest} tag="h4">
                              {this.props.t("Treat request")}
                            </ModalHeader>
                            <ModalBody>
                              <Formik
                                enableReinitialize={true}
                                initialValues={{
                                  accept:
                                    (this.accept && this.state.request) || "",
                                  reason:
                                    (this.state && this.state.reason) || "",
                                }}
                                validationSchema={Yup.object().shape({
                                  accept: Yup.string().required(
                                    "Please Select a status"
                                  ),
                                  reason: Yup.string().when(["accept"], {
                                    is: accept => accept === false,
                                    then: Yup.string().required(
                                      this.props.t("Comment required")
                                    ),
                                  }),
                                })}
                                validateOnChange={true}
                                onSubmit={values => {
                                  this.props.onTraite(
                                    this.state.request.id,
                                    values.accept,
                                    values.reason
                                  );
                                }}
                              >
                                {({
                                  errors,
                                  status,
                                  touched,
                                  setValues,
                                  values,
                                }) => (
                                  <Form>
                                    {this.props.updateError &&
                                    this.props.updateError ? (
                                      <Alert color="danger">
                                        {this.props.updateError}
                                      </Alert>
                                    ) : null}
                                    <Row>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label>{this.props.t("State")}</Label>
                                          <Select
                                            onChange={state =>
                                              setValues({
                                                ...values,
                                                accept: state.value,
                                              })
                                            }
                                            options={[
                                              {
                                                label: this.props.t("ACCEPTED"),
                                                value: true,
                                              },
                                              {
                                                label: this.props.t("DENIED"),
                                                value: false,
                                              },
                                            ]}
                                            value={this.state.accept}
                                            classNamePrefix="select2-selection"
                                            className={
                                              errors.accept && touched.accept
                                                ? " is-invalid"
                                                : ""
                                            }
                                          />
                                          <ErrorMessage
                                            name="accept"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </Col>
                                      <Col className="col-12">
                                        <div className="mb-3">
                                          <Label>
                                            {this.props.t("Comment")}
                                          </Label>
                                          <Field
                                            name="reason"
                                            as="textarea"
                                            maxLength="225"
                                            rows="3"
                                            className={
                                              "form-control" +
                                              (errors.reason && touched.reason
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="reason"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">
                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            {this.props.loadingTrait ? (
                                              <i
                                                className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"
                                                if
                                              ></i>
                                            ) : (
                                              ""
                                            )}
                                            {this.props.t("Save")}
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Form>
                                )}
                              </Formik>
                            </ModalBody>
                          </Modal>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

WithdrawalRequestsTable.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  loadingTrait: PropTypes.bool,
  t: PropTypes.any,
  requests: PropTypes.any,
  onGetRequests: PropTypes.func,
  onTrait: PropTypes.func,
};

const mapStateToProps = state => ({
  requests: state.Wallet.receiveWithdrawalRequests,
  loadingTrait: state.Wallet.loadingTraitWithdrawal,
  error: state.Wallet.error,
  updateError: state.Wallet.traitWithdrawalError,
});
const mapDispatchToProps = dispatch => ({
  onGetRequests: (state = "PENDING") =>
    dispatch(getMicrofinanceWithdrawalRequests(state)),
  onTraite: (id, state, reason) => dispatch(traitWithdrawal(id, state, reason)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(WithdrawalRequestsTable))
);
