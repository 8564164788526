import {
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_CLAIM,
  VERIFY_ACCOUNT_CLAIM_SUCCESS,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_FAILURE,
} from "./actionTypes";

const initialState = {
  error: "",
  message: "",
  loading: false,
};

const accountVerification = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_ACCOUNT:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case VERIFY_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
      break;
    case VERIFY_FAILURE:
      state = { ...state, loading: false, error: action.payload };
      break;
    case VERIFY_ACCOUNT_CLAIM:
      state = { ...state, loading: true };
      break;
    case VERIFY_ACCOUNT_CLAIM_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload,
        error: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default accountVerification;
