/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";

export const GET_SAVER_DASHBOARD = "GET_SAVER_DASHBOARD";
export const GET_SAVER_DASHBOARD_SUCCESS = "GET_SAVER_DASHBOARD_SUCCESS";
export const GET_SAVER_DASHBOARD_FAIL = "GET_SAVER_DASHBOARD_FAIL";

export const GET_CLIENT_DASHBOARD = "GET_CLIENT_DASHBOARD";
export const GET_CLIENT_DASHBOARD_SUCCESS = "GET_CLIENT_DASHBOARD_SUCCESS";
export const GET_CLIENT_DASHBOARD_FAIL = "GET_CLIENT_DASHBOARD_FAIL";

export const GET_MICROFINANCE_DASHBOARD = "GET_MICROFINANCE_DASHBOARD";
export const GET_MICROFINANCE_DASHBOARD_SUCCESS =
  "GET_MICROFINANCE_DASHBOARD_SUCCESS";
export const GET_MICROFINANCE_DASHBOARD_FAIL =
  "GET_MICROFINANCE_DASHBOARD_FAIL";
