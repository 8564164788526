import { call, put, takeEvery } from "redux-saga/effects";
import { setCollaboration } from "../../helpers/session_helper";
// Collector Redux States
import {
  COLLECTOR_COLLABORATION_MY_CLIENTS,
  COLLECTOR_COLLABORATION_REQUEST,
  COLLECTOR_COLLABORATION_REQUEST_RECEIVE,
  COLLECTOR_COLLABORATION_REQUEST_SENT,
  COLLECTOR_COLLABORATION_REQUEST_STATUS,
  COLLECTOR_COLLABORATION_REQUEST_UPDATE,
} from "./actionTypes";
import {
  collaborationFailure,
  collaborationStatusFailure,
  collaborationStatusSuccess,
  collaborationSuccess,
  receiveCollaborationRequestsFailure,
  receiveCollaborationRequestsSuccess,
  sentCollaborationRequestsFailure,
  sentCollaborationRequestsSuccess,
  microfinanceTreatRequestFailure,
  microfinanceTreatRequestSuccess,
  myClientsSuccess,
  myClientsFailure,
  myClients,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getClientsAsCollector,
  getCollectorCollaborationRequestsReceive,
  getCollectorCollaborationRequestsSent,
  patchCollectorCollaboration,
  postCollectorCollaboration,
} from "../../helpers/Api/collectorApi";

function* getSentCollaborationRequests() {
  try {
    const response = yield call(getCollectorCollaborationRequestsSent);
    if (response.success) {
      yield put(sentCollaborationRequestsSuccess(response.body));
    } else {
      yield put(sentCollaborationRequestsFailure(response.message));
    }
  } catch (error) {
    yield put(sentCollaborationRequestsFailure(error));
  }
}

function* getMyClients() {
  try {
    const response = yield call(getClientsAsCollector);
    if (response.success) {
      yield put(myClientsSuccess(response.body));
    } else {
      yield put(myClientsFailure(response.message));
    }
  } catch (error) {
    yield put(myClientsFailure(error));
  }
}

function* getReceiveCollaborationRequests({ payload: { state } }) {
  try {
    const response = yield call(getCollectorCollaborationRequestsReceive, {
      state: state,
    });
    if (response.success) {
      yield put(receiveCollaborationRequestsSuccess(response.body));
    } else {
      yield put(receiveCollaborationRequestsFailure(response.message));
    }
  } catch (error) {
    yield put(receiveCollaborationRequestsFailure(error));
  }
}

function* subscribe({ payload: { microfinanceId } }) {
  try {
    const response = yield call(postCollectorCollaboration, microfinanceId);
    if (response.success) {
      yield put(collaborationSuccess(response.body));
      location.reload();
    } else {
      yield put(collaborationFailure(response.message));
    }
  } catch (error) {
    yield put(collaborationFailure(error));
  }
}

function* update({ payload: { id, state, reason } }) {
  try {
    const response = yield call(patchCollectorCollaboration, {
      id: id,
      state: state,
      reason: reason,
    });
    if (response.success) {
      yield put(microfinanceTreatRequestSuccess(response.body));
      location.reload();
    } else {
      yield put(microfinanceTreatRequestFailure(response.message));
    }
  } catch (error) {
    yield put(microfinanceTreatRequestFailure(error));
  }
}

function* collectorSaga() {
  yield takeEvery(
    COLLECTOR_COLLABORATION_REQUEST_SENT,
    getSentCollaborationRequests
  );
  yield takeEvery(
    COLLECTOR_COLLABORATION_REQUEST_RECEIVE,
    getReceiveCollaborationRequests
  );
  yield takeEvery(COLLECTOR_COLLABORATION_REQUEST, subscribe);
  yield takeEvery(COLLECTOR_COLLABORATION_REQUEST_UPDATE, update);
  yield takeEvery(COLLECTOR_COLLABORATION_MY_CLIENTS, getMyClients);
}

export default collectorSaga;
