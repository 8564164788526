export const COLLECTOR_COLLABORATION_REQUEST =
  "COLLECTOR_COLLABORATION_REQUEST";
export const COLLECTOR_COLLABORATION_REQUEST_SUCCESS =
  "COLLECTOR_COLLABORATION_REQUEST_SUCCESS";
export const COLLECTOR_COLLABORATION_REQUEST_FAILURE =
  "COLLECTOR_COLLABORATION_REQUEST_FAILURE";

export const COLLECTOR_COLLABORATION_REQUEST_UPDATE =
  "COLLECTOR_COLLABORATION_REQUEST_UPDATE";
export const COLLECTOR_COLLABORATION_REQUEST_UPDATE_SUCCESS =
  "COLLECTOR_COLLABORATION_REQUEST_UPDATE_SUCCESS";
export const COLLECTOR_COLLABORATION_REQUEST_UPDATE_FAILURE =
  "COLLECTOR_COLLABORATION_REQUEST_UPDATE_FAILURE";

export const COLLECTOR_COLLABORATION_REQUEST_STATUS =
  "COLLECTOR_COLLABORATION_REQUEST_STATUS";
export const COLLECTOR_COLLABORATION_REQUEST_STATUS_SUCCESS =
  "COLLECTOR_COLLABORATION_REQUEST_STATUS_SUCCESS";
export const COLLECTOR_COLLABORATION_REQUEST_STATUS_FAILURE =
  "COLLECTOR_COLLABORATION_REQUEST_STATUS_FAILURE";

export const COLLECTOR_COLLABORATION_REQUEST_SENT =
  "COLLECTOR_COLLABORATION_REQUEST_SENT";
export const COLLECTOR_COLLABORATION_REQUEST_SENT_SUCCESS =
  "COLLECTOR_COLLABORATION_REQUEST_SENT_SUCCESS";
export const COLLECTOR_COLLABORATION_REQUEST_SENT_FAILURE =
  "COLLECTOR_COLLABORATION_REQUEST_SENT_FAILURE";

export const COLLECTOR_COLLABORATION_REQUEST_RECEIVE =
  "COLLECTOR_COLLABORATION_REQUEST_RECEIVE";
export const COLLECTOR_COLLABORATION_REQUEST_RECEIVE_SUCCESS =
  "COLLECTOR_COLLABORATION_REQUEST_RECEIVE_SUCCESS";
export const COLLECTOR_COLLABORATION_REQUEST_RECEIVE_FAILURE =
  "COLLECTOR_COLLABORATION_REQUEST_RECEIVE_FAILURE";

export const COLLECTOR_COLLABORATION_MY_CLIENTS =
  "COLLECTOR_COLLABORATION_MY_CLIENTS";
export const COLLECTOR_COLLABORATION_MY_CLIENTS_SUCCESS =
  "COLLECTOR_COLLABORATION_MY_CLIENTS_SUCCESS";
export const COLLECTOR_COLLABORATION_MY_CLIENTS_FAILURE =
  "COLLECTOR_COLLABORATION_MY_CLIENTS_FAILURE";
