import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Images
import client1 from "../../../assets/images/clients/asset 4.jpeg";
import client2 from "../../../assets/images/clients/asset 5.png";
import client3 from "../../../assets/images/clients/asset 6.jpeg";
import client4 from "../../../assets/images/clients/asset 7.jpeg";
import client5 from "../../../assets/images/clients/asset 8.jpeg";
import client6 from "../../../assets/images/clients/asset 9.jpeg";

class AboutUs extends Component {
  state = {
    step1: true,
    step2: false,
    responsive: {
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {
        items: 4,
      },
    },
    clients: [
      { imgUrl: client1 },
      { imgUrl: client2 },
      { imgUrl: client3 },
      { imgUrl: client4 },
      { imgUrl: client5 },
      { imgUrl: client6 },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section pt-4 bg-white" id="about">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <div className="small-title">Ce que nous faisons</div>
                  <h4>Comment ça marche</h4>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg="5">
                <div className="text-muted">
                  <h4>Ensemble pour nos clients </h4>
                  <p>
                    C’est avec de nombreux partenaires que nous avons choisi de
                    vivre cette aventure au service de nos clients.
                  </p>
                  <p className="mb-4">Devenir partenaire en tant que .</p>

                  <div className="button-items">
                    <Link
                      to="/register/microfinance"
                      className="btn btn-success me-1"
                    >
                      Microfinance
                    </Link>
                    <Link
                      to="/register/vendor"
                      className="btn btn-outline-primary"
                    >
                      Xpaylater
                    </Link>
                  </div>

                </div>
              </Col>

              <Col lg="6" className="ms-auto">
                <div className="mt-4 mt-lg-0">
                  <Row>
                    <Col sm="6">
                      <Card className="border">
                        <CardBody>
                          <div className="mb-3">
                            <i className="mdi mdi-bank-check h2 text-success" />
                          </div>
                          <h5>Boostez votre épargne </h5>
                          <p className="text-muted mb-0">
                            Transférez dans votre compte d’épargne en quelques
                            clics avec loan2cash .
                          </p>
                        </CardBody>
                        <div className="card-footer bg-transparent border-top text-center">
                          <Link to="login" className="text-primary">
                            En savoir plus
                          </Link>
                        </div>
                      </Card>
                    </Col>
                    <Col sm="6">
                      <Card className="border mt-lg-5">
                        <CardBody>
                          <div className="mb-3">
                            <i className="mdi mdi-cart h2 text-success" />
                          </div>
                          <h5>Commandez …Payez doucement </h5>
                          <p className="text-muted mb-0">
                            Profitez de nos facilités de paiement chez loan2cash
                          </p>
                        </CardBody>
                        <div className="card-footer bg-transparent border-top text-center">
                          <Link to="login" className="text-primary">
                            En savoir plus
                          </Link>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <hr className="my-5" />

            <Row>
              <Col lg="12">
                <div className="hori-timeline">
                  <div
                    className="owl-carousel owl-theme  navs-carousel events"
                    id="timeline-carousel"
                  >
                    {this.state.step1 ? (
                      <>
                        <Row>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client1}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client2}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client3}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client4}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}

                    {this.state.step2 ? (
                      <>
                        <Row>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client4}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client5}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client6}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="item">
                              <div className="client-images">
                                <img
                                  src={client1}
                                  alt="client-img"
                                  className="mx-auto img-fluid d-block"
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : null}

                    <div className="owl-nav" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({ step1: true, step2: false });
                        }}
                        className="border-0"
                        disabled={this.state.step1}
                      >
                        <i className="mdi mdi-chevron-left" />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({ step1: false, step2: true });
                        }}
                        className="border-0"
                        disabled={this.state.step2}
                      >
                        <i className="mdi mdi-chevron-right" />
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
