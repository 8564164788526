import React, { Component } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import PropTypes from "prop-types";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import "./datatables.scss";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Select from "react-select";
import { normalizeDate } from "../../helpers/core_helper";
import { receivedRequest, traitLoanRequest } from "../../store/xpaylater/actions";

class LoanRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      formModal: false,
      formModalEdit: false,
      showInfo: false
    };

    this.toggle = this.toggle.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.toggleRequest = this.toggleRequest.bind(this);
  }

  getStateOptions() {
    return [
      { label: this.props.t("ACCEPTED"), value: "ACCEPTED" },
      { label: this.props.t("DENIED"), value: "DENIED" },
      { label: this.props.t("PENDING"), value: "PENDING" }
    ];
  }

  getSelectedState(value) {
    return this.getStateOptions().find(o => o.value === value);
  }

  componentDidMount() {
    const { onGetRequests } = this.props;
    onGetRequests();
  }

  toggleInfo() {
    this.setState({
      showInfo: !this.state.showInfo
    });
  }

  toggle() {
    this.setState({
      formModal: !this.state.formModal
    });
  }

  handleOrderClicks = () => {
    //this.setState({ order: "", isEdit: false });
    this.toggle();
  };


  toggleRequest() {
    this.setState({
      formModalEdit: !this.state.formModalEdit
    });
  }

  handleRequestClick = request => {
    this.setState({
      request: request
    });
    this.toggleRequest();
  };

  render() {
    const { SearchBar } = Search;

    let columns = [
      {
        dataField: "id",
        text: "#",
        sort: true,
        formatter: (cellContent, row, idx) => (
          <>
            <span className="text-body fw-bold">#LR{idx + 1}</span>
          </>
        )
      },
      {
        dataField: "saver.user.name",
        text: this.props.t("Lastname"),
        sort: true
      },
      {
        dataField: "amount",
        text: this.props.t("Amount"),
        sort: true
      },
      {
        dataField: "period.weekSlip",
        text: this.props.t("Recovery frequency"),
        sort: true,
        formatter: (cellContent, row) => row.period.weekSlip+" Semaine"
      },
      {
        dataField: "state",
        text: this.props.t("State"),
        sort: true,
        formatter: (cellContent, row) => {
          let color = "danger";
          switch (row.state) {
            case "PENDING":
              color = "warning";
              break;
            case "ACCEPTED":
              color = "success";
              break;
          }
          return (
            <Badge
              className={"font-size-12 badge-soft-" + color}
              color={color}
              pill
            >
              {this.props.t(row.state)}
            </Badge>
          );
        }
      },
      {
        dataField: "deniedReason",
        text: this.props.t("Denied reason"),
        sort: true
      },
      {
        dataField: "createdAt",
        text: this.props.t("Created at"),
        sort: true,
        formatter: (cellContent, row) => normalizeDate(row.createdAt)
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        formatter: (cellContent, request) => (
          <>
            <div className="d-flex gap-3">
              <Button className="text-success" onClick={() => {
                this.props.history.push({
                  pathname: "/customers/profile",
                  search: "?username=" + request.saver.user.phone + "&name=" + request.saver.user.name
                });
              }}>
                <i
                  className="mdi mdi-eye font-size-12"
                  id="view"
                />
              </Button>
              <Button className="btn-success">
                <i
                  className="mdi mdi-pencil font-size-12"
                  id="update"
                  onClick={() => this.handleRequestClick(request)}
                />
              </Button>
            </div>
          </>
        )
      }
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc"
      }
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.loanRequests.length, // replace later with size(customers),
      custom: true
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Subscribe saver request list")}
              breadcrumbItem="List"
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Col md="4" className="float-end">
                      <div>
                        <Select
                          onChange={select => {
                            this.props.onGetRequests(select.value);
                          }}
                          options={this.getStateOptions()}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>


                    <CardTitle className="h4">
                      {this.props.t("Subscribe saver request list")}
                    </CardTitle>
                    <p className="card-title-desc"> .</p>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.loanRequests}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.props.loanRequests}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                    <Modal
                                      isOpen={this.state.formModalEdit}
                                      className={this.props.className}
                                    >
                                      <ModalHeader
                                        toggle={this.toggleRequest}
                                        tag="h4"
                                      >
                                        {this.props.t("Treat request")}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Formik
                                          enableReinitialize={true}
                                          initialValues={{
                                            state:
                                              (this.state &&
                                                this.state.request &&
                                                this.getSelectedState(
                                                  this.state.request.state || ""
                                                )) ||
                                              "",
                                            reason:
                                              (this.state &&
                                                this.state.reason) ||
                                              ""
                                          }}
                                          validationSchema={Yup.object().shape({
                                            state: Yup.string().required(
                                              "Please Select a status"
                                            ),
                                            reason: Yup.string().when(
                                              ["state"],
                                              {
                                                is: state =>
                                                  ["DENIED"].includes(state),
                                                then: Yup.string().required(
                                                  this.props.t(
                                                    "Comment required"
                                                  )
                                                )
                                              }
                                            )
                                          })}
                                          validateOnChange={true}
                                          onSubmit={values => {
                                            this.props.onTraite(
                                              this.state.request.id,
                                              values.state,
                                              values.reason
                                            );
                                          }}
                                        >
                                          {({
                                              errors,
                                              status,
                                              touched,
                                              setValues,
                                              values
                                            }) => (
                                            <Form>
                                              {this.props.updateError &&
                                              this.props.updateError ? (
                                                <Alert color="danger">
                                                  {this.props.updateError}
                                                </Alert>
                                              ) : null}
                                              <Row>
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <Label>
                                                      {this.props.t("State")}
                                                    </Label>
                                                    <Select
                                                      onChange={state =>
                                                        setValues({
                                                          ...values,
                                                          state: state.value
                                                        })
                                                      }
                                                      options={this.getStateOptions()}
                                                      value={this.state.state}
                                                      classNamePrefix="select2-selection"
                                                      className={
                                                        errors.state &&
                                                        touched.state
                                                          ? " is-invalid"
                                                          : ""
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="state"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <Label>
                                                      {this.props.t("Comment")}
                                                    </Label>
                                                    <Field
                                                      name="reason"
                                                      as="textarea"
                                                      maxLength="225"
                                                      rows="3"
                                                      className={
                                                        "form-control" +
                                                        (errors.reason &&
                                                        touched.reason
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="reason"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-user"
                                                    >
                                                      {this.props
                                                        .loadingTraite ? (
                                                        <i
                                                          className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"
                                                          if
                                                        ></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {this.props.t("Save")}
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Form>
                                          )}
                                        </Formik>
                                      </ModalBody>
                                    </Modal>
                                    {/*<SaverInfoModal visible={this.state.showInfo}/>*/}
                                    {/*<AffiliateRequestForm visible={this.state.formModalEdit}*/}
                                    {/*                      request={this.props.selectedRequest} />*/}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

LoanRequest.propTypes = {
  error: PropTypes.any,
  updateError: PropTypes.any,
  t: PropTypes.any,
  loanRequests: PropTypes.any,
  onGetRequests: PropTypes.func,
  loadingMicrofinance: PropTypes.bool,
  loadingTraite: PropTypes.bool,
  loadingSubscription: PropTypes.bool,
  className: PropTypes.any,
  history: PropTypes.any,
  closeModal: PropTypes.bool,
  onTraite: PropTypes.func
};

const mapStateToProps = state => ({
  loanRequests: state.XPayLater.loanRequests,
  loadingSubscription: state.XPayLater.loadingSubscribe,
  loadingTraite: state.XPayLater.loadingUpdate,
  error: state.XPayLater.error,
  updateError: state.XPayLater.updateError,
  closeModal: state.XPayLater.close
});

const mapDispatchToProps = dispatch => ({
  onGetRequests: (state = "PENDING") =>
    dispatch(
      receivedRequest(state)
    ),
  onTraite: (id, state, reason) =>
    dispatch(traitLoanRequest(id, state, reason))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(LoanRequest))
);
