import { call, put, takeEvery } from "redux-saga/effects";
// Collector Redux States
import { MANAGER_SUBSCRIBE } from "./actionTypes";

//Include Both Helper File with needed methods
import { getCollectorCollaborationRequestsSent } from "../../helpers/Api/collectorApi";
import { subscribeFailure, subscribeSuccess } from "./actions";
import { postManagerAffiliate } from "../../helpers/Api/saverApi";

function* subscribe({ payload: { data } }) {
  try {
    const response = yield call(postManagerAffiliate, data);
    if (response.success) {
      location.reload();
      yield put(subscribeSuccess(response.body));
    } else {
      yield put(subscribeFailure(response.message));
    }
  } catch (error) {
    yield put(subscribeFailure(error));
  }
}

function* collectorSaga() {
  yield takeEvery(MANAGER_SUBSCRIBE, subscribe);
}

export default collectorSaga;
