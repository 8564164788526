export const LOAN_REQUEST =
  "LOAN_REQUEST";
export const LOAN_REQUEST_SUCCESS =
  "LOAN_REQUEST_SUCCESS";
export const LOAN_REQUEST_FAILURE =
  "LOAN_REQUEST_FAILURE";

export const LOAN_REQUEST_UPDATE =
  "LOAN_REQUEST_UPDATE";
export const LOAN_REQUEST_UPDATE_SUCCESS =
  "LOAN_REQUEST_UPDATE_SUCCESS";
export const LOAN_REQUEST_UPDATE_FAILURE =
  "LOAN_REQUEST_UPDATE_FAILURE";

export const LOAN_REQUEST_SENT = "LOAN_REQUEST_SENT";
export const LOAN_REQUEST_SENT_SUCCESS = "LOAN_REQUEST_SENT_SUCCESS";
export const LOAN_REQUEST_SENT_FAILURE = "LOAN_REQUEST_SENT_FAILURE";

export const LOAN_REQUEST_RECEIVE =
  "LOAN_REQUEST_RECEIVE";
export const LOAN_REQUEST_RECEIVE_SUCCESS =
  "LOAN_REQUEST_RECEIVE_SUCCESS";
export const LOAN_REQUEST_RECEIVE_FAILURE =
  "LOAN_REQUEST_RECEIVE_FAILURE";

export const LOAN_MY_CLIENTS =
  "LOAN_MY_CLIENTS";
export const LOAN_MY_CLIENTS_SUCCESS =
  "LOAN_MY_CLIENTS_SUCCESS";
export const LOAN_MY_CLIENTS_FAILURE =
  "LOAN_MY_CLIENTS_FAILURE";
