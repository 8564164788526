import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getClientTransactions } from "../../store/wallet/actions";
// datatable related plugins
import "./datatables.scss";
import React, { Component } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { normalizeDate } from "../../helpers/core_helper";
import Balance from "../../components/Saver/Balance";
import MicrofinanceAffiliation from "../../components/Saver/MicrofinanceAffiliation";

class WalletHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      formModal: false,
      formModalEdit: false,
      showInfo: false,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search) ;
    const { onGetTransactions } = this.props;
    onGetTransactions(params.get("username"));
  }

  render() {
    const { SearchBar } = Search;

    let columns = [
      {
        dataField: "id",
        text: "#Ref",
        sort: true,
        formatter: (cellContent, row, idx) => (
          <>
            <span className="text-body fw-bold">#{row.id}</span>
          </>
        ),
      },
      {
        dataField: "amount",
        text: this.props.t("Amount"),
        formatter: (cellContent, row) => {
          return Math.abs(row.amount);
        },
        sort: true,
      },
      {
        dataField: "state",
        text: this.props.t("Type"),
        sort: true,
        formatter: (cellContent, row) => {
          let color = "danger";
          if (row.amount > 0) {
            color = "success";
          }
          return (
            <Badge
              className={"font-size-12 badge-soft-" + color}
              color={color}
              pill
            >
              {this.props.t(row.amount > 0 ? "Deposit" : "Withdrawal")}
            </Badge>
          );
        },
      },
      {
        dataField: "paymentProvider",
        text: this.props.t("Operator"),
        sort: true,
      },
      {
        dataField: "description",
        text: this.props.t("Description"),
        sort: true,
      },
      // {
      //   dataField: "verifiedAt", text: this.props.t("Created at"), sort: true,
      //   formatter: (cellContent, row) => this.handleValidDate(row.createdAt)
      // },
      {
        dataField: "createdAt",
        text: this.props.t("Created at"),
        sort: true,
        formatter: (cellContent, row) => normalizeDate(row.createdAt),
      },
    ];

    const defaultSorted = [
      {
        dataField: "createdAt",
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.transactions.length, // replace later with size(customers),
      custom: true,
    };

    return (
      <React.Fragment>
        <Container fluid>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    {this.props.t("Transaction History")}
                  </CardTitle>
                  <p className="card-title-desc"> .</p>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={this.state.transactions}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={this.props.transactions}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    //selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

WalletHistory.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  t: PropTypes.any,
  transactions: PropTypes.any,
  onGetTransactions: PropTypes.func,
};

const mapStateToProps = state => ({
  transactions: state.Wallet.transactions,
  loading: state.Wallet.loading,
  error: state.Wallet.error,
});
const mapDispatchToProps = dispatch => ({
  onGetTransactions: (phone) => dispatch(getClientTransactions(phone)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WalletHistory))
);
