import * as actionTypes from "./actionTypes";

const INIT_STATE = {
  countries: [],
  cities: [],
  districts: [],
  error: {},
  loadingCountries: true,
};

const Location = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_COUNTRY_RESOURCES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loadingCountries: false,
      };
    case actionTypes.GET_COUNTRY_RESOURCES_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingCountries: false,
      };
    case actionTypes.GET_CITY_RESOURCES_SUCCESS:
      return {
        ...state,
        cities: action.payload,
      };

    case actionTypes.GET_CITY_RESOURCES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.GET_DISTRICT_RESOURCES_SUCCESS:
      return {
        ...state,
        districts: action.payload,
      };

    case actionTypes.GET_DISTRICT_RESOURCES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Location;
