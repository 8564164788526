import React, { Component } from "react";
import { Alert, Container } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  isAffiliate,
  isMicrofinance,
  isSaverOnly,
} from "../../helpers/session_helper";
import { affiliateStatus } from "../../store/saver/actions";
import { withTranslation } from "react-i18next";
import Saver from "./Saver";
import Microfinance from "./Microfinance";
import MicrofinanceAffiliation from "../../components/Saver/MicrofinanceAffiliation";

class Dashboard extends Component {
  render() {
    document.title = "Dashboard | loan2cash";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <MicrofinanceAffiliation />
            <Saver visible={isSaverOnly()} />
            <Microfinance visible={isMicrofinance()} />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  error: PropTypes.any,
  message: PropTypes.any,
  history: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.any,
};

const mapStateToProps = state => ({
  error: state.Saver.statusError,
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard))
);
