import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Components
import CardBox from "./card-box";

class CardsMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coins: [
        {
          title: "Partenariats",
          color: "warning",
          icon: "mdi mdi-handshake",
          value: "10",
          rate: "0.0012.23 ( 0.2 % )",
          isIncrease: true,
        },
        {
          title: "Utilisateurs",
          color: "primary",
          icon: "mdi mdi-card-account-details-star",
          value: "10000",
          rate: "5 ( 0.1 % )",
          isIncrease: true,
        },
        {
          title: "Transaction",
          color: "info",
          icon: "mdi mdi-credit-card-check",
          value: "10000",
          rate: "70000 ( 0.1 % ) ",
          isIncrease: true,
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-white p-0">
          <Container>
            <div className="currency-price">
              <Row>
                {/* reder card boxes */}
                <CardBox coins={this.state.coins} />
              </Row>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default CardsMini;
