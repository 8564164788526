import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  getProvider,
  makeDeposit,
  makeWithdrawal,
} from "../../store/wallet/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Balance from "../../components/Saver/Balance";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../components/Common/ButtonLoader";
import { getAccount } from "../../helpers/session_helper";
import MicrofinanceAffiliation from "../../components/Saver/MicrofinanceAffiliation";

class CashInOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      isMenu: false,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const { onGetProviders } = this.props;
    onGetProviders();

    if (this.props.location.state && this.props.location.state.activeTab) {
      this.toggleTab(this.props.location.state.activeTab);
    }
  }

  render() {
    //meta title
    document.title =
      this.props.t("Deposit") +
      "/" +
      this.props.t("Withdrawal") +
      "  | loan2cash";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <MicrofinanceAffiliation />
            <Breadcrumbs
              title={this.props.t("Wallet")}
              breadcrumbItem={
                this.props.t("Deposit") + "/" + this.props.t("Withdrawal")
              }
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="float-end">
                      <Balance />
                    </div>
                    <h4 className="card-title mb-4">
                      {this.props.t("Deposit") +
                        "/" +
                        this.props.t("Withdrawal")}
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav tabs className="nav-tabs-custom" role="tablist">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.toggleTab("1");
                            }}
                          >
                            {this.props.t("Deposit")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                              this.toggleTab("2");
                            }}
                          >
                            {this.props.t("Withdrawal")}
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="buy">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              amount: (this.state && this.state.amount) || "",
                              payerPhone:
                                (this.state && this.state.payerPhone) || "",
                              otp: (this.state && this.state.otp) || "",
                              providerCode:
                                (this.state && this.state.providerCode) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              amount: Yup.number()
                                .min(500)
                                .required("Please Enter Your amount"),
                              payerPhone: Yup.number().required(
                                "Please Enter Valid phone"
                              ),
                              otp: Yup.number()
                                .max(999999)
                                .required("Please Enter Valid OTP"),
                              providerCode: Yup.number().required(
                                "Please select payment method"
                              ),
                            })}
                            onSubmit={values => {
                              this.props.onMakeDeposit(
                                values,
                                this.props.history
                              );
                            }}
                            validateOnChange={true}
                          >
                            {({
                              errors,
                              status,
                              touched,
                              values,
                              setValues,
                            }) => (
                              <Form>
                                {this.props.depositError &&
                                this.props.depositError ? (
                                  <Alert color="danger">
                                    {this.props.depositError}
                                  </Alert>
                                ) : null}

                                <div className="mb-2">
                                  <Label>Payment method :</Label>

                                  <Row>
                                    {this.props.providers.map((item, idx) => {
                                      if (item.label.includes("loan2cash")) {
                                        return "";
                                      }
                                      return (
                                        <Col xl="2" sm="4" key={idx}>
                                          <Label className="card-radio-label mb-3">
                                            <Input
                                              type="radio"
                                              name="provider"
                                              value={item.code}
                                              onChange={event =>
                                                setValues({
                                                  ...values,
                                                  providerCode:
                                                    event.target.value,
                                                })
                                              }
                                              className={
                                                "card-radio-input" +
                                                (errors.providerCode &&
                                                touched.providerCode
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name="providerCode"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                            <div className="card-radio">
                                              <img
                                                className="text-primary align-middle me-2"
                                                src={item.logo}
                                                height={24}
                                                alt={item.label}
                                              />{" "}
                                              <span>{item.label}</span>
                                            </div>
                                          </Label>
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                </div>

                                <div className="mb-3">
                                  <Label>{this.props.t("Amount")} :</Label>

                                  <Field
                                    name="amount"
                                    placeholder="EX : 500"
                                    type="number"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.amount && touched.amount
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="amount"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label>{this.props.t("Payer Phone")} :</Label>

                                  <Field
                                    name="payerPhone"
                                    placeholder="EX : 695499969"
                                    type="number"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.payerPhone && touched.payerPhone
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="payerPhone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label>{this.props.t("OTP")} :</Label>

                                  <Field
                                    name="otp"
                                    placeholder={this.props.t("Otp")}
                                    type="number"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.otp && touched.otp
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="otp"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="text-center mt-4">
                                  <Button type="submit" color="success">
                                    <ButtonLoader
                                      loading={this.props.loadingDeposit}
                                    />
                                    {this.props.t("Make deposit")}
                                  </Button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </TabPane>

                        <TabPane tabId="2">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              amount: (this.state && this.state.amount) || "",
                              receiverPhone:
                                (this.state && this.state.receiverPhone) ||
                                getAccount().phone,
                            }}
                            validationSchema={Yup.object().shape({
                              amount: Yup.number().required(
                                "Please Enter Your amount"
                              ),
                              receiverPhone: Yup.number().required(
                                "Please Enter Valid Phone"
                              ),
                            })}
                            onSubmit={values => {
                              this.props.onMakeWithdrawal(
                                values,
                                this.props.history
                              );
                            }}
                            validateOnChange={true}
                          >
                            {({
                              errors,
                              status,
                              touched,
                              values,
                              setValues,
                            }) => (
                              <Form>
                                {this.props.withdrawalError &&
                                this.props.withdrawalError ? (
                                  <Alert color="danger">
                                    {this.props.withdrawalError}
                                  </Alert>
                                ) : null}

                                <div className="mb-3">
                                  <Label>{this.props.t("Amount")} :</Label>

                                  <Field
                                    name="amount"
                                    placeholder="EX : 500"
                                    type="number"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.amount && touched.amount
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="amount"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-3">
                                  <Label>
                                    {this.props.t("Receiver Phone")} :
                                  </Label>

                                  <Field
                                    name="receiverPhone"
                                    placeholder="EX : 695499969"
                                    type="number"
                                    readOnly
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.receiverPhone &&
                                      touched.receiverPhone
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="receiverPhone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="text-center mt-4">
                                  <Button type="submit" color="danger">
                                    <ButtonLoader
                                      loading={this.props.loadingWithdrawal}
                                    />
                                    {this.props.t("Make withdrawal request")}
                                  </Button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

CashInOut.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  depositError: PropTypes.any,
  withdrawalError: PropTypes.any,
  loadingDeposit: PropTypes.bool,
  loadingWithdrawal: PropTypes.bool,
  balance: PropTypes.any,
  type: PropTypes.string,
  providers: PropTypes.any,
  onGetProviders: PropTypes.func,
  onMakeDeposit: PropTypes.func,
  onMakeWithdrawal: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  balance: state.Wallet.balance,
  loadingDeposit: state.Wallet.loadingDeposit,
  loadingWithdrawal: state.Wallet.loadingWithdrawal,
  depositError: state.Wallet.depositError,
  withdrawalError: state.Wallet.withdrawalError,
  providers: state.Wallet.providers,
});
const mapDispatchToProps = dispatch => ({
  onGetProviders: () => dispatch(getProvider()),
  onMakeDeposit: (data, history) => dispatch(makeDeposit(data, history)),
  onMakeWithdrawal: (data, history) => dispatch(makeWithdrawal(data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CashInOut))
);
