import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Components
import FooterLink from "./footer-link";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerLinks: [
        {
          title: "Société",
          links: [
            { title: "A Propos", link: "#" },
            { title: "Service", link: "#" },
            { title: "Equipe", link: "#" },
            { title: "Nouvelles", link: "#" },
            { title: "FAQs", link: "#" },
          ],
        },
        {
          title: "Resources",
          links: [
            { title: "Conditions d’utilisation : Politique d’utilisation", link: "#" },
          ],
        },
        {
          title: "Liens",
          links: [
            { title: "Devenir Partenaire Microfinance", link: "#" },
            { title: "Devenir Partenaire Commerces", link: "#" },
          ],
        },
        {
          title: "Adresse",
          links: [
            { title: "Douala : BP 4395 Bassa", link: "#" },
            { title: "Tel : 656117999", link: "#" },
            { title: "Rennes : 06 Avenue André", link: "#" },
            { title: "Malraux 35000", link: "#" },
            { title: "Tel : 0783000334", link: "tel:0783000334" },
            { title: "WhatsApp : 0783000334", link: "https://wa.me/0783000334" },
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <footer className="landing-footer">
          <Container>
            <Row>
              {this.state.footerLinks.map((footerLink, key) => (
                <Col lg="3" sm="6" key={key}>
                  <div className="mb-4 mb-lg-0">
                    <h5 className="mb-3 footer-list-title">
                      {footerLink.title}
                    </h5>
                    <ul className="list-unstyled footer-list-menu">
                      {footerLink.links.map((Flink, key) => (
                        <li key={key}>
                          <Link to={Flink.link}>{Flink.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}

              <Col lg="3" sm="6" hidden={true}>
                <div className="mb-4 mb-lg-0">
                  <h5 className="mb-3 footer-list-title">Latest News</h5>
                  <div className="blog-post">
                    <Link to="#" className="post">
                      <div className="badge badge-soft-success font-size-11 mb-3">
                        Adresse
                      </div>
                      <h5 className="post-title">
                        Donec pede justo aliquet nec
                      </h5>
                      <p className="mb-0">
                        <i className="bx bx-calendar me-1" /> 04 Mar, 2020
                      </p>
                    </Link>
                    <Link to="#" className="post">
                      <div className="badge badge-soft-success font-size-11 mb-3">
                        Cryptocurrency
                      </div>
                      <h5 className="post-title">In turpis, Pellentesque</h5>
                      <p className="mb-0">
                        <i className="bx bx-calendar me-1" /> 12 Mar, 2020
                      </p>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <hr className="footer-border my-5" />

            <FooterLink />
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default Features;
