import React, { Component } from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

import ApexRadial from "./ApexRadial";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class MonthlyEarning extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        {" "}
        <Card>
          <CardBody>
            <CardTitle className="mb-4 h4">Monthly Earning</CardTitle>
            <Row>
              <Col sm="6">
                <p className="text-muted">This month</p>
                <h3>
                  {this.props.chartsData.balanceSinceLastMonthDifference} XAF
                </h3>
                <p className="text-muted">
                  <span className="text-success me-2">
                    {" "}
                    {
                      this.props.chartsData
                        .balanceSinceLastMonthDifferencePercent
                    }
                    % <i className="mdi mdi-arrow-up"></i>{" "}
                  </span>{" "}
                  From previous period
                </p>
                <div className="mt-4"></div>
              </Col>
              <Col sm="6">
                <div className="mt-4 mt-sm-0">
                  <ApexRadial chartsData={this.props.chartsData} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

MonthlyEarning.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
};

export default withRouter(withTranslation()(MonthlyEarning));
