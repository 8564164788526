import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import WelcomeComp from "./Microfinance/WelcomeComp";
import MonthlyEarning from "./Microfinance/MonthlyEarning";
import classNames from "classnames";
import ReactApexChart from "react-apexcharts";
import SocialSource from "./Microfinance/SocialSource";
import ActivityComp from "./Microfinance/ActivityComp";
import TopCities from "./Microfinance/TopCities";

//import action
import { getMicrofinanceDashboard } from "../../store/actions";
import WithdrawalRequestsTable from "../../components/Microfinance/WithdrawalRequestsTable";

class Microfinance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      reports: [
        {
          title: this.props.t("Customers"),
          iconClass: "bx-copy-alt",
          description: "Loading",
        },
        {
          title: this.props.t("Collaborators"),
          iconClass: "bx-archive-in",
          description: "Loading",
        },
        {
          title: this.props.t("XPayLater"),
          iconClass: "bx-purchase-tag-alt",
          description: "Loading",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      subscribemodal: false,
      chartSeries: [],
      periodType: "yearly",
    };

    this.togglemodal.bind(this);
  }

  componentDidMount() {
    const { onGetChartsData } = this.props;
    onGetChartsData("yearly");
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, chartSeries: this.props.chartsData });
    }
  }

  render() {
    if (!this.props.visible) return null;
    else
      return (
        <React.Fragment>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Dashboards"
            breadcrumbItem={this.props.t("Microfinance")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning chartsData={this.props.chartsData} />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {this.props.t("Customers")}
                          </p>
                          <h4 className="mb-0">
                            {" "}
                            {this.props.chartsData.customers}
                          </h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className={"bx bx-user font-size-24"} />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {this.props.t("Collaborators")}
                          </p>
                          <h4 className="mb-0">
                            {" "}
                            {this.props.chartsData.collectors}
                          </h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="bx bxs-user-check font-size-24" />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {this.props.t("XPayLater")}
                          </p>
                          <h4 className="mb-0">
                            {" "}
                            {this.props.chartsData.xpayLater} XAF
                          </h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i className="bx bxs-wallet font-size-24" />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title mb-4 h4">Stats</CardTitle>
                    <div className="ms-auto" hidden="hidden">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: this.state.periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                periodType: "weekly",
                              });
                              this.props.onGetChartsData("weekly");
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: this.state.periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                periodType: "monthly",
                              });
                              this.props.onGetChartsData("monthly");
                            }}
                            id="one_month"
                          >
                            Month
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: this.state.periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                periodType: "yearly",
                              });
                              this.props.onGetChartsData("yearly");
                            }}
                            id="one_month"
                          >
                            Year
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <ReactApexChart
                    series={this.props.chartsData.chartInfo.series}
                    options={{
                      chart: { toolbar: { show: true } },
                      dataLabels: { enabled: false },
                      stroke: {
                        curve: "smooth",
                        width: 2,
                        dashArray: [0, 0, 3],
                      },
                      fill: { type: "solid", opacity: [0.15, 0.05, 1] },
                      grid: {
                        borderColor: "#f1f1f1",
                      },
                      xaxis: {
                        categories:this.props.chartsData.chartInfo.months,
                      },
                      colors: ["#f1b44c", "#5BC190", "#f46a6a"],
                    }}
                    type="area"
                    height={340}
                    className="apex-charts"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row hidden={true}>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>
            <Col xl="4">
              <TopCities />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <WithdrawalRequestsTable />
            </Col>
          </Row>
        </React.Fragment>
      );
  }
}

Microfinance.propTypes = {
  visible: PropTypes.bool,
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Dashboard }) => ({
  chartsData: Dashboard.chartsData,
});

const mapDispatchToProps = dispatch => ({
  onGetChartsData: periodType => dispatch(getMicrofinanceDashboard()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Microfinance))
);
