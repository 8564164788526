export const key = "account";
export const affiliate = "affiliate";

export function setAuthenticatedUser(account) {
  console.log(account);
  localStorage.setItem(key, JSON.stringify(account));
}

export function setAffiliate() {
  localStorage.setItem(affiliate, "true");
}

export function isAffiliate() {
  let state = localStorage.getItem(affiliate);
  return state !== null && state === true;
}

export function getAccount() {
  const user = localStorage.getItem(key);
  if (user) return JSON.parse(user);
  return null;
}

export function getAccountName() {
  const user = localStorage.getItem(key);
  if (user) {
    if (isMicrofinance() ) {
      return JSON.parse(user).microfinance.socialReason;
    } else if(isVendor()){}else{
      return JSON.parse(user).lastName + " " + JSON.parse(user).firstName;
    }
  }
  return null;
}

export function getAccountAvatar() {
  const user = localStorage.getItem(key);
  if (user)
    return (
      "https://ui-avatars.com/api/?name=" +
      getAccountName() +
      "&background=1BB8BB&color=fff"
    );
  return null;
}

//Is user  logged in
export const isUserAuthenticated = () => {
  return getAccount() !== null;
};

export const logoutAccount = () => {
  localStorage.removeItem(key);
  localStorage.removeItem("token");
};

export const isSaverOnly = () => {
  if (isUserAuthenticated()) {
    return !isMicrofinance() && !isVendor();
  }
  return false;
};

export const isMicrofinance = () => {
  if (isUserAuthenticated()) {
    const user = getAccount();
    return user.microfinanceId !== null;
  }
  return false;
};

export const isVendor = () => {
  if (isUserAuthenticated()) {
    const user = getAccount();
    return user.partnerId !== null;
  }
  return false;
};
