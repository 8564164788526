import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { getBalance } from "../../store/wallet/actions";
import { connect } from "react-redux";

class Balance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenu: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    const { onGetBalance } = this.props;
    onGetBalance();
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown isOpen={this.state.isMenu} toggle={this.toggleMenu}>
          <DropdownToggle type="button" tag="button" className="btn btn-light">
            <i className="mdi mdi-wallet me-1" />
            <span className="d-none d-sm-inline-block ms-1">
              {this.props.t("Wallet Balance") + " "}
              <i className="mdi mdi-chevron-down" />
            </span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
            <div className="dropdown-item-text">
              <div>
                <p className="text-muted mb-2">
                  {this.props.t("Balance available")}
                </p>
                <h5 className="mb-0">XAF {this.props.balance}</h5>
              </div>
            </div>

            <DropdownItem divider />

            <DropdownItem href="#">
              {this.props.t("Balance loan")} :{" "}
              <span className="float-end">0</span>
            </DropdownItem>
            {/*<DropdownItem href="#">*/}
            {/*  ETH : <span className="float-end">0.04121</span>*/}
            {/*</DropdownItem>*/}
            {/*<DropdownItem href="#">*/}
            {/*  LTC : <span className="float-end">0.00356</span>*/}
            {/*</DropdownItem>*/}

            <DropdownItem divider />

            <DropdownItem className="text-primary text-center" href="#">
              Learn more
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

Balance.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  balance: PropTypes.any,
  type: PropTypes.string,
  onGetBalance: PropTypes.func,
};

const mapStateToProps = state => ({
  balance: state.Wallet.balance,
  loading: state.Wallet.loading,
  error: state.Wallet.error,
});
const mapDispatchToProps = dispatch => ({
  onGetBalance: () => dispatch(getBalance()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Balance))
);
