import * as url from "./backendUrl";
import { handleToken, post, postForm, put } from "../api_helper";
import { setAuthenticatedUser } from "../session_helper";
import { PUT_PASSWORD_RESET } from "./backendUrl";

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);

// Register new saver
const postRegisterSaver = data => post(url.POST_REGISTER_SAVER, data);

// Register new saver
const postRegisterMicrofinance = data => {
  return postForm(url.POST_REGISTER_MICROFINANCE, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// Register new saver
const postRegisterVendor = data => {
  return postForm(url.POST_REGISTER_VENDOR, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// Account verification
const putAccountVerification = data => put(url.PUT_VERIFY_ACCOUNT, data);

// Account verification
const postAccountVerificationClaim = data =>
  post(url.POST_VERIFY_ACCOUNT_CLAIM, null, { params: data });

// Account verification
const postPasswordForgot = data =>
  post(url.POST_PASSWORD_FORGOT, null, { params: data });

// Account verification
const putResetForgot = data =>
  put(url.PUT_PASSWORD_RESET, data, { params: data });

export {
  postLogin,
  postRegisterSaver,
  putAccountVerification,
  postAccountVerificationClaim,
  handleToken,
  setAuthenticatedUser,
  postPasswordForgot,
  postRegisterMicrofinance,
  postRegisterVendor,
  putResetForgot,
};
