import * as url from "./backendUrl";
import { get, patch, post } from "../api_helper";
import { isMicrofinance } from "../session_helper";

const getTransactions = data => get(isMicrofinance()?url.GET_CLIENT_TRANSACTIONS: url.GET_TRANSACTIONS, data);

const getClientTransactions = data => get(url.GET_TRANSACTIONS+"/"+data, data);

const getBalance = data => get(url.GET_BALANCE, data);

const postSelfDeposit = data => post(url.POST_SELF_DEPOSIT, data);

const postManagerDeposit = data => post(url.POST_MANAGER_DEPOSIT, data);

const postWithdrawalRequest = data => post(url.POST_WITHDRAWAL_REQUEST, data);

const getPaymentProviders = data => get(url.GET_PAYMENT_PROVIDERS, data);

const getMyWithdrawalRequests = data => get(url.GET_WITHDRAWAL_REQUESTS, data);

const getReceivesWithdrawalRequests = data =>
  get(url.GET_MICROFINANCE_WITHDRAWAL_REQUESTS, { params: data });

const treatReceiveWithdrawalRequest = data =>
  patch(url.PATCH_MICROFINANCE_WITHDRAWAL_REQUEST_TREAT, data);

export {
  getTransactions,
  getBalance,
  postManagerDeposit,
  postSelfDeposit,
  postWithdrawalRequest,
  getMyWithdrawalRequests,
  getPaymentProviders,
  getReceivesWithdrawalRequests,
  treatReceiveWithdrawalRequest,
  getClientTransactions
};
