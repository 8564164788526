import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getTransactions } from "../../store/wallet/actions";
// datatable related plugins
import "./datatables.scss";
import React, { Component } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import WithdrawalRequestsTable from "../../components/Saver/WithdrawalRequestsTable";
import MicrofinanceAffiliation from "../../components/Saver/MicrofinanceAffiliation";

class WithdrawalRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      formModal: false,
      formModalEdit: false,
      showInfo: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <MicrofinanceAffiliation />
            <Breadcrumbs
              title={this.props.t("Wallet")}
              breadcrumbItem={this.props.t("My withdrawal request")}
            />
            <WithdrawalRequestsTable />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

WithdrawalRequests.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  t: PropTypes.any,
  transactions: PropTypes.any,
  onGetTransactions: PropTypes.func,
};

const mapStateToProps = state => ({
  transactions: state.Wallet.transactions,
  loading: state.Wallet.loading,
  error: state.Wallet.error,
});
const mapDispatchToProps = dispatch => ({
  onGetTransactions: () => dispatch(getTransactions()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(WithdrawalRequests))
);
