import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import FeatureBox from "./feature-box";

//Import images
import feature1 from "../../../assets/images/crypto/features-img/img-1-ed.png";
import feature2 from "../../../assets/images/crypto/features-img/img-2-ed.png";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features1: [
        { id: 1, desc: "Accédez à la boutique en ligne" },
        { id: 2, desc: "Choisissez votre article " },
        { id: 3, desc: "Étalez votre paiement en 3X" },
      ],
      features2: [
        { id: 1, desc: "Ouvrez l’application" },
        { id: 2, desc: "Choisissez votre EMF" },
        { id: 3, desc: "Transférer votre épargne dans votre compte" },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <div className="small-title">Ce que nous offrons</div>
                  <h4>Key features of the product</h4>
                </div>
              </Col>
            </Row>

            <Row className="align-items-center pt-4">
              <Col md="6" sm="8">
                <div>
                  <img
                    src={feature1}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col md="5" className="ms-auto">
                {/* featurebox */}
                <FeatureBox
                  num="01"
                  title="Payez en 3X"
                  features={this.state.features1}
                  desc="Achetez et payez autrement. Avec loan2cash vous pouvez vous faire plaisir à tout moment en réglant en 3X vos achats d’urgence ou de loisir à 0 frais. "
                />
              </Col>
            </Row>

            <Row className="align-items-center mt-5 pt-md-5">
              <Col md="5">
                {/* featurebox */}
                <FeatureBox
                  num="02"
                  title="Sécurisez votre épargne"
                  features={this.state.features2}
                  desc="Ne vous sentez plus des limites, avec loan2cash vous pouvez désormais transférer votre épargne dans votre compte auprès d’une EMF partenaire sans vous déplacer et en toute sécurité."
                />
              </Col>
              <Col md="6" sm="8" className="ms-md-auto">
                <div className="mt-4 me-md-0">
                  <img
                    src={feature2}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
