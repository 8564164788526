import {
  ACCOUNT_VERIFICATION,
  ACCOUNT_VERIFICATION_FAILED,
  ACCOUNT_VERIFICATION_SUCCESSFUL,
  REGISTER_MICROFINANCE,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_VENDOR,
} from "./actionTypes";

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  };
};

export const registerMicrofinance = (user, history) => {
  return {
    type: REGISTER_MICROFINANCE,
    payload: { user, history },
  };
};

export const registerVendor = (user, history) => {
  return {
    type: REGISTER_VENDOR,
    payload: { user, history },
  };
};

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  };
};

export const accountVerification = (user, history) => {
  return {
    type: ACCOUNT_VERIFICATION,
    payload: { user, history },
  };
};

export const accountVerificationSuccessful = user => {
  return {
    type: ACCOUNT_VERIFICATION_SUCCESSFUL,
    payload: user,
  };
};

export const accountVerificationFailed = user => {
  return {
    type: ACCOUNT_VERIFICATION_FAILED,
    payload: user,
  };
};
