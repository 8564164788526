import {
  COMMITTEE,
  COMMITTEE_CREATE,
  COMMITTEE_CREATE_FAILURE,
  COMMITTEE_CREATE_SUCCESS,
  COMMITTEE_DELETE,
  COMMITTEE_DELETE_FAILURE,
  COMMITTEE_DELETE_SUCCESS,
  COMMITTEE_FAILURE,
  COMMITTEE_SUCCESS,
  COMMITTEE_UPDATE, COMMITTEE_UPDATE_FAILURE, COMMITTEE_UPDATE_SUCCESS
} from "./actionTypes";

export const committeeDelete = microfinanceId => ({
  type: COMMITTEE_DELETE,
  payload: { microfinanceId },
});

export const committeeDeleteSuccess = body => ({
  type: COMMITTEE_DELETE_SUCCESS,
  payload: body,
});

export const committeeDeleteFailure = error => ({
  type: COMMITTEE_DELETE_FAILURE,
  payload: error,
});

export const committeeCreate = (committee) => ({
  type: COMMITTEE_CREATE,
  payload: { committee },
});

export const committeeCreateSuccess = body => ({
  type: COMMITTEE_CREATE_SUCCESS,
  payload: body,
});

export const committeeCreateFailure = error => ({
  type: COMMITTEE_CREATE_FAILURE,
  payload: error,
});

export const committeeUpdate = (committee) => ({
  type: COMMITTEE_UPDATE,
  payload: { committee },
});

export const committeeUpdateSuccess = body => ({
  type: COMMITTEE_UPDATE_SUCCESS,
  payload: body,
});

export const committeeUpdateFailure = error => ({
  type: COMMITTEE_UPDATE_FAILURE,
  payload: error,
});

export const committees = () => ({
  type: COMMITTEE,
});

export const committeesSuccess = body => ({
  type: COMMITTEE_SUCCESS,
  payload:  body ,
});

export const committeesFailure = error => ({
  type: COMMITTEE_FAILURE,
  payload: error,
});

