import React, { Component } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import "./datatables.scss";

import { getMicrofinanceList } from "../../store/saver/actions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Select from "react-select";
import { isMicrofinance } from "../../helpers/session_helper";
import {
  collaboration,
  microfinanceTreatRequest,
  myClients,
  receiveCollaborationRequests,
  sentCollaborationRequests,
} from "../../store/collector/actions";
import { normalizeDate } from "../../helpers/core_helper";

class CollectorClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      formModal: false,
      formModalEdit: false,
      showInfo: false,
    };

    this.toggle = this.toggle.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.toggleRequest = this.toggleRequest.bind(this);
  }

  getStateOptions() {
    return [
      { label: this.props.t("ACCEPTED"), value: "ACCEPTED" },
      { label: this.props.t("DENIED"), value: "DENIED" },
      { label: this.props.t("PENDING"), value: "PENDING" },
    ];
  }

  getSelectedState(value) {
    return this.getStateOptions().find(o => o.value === value);
  }

  componentDidMount() {
    const { onGetClients } = this.props;
    onGetClients();
  }

  toggleInfo() {
    this.setState({
      showInfo: !this.state.showInfo,
    });
  }

  toggle() {
    this.setState({
      formModal: !this.state.formModal,
    });
  }

  handleOrderClicks = () => {
    //this.setState({ order: "", isEdit: false });
    this.toggle();
  };

  getSubscribeAction() {
    if (!isMicrofinance()) {
      return (
        <Col sm="8" className="float-end">
          <div className="text-sm-end">
            <Button
              type="button"
              color="success"
              className="btn-rounded mb-2 me-2"
              onClick={this.handleOrderClicks}
            >
              <i className="mdi mdi-plus me-1" />{" "}
              {this.props.t("Subscribe saver")}
            </Button>
          </div>
        </Col>
      );
    } else {
      return (
        <Col md="4" className="float-end">
          <div>
            <Select
              onChange={select => {
                this.props.onGetRequests(select.value);
              }}
              options={this.getStateOptions()}
              classNamePrefix="select2-selection"
            />
          </div>
        </Col>
      );
    }
  }

  toggleRequest() {
    this.setState({
      formModalEdit: !this.state.formModalEdit,
    });
  }

  handleRequestClick = request => {
    this.setState({
      request: request,
    });
    this.props.history.push({
      pathname: "/collaborators/clients/collect",
      search: "?phone=" + request.user.phone,
      state: { manager: request },
    });
  };

  render() {
    const { SearchBar } = Search;

    let columns = [
      {
        dataField: "id",
        text: "#",
        sort: true,
        formatter: (cellContent, row, idx) => (
          <>
            <span className="text-body fw-bold">#CL{idx + 1}</span>
          </>
        ),
      },
      {
        dataField: "Name",
        text: this.props.t("Lastname"),
        sort: true,
        formatter: (cellContent, row, idx) =>
          row.user.lastName + " " + row.user.firstName,
      },
      {
        dataField: "user.phone",
        text: this.props.t("Phone"),
        sort: true,
      },
      {
        dataField: "user.city",
        text: this.props.t("City"),
        sort: true,
      },
      {
        dataField: "createdAt",
        text: this.props.t("Created at"),
        sort: true,
        formatter: (cellContent, row) => normalizeDate(row.createdAt),
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        formatter: (cellContent, request) => (
          <>
            <div className="d-flex gap-3">
              <Button className="btn-success">
                <i
                  className="mdi mdi-wallet-plus font-size-12"
                  id="update"
                  onClick={() => this.handleRequestClick(request)}
                />
              </Button>
            </div>
          </>
        ),
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.clients.length, // replace later with size(customers),
      custom: true,
    };

    // Select All ButtonLoader operation
    const selectRow = {
      mode: "checkbox",
    };

    if (this.props.closeModal) {
      //this.toggleRequest();
      //this.toggle();
      //this.setState({"closeModal":false})
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Subscribe saver request list")}
              breadcrumbItem="List"
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">
                      {this.props.t("Subscribe saver request list")}
                    </CardTitle>
                    <p className="card-title-desc"> .</p>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.clients}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.props.clients}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                    <Modal
                                      isOpen={this.state.formModal}
                                      className={this.props.className}
                                    >
                                      <ModalHeader
                                        toggle={this.toggle}
                                        tag="h4"
                                      >
                                        {this.props.t("Subscribe saver")}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Formik
                                          enableReinitialize={true}
                                          initialValues={{
                                            microfinanceId:
                                              (this.state &&
                                                this.state.microfinanceId) ||
                                              "",
                                          }}
                                          validationSchema={Yup.object().shape({
                                            microfinanceId:
                                              Yup.string().required(
                                                "Please Select a microfinance"
                                              ),
                                          })}
                                          validateOnChange={true}
                                          onSubmit={values => {
                                            this.props.onSubscribe(
                                              values.microfinanceId
                                            );
                                          }}
                                        >
                                          {({
                                            errors,
                                            status,
                                            touched,
                                            setValues,
                                            values,
                                          }) => (
                                            <Form>
                                              {this.props.error &&
                                              this.props.error ? (
                                                <Alert color="danger">
                                                  {this.props.error}
                                                </Alert>
                                              ) : null}
                                              <Row>
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <Label>Microfinance</Label>
                                                    <Select
                                                      onChange={microfinance =>
                                                        setValues({
                                                          ...values,
                                                          microfinanceId:
                                                            microfinance.value,
                                                        })
                                                      }
                                                      options={
                                                        this.props
                                                          .microfinanceList
                                                      }
                                                      classNamePrefix="select2-selection"
                                                      isLoading={
                                                        this.props
                                                          .loadingMicrofinance
                                                      }
                                                      className={
                                                        errors.microfinanceId &&
                                                        touched.microfinanceId
                                                          ? " is-invalid"
                                                          : ""
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="microfinanceId"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-user"
                                                    >
                                                      {this.props
                                                        .loadingSubscription ? (
                                                        <i
                                                          className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"
                                                          if
                                                        ></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {this.props.t(
                                                        "Subscribe saver"
                                                      )}
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Form>
                                          )}
                                        </Formik>
                                      </ModalBody>
                                    </Modal>
                                    <Modal
                                      isOpen={this.state.formModalEdit}
                                      className={this.props.className}
                                    >
                                      <ModalHeader
                                        toggle={this.toggleRequest}
                                        tag="h4"
                                      >
                                        {this.props.t("Treat request")}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Formik
                                          enableReinitialize={true}
                                          initialValues={{
                                            state:
                                              (this.state &&
                                                this.state.request &&
                                                this.getSelectedState(
                                                  this.state.request.state || ""
                                                )) ||
                                              "",
                                            reason:
                                              (this.state &&
                                                this.state.reason) ||
                                              "",
                                          }}
                                          validationSchema={Yup.object().shape({
                                            state: Yup.string().required(
                                              "Please Select a status"
                                            ),
                                            reason: Yup.string().when(
                                              ["state"],
                                              {
                                                is: state =>
                                                  ["DENIED"].includes(state),
                                                then: Yup.string().required(
                                                  this.props.t(
                                                    "Comment required"
                                                  )
                                                ),
                                              }
                                            ),
                                          })}
                                          validateOnChange={true}
                                          onSubmit={values => {
                                            this.props.onTraite(
                                              this.state.request.id,
                                              values.state,
                                              values.reason
                                            );
                                          }}
                                        >
                                          {({
                                            errors,
                                            status,
                                            touched,
                                            setValues,
                                            values,
                                          }) => (
                                            <Form>
                                              {this.props.updateError &&
                                              this.props.updateError ? (
                                                <Alert color="danger">
                                                  {this.props.updateError}
                                                </Alert>
                                              ) : null}
                                              <Row>
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <Label>
                                                      {this.props.t("State")}
                                                    </Label>
                                                    <Select
                                                      onChange={state =>
                                                        setValues({
                                                          ...values,
                                                          state: state.value,
                                                        })
                                                      }
                                                      options={this.getStateOptions()}
                                                      value={this.state.state}
                                                      classNamePrefix="select2-selection"
                                                      className={
                                                        errors.state &&
                                                        touched.state
                                                          ? " is-invalid"
                                                          : ""
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="state"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <Label>
                                                      {this.props.t("Comment")}
                                                    </Label>
                                                    <Field
                                                      name="reason"
                                                      as="textarea"
                                                      maxLength="225"
                                                      rows="3"
                                                      className={
                                                        "form-control" +
                                                        (errors.reason &&
                                                        touched.reason
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="reason"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-user"
                                                    >
                                                      {this.props
                                                        .loadingTraite ? (
                                                        <i
                                                          className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"
                                                          if
                                                        ></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {this.props.t("Save")}
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Form>
                                          )}
                                        </Formik>
                                      </ModalBody>
                                    </Modal>
                                    {/*<SaverInfoModal visible={this.state.showInfo}/>*/}
                                    {/*<AffiliateRequestForm visible={this.state.formModalEdit}*/}
                                    {/*                      request={this.props.selectedRequest} />*/}
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

CollectorClients.propTypes = {
  error: PropTypes.any,
  updateError: PropTypes.any,
  t: PropTypes.any,
  clients: PropTypes.any,
  microfinanceList: PropTypes.any,
  onGetClients: PropTypes.func,
  onSubscribe: PropTypes.func,
  loadingMicrofinance: PropTypes.bool,
  loadingTraite: PropTypes.bool,
  loadingSubscription: PropTypes.bool,
  className: PropTypes.any,
  closeModal: PropTypes.bool,
};

const mapStateToProps = state => ({
  clients: state.Collector.myClients,
  loadingMicrofinance: state.Saver.loadingMicrofinance,
  loadingSubscription: state.Collector.loadingSubscribe,
  loadingTraite: state.Collector.loadingUpdate,
  error: state.Collector.error,
  updateError: state.Collector.updateError,
  closeModal: state.Collector.close,
});

const mapDispatchToProps = dispatch => ({
  onGetClients: () =>
    dispatch(
      isMicrofinance() ? receiveCollaborationRequests(state) : myClients()
    ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(CollectorClients))
);
