import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getTransactions } from "../../store/wallet/actions";
// datatable related plugins
import "./datatables.scss";
import React, { Component } from "react";
import { Container } from "reactstrap";

// datatable related plugins
import Breadcrumbs from "../../components/Common/Breadcrumb";
import WithdrawalRequestsTable from "../../components/Microfinance/WithdrawalRequestsTable";

class ReceiveWithdrawalRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      formModal: false,
      formModalEdit: false,
      showInfo: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Wallet")}
              breadcrumbItem={this.props.t("Withdrawal request")}
            />
            <WithdrawalRequestsTable />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ReceiveWithdrawalRequests.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  t: PropTypes.any,
  transactions: PropTypes.any,
  onGetTransactions: PropTypes.func,
};

const mapStateToProps = state => ({
  transactions: state.Wallet.transactions,
  loading: state.Wallet.loading,
  error: state.Wallet.error,
});
const mapDispatchToProps = dispatch => ({
  onGetTransactions: () => dispatch(getTransactions()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ReceiveWithdrawalRequests))
);
