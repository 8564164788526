import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Alert, Col, Container, InputGroup, Label, Row } from "reactstrap";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

// import images
// import images
import { connect } from "react-redux";
// action
import {
  getCities,
  getCountries,
  getDistricts,
  registerUser,
} from "../../store/actions";
import CarouselPage from "./CarouselPage";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { XSelect } from "../Form/XSelect";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      countries,
      onGetCountries,
      loadingCountries,
      onGetCities,
      cities,
      onGetAddresses,
      addresses,
      loading,
    } = this.props;
    this.setState({ countries, loadingCountries, cities, addresses, loading });
    onGetCountries();
    onGetCities();
    onGetAddresses();
  }

  render() {
    //meta title
    document.title = "Register  | loan2cash";
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="/" className="d-block auth-logo">
                          <img
                            src={logodark}
                            alt=""
                            height="18"
                            className="auth-logo-dark"
                          />
                          <img
                            src={logolight}
                            alt=""
                            height="18"
                            className="auth-logo-light"
                          />
                        </Link>
                      </div>
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Register account</h5>
                          <p className="text-muted">
                            Get your free loan2cash account now.
                          </p>
                        </div>
                        <div className="mt-4">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              phone: (this.state && this.state.phone) || "",
                              password:
                                (this.state && this.state.password) || "",
                              lastName:
                                (this.state && this.state.lastName) || "",
                              firstName:
                                (this.state && this.state.firstName) || "",
                              gender:
                                (this.state && this.state.gender) || "NONE",
                              countryId:
                                (this.state && this.state.countryId) || "",
                              city: (this.state && this.state.city) || "",
                              address: (this.state && this.state.address) || "",
                              phoneCode:
                                (this.state && this.state.phoneCode) || "+00",
                              privacy:
                                (this.state && this.state.privacy) || false,
                            }}
                            validationSchema={Yup.object().shape({
                              phone: Yup.number().required(
                                "Please Enter Your Phone"
                              ),
                              password: Yup.string().required(
                                "Please Enter Valid Password"
                              ),
                              firstName: Yup.string()
                                .min(3)
                                .max(100)
                                .required("Please Enter Valid firstname"),
                              lastName: Yup.string()
                                .min(3)
                                .max(100)
                                .required("Please Enter Valid Lastname"),
                              countryId: Yup.number().required(
                                "Please Select a Country"
                              ),
                              privacy: Yup.boolean().oneOf(
                                [true],
                                "Please agree to terms"
                              ),
                            })}
                            onSubmit={values => {
                              this.props.onRegistration(
                                values,
                                this.props.history
                              );
                            }}
                            validateOnChange={true}
                          >
                            {({
                              errors,
                              status,
                              touched,
                              values,
                              setValues,
                            }) => (
                              <Form className="form-horizontal">
                                {this.props.error && this.props.error ? (
                                  <Alert color="danger">
                                    {this.props.error}
                                  </Alert>
                                ) : null}

                                <div className="mb-3">
                                  <Label for="firstName" className="form-label">
                                    Firstname
                                  </Label>
                                  <Field
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Enter firstname"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.firstName && touched.firstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label for="lastName" className="form-label">
                                    Lastname
                                  </Label>
                                  <Field
                                    name="lastName"
                                    placeholder="Enter lastname"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.lastName && touched.lastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <Row>
                                  <Col md={5}>
                                    <div className="mb-3">
                                      <Label>Country</Label>
                                      <Field
                                        name="country"
                                        as={XSelect}
                                        onChange={country =>
                                          setValues({
                                            ...values,
                                            countryId: country.value,
                                            phoneCode: country.code,
                                          })
                                        }
                                        options={this.props.countries}
                                        classNamePrefix="select2-selection"
                                        isLoading={this.props.loadingCountries}
                                        className={
                                          errors.countryId && touched.countryId
                                            ? " is-invalid"
                                            : ""
                                        }
                                      />
                                      <ErrorMessage
                                        name="countryId"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={7}>
                                    <div className="mb-3">
                                      <Label for="phone" className="form-label">
                                        Phone
                                      </Label>
                                      <InputGroup>
                                        <div className="input-group-text">
                                          {values.phoneCode}
                                        </div>
                                        <Field
                                          name="phone"
                                          placeholder="Enter phone"
                                          type="tel"
                                          className={
                                            "form-control" +
                                            (errors.phone && touched.phone
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="phone"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </InputGroup>
                                    </div>
                                  </Col>
                                </Row>

                                <div className="mb-3">
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <Field
                                    name="password"
                                    placeholder="Enter Password"
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label for="city" className="form-label">
                                    City
                                  </Label>
                                  <Field
                                    name="city"
                                    placeholder="Enter city"
                                    list="cityList"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.city && touched.city
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <datalist id="cityList">
                                    {this.props.cities.map((city, i) => {
                                      return (
                                        <option
                                          key={city.value}
                                          value={city.label}
                                        />
                                      );
                                    })}
                                  </datalist>
                                  <ErrorMessage
                                    name="city"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label for="city" className="form-label">
                                    Address
                                  </Label>
                                  <Field
                                    name="address"
                                    placeholder="Enter address"
                                    type="text"
                                    list="addressList"
                                    className={
                                      "form-control" +
                                      (errors.address && touched.address
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <datalist id="addressList">
                                    {this.props.addresses.map((address, i) => {
                                      return (
                                        <option
                                          key={address.value}
                                          value={address.label}
                                        />
                                      );
                                    })}
                                  </datalist>
                                  <ErrorMessage
                                    name="address"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="form-check form-check-outline form-check-success mb-3">
                                  <Field
                                    type="checkbox"
                                    className={
                                      "form-check-input" +
                                      (errors.privacy && touched.privacy
                                        ? " is-invalid"
                                        : "")
                                    }
                                    name="privacy"
                                  />

                                  <label
                                    className="form-check-label"
                                    htmlFor="privacy"
                                  >
                                    By registering you agree to the loan2cash{" "}
                                    <Link to="#" className="text-primary">
                                      Terms of Use
                                    </Link>
                                  </label>
                                  <ErrorMessage
                                    name="privacy"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-success btn-block"
                                    type="submit"
                                  >
                                    {this.props.loading ? (
                                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                    ) : (
                                      ""
                                    )}{" "}
                                    Register{" "}
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                          <div className="mt-5 text-center">
                            <p>
                              Already have an account ?{" "}
                              <Link
                                to="/login"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Login
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} loan2cash. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger" /> by SAS 2
                          Cash Enterprise
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  error: PropTypes.any,
  countries: PropTypes.any,
  addresses: PropTypes.any,
  cities: PropTypes.any,
  onGetCountries: PropTypes.func,
  onGetCities: PropTypes.func,
  onRegistration: PropTypes.func,
  onGetAddresses: PropTypes.func,
  loadingCountries: PropTypes.bool,
  history: PropTypes.object,
  loading: PropTypes.bool,
  loginUser: PropTypes.func,
};

const mapStateToProps = state => ({
  countries: state.Location.countries,
  cities: state.Location.cities,
  addresses: state.Location.districts,
  loadingCountries: state.Location.loadingCountries,
  loading: state.Account.loading,
  error: state.Account.registrationError,
});

const mapDispatchToProps = dispatch => ({
  onGetCountries: () => dispatch(getCountries()),
  onGetCities: () => dispatch(getCities()),
  onGetAddresses: () => dispatch(getDistricts()),
  onRegistration: (values, history) => dispatch(registerUser(values, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
