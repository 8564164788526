import {
  COMMITTEE,
  COMMITTEE_CREATE, COMMITTEE_CREATE_FAILURE, COMMITTEE_CREATE_SUCCESS,
  COMMITTEE_DELETE,
  COMMITTEE_DELETE_FAILURE,
  COMMITTEE_DELETE_SUCCESS,
  COMMITTEE_FAILURE,
  COMMITTEE_SUCCESS,
  COMMITTEE_UPDATE,
  COMMITTEE_UPDATE_FAILURE,
  COMMITTEE_UPDATE_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  committees: [],
  loadingData: false,
  loadingMicrofinance: false,
  loading: false,
  loadingUpdate: false,
  open: false,
  error: "",
  statusError: "",
  updateError: "",
};

const committees = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMMITTEE:
      return {
        ...state,
        loading: true,
      };
    case COMMITTEE_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        committees:  action.payload,
        loading: false,
      };

    case COMMITTEE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
      
    case COMMITTEE_CREATE:
      return {
        ...state,
        loading: true,
      };
      
    case COMMITTEE_CREATE_SUCCESS:
      return {
        ...state,
        committees: [...state.committees, action.payload],
        loading: false,
        open: false,
      };

    case COMMITTEE_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
      
    case COMMITTEE_UPDATE:
      return {
        ...state,
        loading: true,
      };
      
    case COMMITTEE_UPDATE_SUCCESS:
      return {
        ...state,
        collectorRequests: [state.collectorRequests],
        loading: false,
        open: false,
      };

    case COMMITTEE_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case COMMITTEE_DELETE:
      return {
        ...state,
        loadingData: false,
      };

    case COMMITTEE_DELETE_SUCCESS:
      return {
        ...state,
        loadingData: false,
        collectorRequests: action.payload,
      };

    case COMMITTEE_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingData: false,
      };

    default:
      return state;
  }
};

export default committees;
