import * as action from "./actionTypes";

export const getCountries = () => ({
  type: action.GET_COUNTRY_RESOURCES,
});

export const getCountriesSuccess = countries => ({
  type: action.GET_COUNTRY_RESOURCES_SUCCESS,
  payload: countries,
});

export const getCountriesError = error => ({
  type: action.GET_COUNTRY_RESOURCES_FAILURE,
  payload: error,
});

export const getCities = () => ({
  type: action.GET_CITY_RESOURCES,
});

export const getCitiesSuccess = cities => ({
  type: action.GET_CITY_RESOURCES_SUCCESS,
  payload: cities,
});

export const getCitiesError = error => ({
  type: action.GET_CITY_RESOURCES_FAILURE,
  payload: error,
});

export const getDistricts = () => ({
  type: action.GET_DISTRICT_RESOURCES,
});

export const getDistrictsSuccess = addresses => ({
  type: action.GET_DISTRICT_RESOURCES_SUCCESS,
  payload: addresses,
});

export const getDistrictsError = error => ({
  type: action.GET_DISTRICT_RESOURCES_FAILURE,
  payload: error,
});
