import * as url from "./backendUrl";
import { get } from "../api_helper";

const getAccount = data => get(url.GET_ACCOUNT_ME, data);

const getSaverStats = data => get(url.GET_ACCOUNT_STATS, data);
const getClientStats = data => get(url.GET_ACCOUNT_STATS+"/"+data, data);
const getMicrofinanceStats = data => get(url.GET_MICROFINANCE_STATS, data);
const getPartnerStats = data => get(url.GET_PARTNER_STATS, data);

export { getAccount, getSaverStats, getMicrofinanceStats, getPartnerStats,getClientStats };
