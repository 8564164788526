import * as url from "./backendUrl";
import { get, put } from "../api_helper";

// Saver status Method
const getRequests = data => get(url.GET_XPAYLATER_LOAN_REQUESTS, { params: data });

const traitRequests = data =>
  put(url.PUT_XPAYLATER_LOAN_REQUEST, null, { params: data });


export { getRequests, traitRequests };
