import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_CHARTS_DATA, GET_CLIENT_DASHBOARD,
  GET_MICROFINANCE_DASHBOARD,
  GET_SAVER_DASHBOARD
} from "./actionTypes";
import {
  apiFail,
  apiSuccess, getClientDashboardFailure, getClientDashboardSuccess,
  getMicrofinanceDashboardFailure,
  getMicrofinanceDashboardSuccess,
  getSaverDashboardFailure,
  getSaverDashboardSuccess
} from "./actions";

//Include Both Helper File with needed methods
import {
  getMonthlyData,
  getWeeklyData,
  getYearlyData,
} from "../../helpers/fakebackend_helper";
import {
  getClientStats,
  getMicrofinanceStats,
  getSaverStats
} from "../../helpers/Api/AccountApi";

function* getChartsData({ payload: periodType }) {
  try {
    var response;
    if (periodType == "monthly") {
      response = yield call(getWeeklyData, periodType);
    }
    if (periodType == "yearly") {
      response = yield call(getYearlyData, periodType);
    }
    if (periodType == "weekly") {
      response = yield call(getMonthlyData, periodType);
    }

    yield put(apiSuccess(GET_CHARTS_DATA, response));
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error));
  }
}

function* fetchSaverDashboard() {
  try {
    const response = yield call(getSaverStats);
    if (response.success) {
      // let data = response.body;
      // data.chartInfo.series =  data.chatInfo.series.map((s, i) => {
      //   return { type: s.type, name: s.name, values: s.values.map((v,i)=> {
      //       return v;
      //     }) };
      // });
      yield put(getSaverDashboardSuccess(response.body));
    } else {
      yield put(getSaverDashboardFailure(response.message));
    }
  } catch (error) {
    yield put(getSaverDashboardFailure(error));
  }
}

function* fetchClientDashboard({ payload: { phone }}) {
  try {
    const response = yield call(getClientStats,phone);
    if (response.success) {

      yield put(getClientDashboardSuccess(response.body));
    } else {
      yield put(getClientDashboardFailure(response.message));
    }
  } catch (error) {
    yield put(getClientDashboardFailure(error));
  }
}

function* fetchMicrofinanceDashboard() {
  try {
    const response = yield call(getMicrofinanceStats);
    if (response.success) {
      yield put(getMicrofinanceDashboardSuccess(response.body));
    } else {
      yield put(getMicrofinanceDashboardFailure(response.message));
    }
  } catch (error) {
    yield put(getMicrofinanceDashboardFailure(error));
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData);
  yield takeEvery(GET_SAVER_DASHBOARD, fetchSaverDashboard);
  yield takeEvery(GET_CLIENT_DASHBOARD, fetchClientDashboard);
  yield takeEvery(GET_MICROFINANCE_DASHBOARD, fetchMicrofinanceDashboard);
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;
