import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes";
import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  userResetPasswordSuccess,
  userResetPasswordError,
} from "./actions";

import {
  postPasswordForgot,
  putResetForgot,
} from "../../../helpers/Api/authApi";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postPasswordForgot, {
      username: user.username,
    });
    if (response.success) {
      yield put(userForgetPasswordSuccess(response.message));
      history.push({
        pathname: "/reset-password",
        search: "?username=" + user.username,
      });
    } else {
      yield put(userForgetPasswordError(response.message));
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* resetUser({ payload: { user, history } }) {
  try {
    const response = yield call(putResetForgot, {
      username: user.username,
      password: user.password,
      code: user.code,
    });
    if (response.success) {
      yield put(userResetPasswordSuccess(response.message));
      location = "/dashboard";
    } else {
      yield put(userResetPasswordError(response.message));
    }
  } catch (error) {
    yield put(userResetPasswordError(error));
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
  yield takeEvery(RESET_PASSWORD, resetUser);
}

export default forgetPasswordSaga;
