import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  getProvider,
  makeIntermediateDeposit,
} from "../../store/wallet/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonLoader from "../../components/Common/ButtonLoader";

class ManagerCollect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      isMenu: false,
      manager: {},
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const { onGetProviders } = this.props;
    onGetProviders();

    if (this.props.location.state && this.props.location.state.manager) {
      this.setState({ manager: this.props.location.state.manager });
    } else {
      this.props.history.push("/collaborators/clients");
    }
  }

  render() {
    //meta title
    document.title = this.props.t("Deposit") + "  | loan2cash";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Wallet")}
              breadcrumbItem={this.props.t("Deposit")}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      {this.props.t("Deposit")}
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          amount: (this.state && this.state.amount) || "",
                          payerPhone:
                            (this.state && this.state.payerPhone) || "",
                          otp: (this.state && this.state.otp) || "",
                          providerCode:
                            (this.state && this.state.providerCode) || "",
                          ownerId:
                            (this.state &&
                              this.state.manager.user &&
                              this.state.manager.user.id) ||
                            "",
                        }}
                        validationSchema={Yup.object().shape({
                          amount: Yup.number()
                            .min(500)
                            .required("Please Enter Your amount"),
                          payerPhone: Yup.number().required(
                            "Please Enter Valid phone"
                          ),
                          otp: Yup.number()
                            .max(999999)
                            .required("Please Enter Valid OTP"),
                          providerCode: Yup.number().required(
                            "Please select payment method"
                          ),
                        })}
                        onSubmit={values => {
                          this.props.onMakeDeposit(values, this.props.history);
                        }}
                        validateOnChange={true}
                      >
                        {({ errors, status, touched, values, setValues }) => (
                          <Form>
                            {this.props.depositError &&
                            this.props.depositError ? (
                              <Alert color="danger">
                                {this.props.depositError}
                              </Alert>
                            ) : null}

                            {this.props.depositSuccess &&
                            this.props.depositSuccess ? (
                              <Alert color="success">
                                {this.props.depositSuccess}
                              </Alert>
                            ) : null}

                            <div className="mb-2">
                              <Label>Payment method :</Label>

                              <Row>
                                {this.props.providers.map((item, idx) => {
                                  if (item.label.includes("loan2cash")) {
                                    return "";
                                  }
                                  return (
                                    <Col xl="2" sm="4" key={idx}>
                                      <Label className="card-radio-label mb-3">
                                        <Input
                                          type="radio"
                                          name="provider"
                                          value={item.code}
                                          onChange={event =>
                                            setValues({
                                              ...values,
                                              providerCode: event.target.value,
                                            })
                                          }
                                          className={
                                            "card-radio-input" +
                                            (errors.providerCode &&
                                            touched.providerCode
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="providerCode"
                                          component="div"
                                          className="invalid-feedback"
                                        />

                                        <div className="card-radio">
                                          <img
                                            className="text-primary align-middle me-2"
                                            src={item.logo}
                                            height={24}
                                            alt={item.label}
                                          />{" "}
                                          <span>{item.label}</span>
                                        </div>
                                      </Label>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </div>

                            <div className="mb-3">
                              <Label>{this.props.t("Amount")} :</Label>

                              <Field
                                name="amount"
                                placeholder="EX : 500"
                                type="number"
                                autoComplete="true"
                                className={
                                  "form-control" +
                                  (errors.amount && touched.amount
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="amount"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label>{this.props.t("Payer Phone")} :</Label>

                              <Field
                                name="payerPhone"
                                placeholder="EX : 695499969"
                                type="number"
                                autoComplete="true"
                                className={
                                  "form-control" +
                                  (errors.payerPhone && touched.payerPhone
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="payerPhone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label>{this.props.t("OTP")} :</Label>

                              <Field
                                name="otp"
                                placeholder={this.props.t("Otp")}
                                type="number"
                                autoComplete="true"
                                className={
                                  "form-control" +
                                  (errors.otp && touched.otp
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="otp"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="text-center mt-4">
                              <Button type="submit" color="success">
                                <ButtonLoader
                                  loading={this.props.loadingDeposit}
                                />
                                {this.props.t("Make deposit")}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ManagerCollect.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  depositError: PropTypes.any,
  depositSuccess: PropTypes.any,
  manager: PropTypes.any,
  loadingDeposit: PropTypes.bool,
  type: PropTypes.string,
  providers: PropTypes.any,
  onGetProviders: PropTypes.func,
  onMakeDeposit: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  loadingDeposit: state.Wallet.loadingIntermediateDeposit,
  depositError: state.Wallet.depositIntermediateError,
  depositSuccess: state.Wallet.depositIntermediateSuccess,
  withdrawalError: state.Wallet.withdrawalError,
  providers: state.Wallet.providers,
});
const mapDispatchToProps = dispatch => ({
  onGetProviders: () => dispatch(getProvider()),
  onMakeDeposit: (data, history) =>
    dispatch(makeIntermediateDeposit(data, history)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ManagerCollect))
);
