import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { getAccount, getAccountName } from "../../../helpers/session_helper";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getBalance } from "../../../store/wallet/actions";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from "react-select";
import { subscribe } from "../../../store/manager/actions";

class WalletStats extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { onGetBalance } = this.props;
    onGetBalance();
    this.toggleSubscribeModal.bind(this);
  }

  toggleSubscribeModal = () => {
    this.setState(prevState => ({
      subscribeModal: !prevState.subscribeModal,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="d-flex align-items-start">
              <div className="me-4">
                <i className="mdi mdi-account-circle text-primary h1" />
              </div>

              <div className="flex-grow-1">
                <div className="text-muted">
                  <h5>{getAccountName()}</h5>
                  <p className="mb-1">{getAccount().phone}</p>
                  {/*<p className="mb-0">Id no: {this.props.wallet.id}</p>*/}
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody className="border-top">
            <Row>
              <Col sm="6">
                <div>
                  <p className="text-muted mb-2">
                    {this.props.t("Balance available")}
                  </p>
                  <h5>XAF {this.props.balance}</h5>
                </div>
              </Col>
              <Col sm="6">
                <div className="text-sm-end mt-4 mt-sm-0">
                  <p className="text-muted mb-2">Since last month</p>
                  <h5>
                    XAF{" "}
                    {this.props.wallet.balanceSinceLastMonthDifference > 0
                      ? "+"
                      : ""}{" "}
                    {this.props.wallet.balanceSinceLastMonthDifference}
                    {"  "}
                    <span
                      className={
                        "badge" +
                        (this.props.wallet.balanceSinceLastMonthDifference > 0
                          ? " bg-success"
                          : "bg-danger") +
                        " ms-1 align-bottom"
                      }
                    >
                      {this.props.wallet.balanceSinceLastMonthDifference > 0
                        ? "+"
                        : "-"}{" "}
                      {this.props.wallet.balanceSinceLastMonthDifferencePercent}{" "}
                      %
                    </span>
                  </h5>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border-top">
            <Row>
              <Col sm="6">
                <div>
                  <p className="text-muted mb-2">
                    {this.props.t("Account Manager")}
                  </p>
                  <div className="mt-3">
                    <Modal
                      isOpen={this.state.subscribeModal}
                      role="dialog"
                      autoFocus={true}
                      data-toggle="modal"
                      centered
                      toggle={this.toggleSubscribeModal}
                    >
                      <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                          <button
                            type="button"
                            className="btn-close"
                            onClick={() =>
                              this.setState({ subscribeModal: false })
                            }
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="text-center mb-4">
                            <div className="avatar-md mx-auto mb-4">
                              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                                <i className="mdi mdi-account"></i>
                              </div>
                            </div>

                            <div className="row justify-content-center">
                              <div className="col-xl-10">
                                <h4 className="text-primary">Subscribe !</h4>
                                <p className="text-muted font-size-14 mb-4">
                                  {this.props.t("Account Manager")}
                                </p>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    phone:
                                      (this.state && this.state.phone) || "",
                                  }}
                                  validationSchema={Yup.object().shape({
                                    phone: Yup.number().required(
                                      "Please enter manager phone"
                                    ),
                                  })}
                                  validateOnChange={true}
                                  onSubmit={values => {
                                    this.props.subscribeManager(values);
                                  }}
                                >
                                  {({
                                    errors,
                                    status,
                                    touched,
                                    setValues,
                                    values,
                                    isSubmitting,
                                  }) => (
                                    <Form>
                                      {this.props.subscribeError &&
                                      this.props.subscribeError ? (
                                        <Alert color="danger">
                                          {this.props.subscribeError}
                                        </Alert>
                                      ) : null}
                                      <div className="input-group  rounded bg-light">
                                        <Field
                                          type="tel"
                                          name="phone"
                                          className={
                                            "form-control bg-transparent border-0" +
                                            (errors.phone && touched.phone
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder={this.props.t("Phone")}
                                        />
                                        <ErrorMessage
                                          name="phone"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                        <button
                                          className="btn btn-success save-user"
                                          type="submit"
                                          id="button-addon2"
                                        >
                                          {this.props.loading ? (
                                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                          ) : (
                                            <i className="bx bxs-paper-plane"></i>
                                          )}
                                        </button>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <button
                      onClick={this.toggleSubscribeModal}
                      className="btn btn-primary btn-sm w-md"
                    >
                      {this.props.t("Invite")}
                    </button>
                  </div>
                </div>
              </Col>
              <Col sm="6">
                <div className="text-sm-end mt-4 mt-sm-0">
                  <p className="text-muted mb-2">Since last month</p>
                  <h5>
                    XAF{" "}
                    {this.props.wallet.balanceSinceLastMonthDifference > 0
                      ? "+"
                      : ""}{" "}
                    {this.props.wallet.balanceSinceLastMonthDifference}
                    {"  "}
                    <span
                      className={
                        "badge" +
                        (this.props.wallet.balanceSinceLastMonthDifference > 0
                          ? " bg-success"
                          : "bg-danger") +
                        " ms-1 align-bottom"
                      }
                    >
                      {this.props.wallet.balanceSinceLastMonthDifference > 0
                        ? "+"
                        : "-"}{" "}
                      {this.props.wallet.balanceSinceLastMonthDifferencePercent}{" "}
                      %
                    </span>
                  </h5>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="border-top">
            <p className="text-muted mb-4">In this month</p>
            <div className="text-center">
              <Row>
                <Col sm="4">
                  <div>
                    <div className="font-size-24 text-primary mb-2">
                      <i className="bx bx-money" />
                    </div>

                    <p className="text-muted mb-2">XPayLater</p>
                    <h5>XAF {this.props.wallet.xpayLater}</h5>

                    <div className="mt-3">
                      <Link to="#" className="btn btn-primary btn-sm w-md">
                        XPayLater
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="mt-4 mt-sm-0">
                    <div className="font-size-24 text-primary mb-2">
                      <i className="bx bx-import" />
                    </div>

                    <p className="text-muted mb-2">
                      {" "}
                      {this.props.t("Deposit")}
                    </p>
                    <h5>XAF {this.props.wallet.deposit}</h5>

                    <div className="mt-3">
                      <Link
                        to="/wallet/cash-in-out"
                        className="btn btn-primary btn-sm w-md"
                      >
                        {this.props.t("Deposit")}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col sm="4">
                  <div className="mt-4 mt-sm-0">
                    <div className="font-size-24 text-primary mb-2">
                      <i className="bx bx-export" />
                    </div>

                    <p className="text-muted mb-2">
                      {this.props.t("Withdrawal")}
                    </p>
                    <h5>XAF {this.props.wallet.withdrawal}</h5>

                    <div className="mt-3">
                      <Link
                        to={{
                          pathname: "/wallet/cash-in-out",
                          search: "",
                          hash: "#withdrawal",
                          state: { activeTab: "2" },
                        }}
                        className="btn btn-primary btn-sm w-md"
                      >
                        {this.props.t("Withdrawal")}
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

WalletStats.propTypes = {
  t: PropTypes.any,
  wallet: PropTypes.any,
  isMenu: PropTypes.bool,
  loading: PropTypes.bool,
  toggleMenu: PropTypes.func,
  balance: PropTypes.any,
  subscribeError: PropTypes.any,
  onGetBalance: PropTypes.func,
  subscribeManager: PropTypes.func,
};

const mapStateToProps = state => ({
  balance: state.Wallet.balance,
  subscribeError: state.Manager.error,
  loading: state.Manager.loading,
});

const mapDispatchToProps = dispatch => ({
  onGetBalance: () => dispatch(getBalance()),
  subscribeManager: data => dispatch(subscribe(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WalletStats))
);
