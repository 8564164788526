import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import PropTypes from "prop-types";
import MonthlyEarning from "./MonthlyEarning";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class ApexRadial extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chart: {
        height: 200,
        type: "radialBar",
        offsetY: -10,
      },
      options: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "13px",
                color: void 0,
                offsetY: 60,
              },
              value: {
                offsetY: 22,
                fontSize: "16px",
                color: void 0,
                formatter: function (e) {
                  return e + "%";
                },
              },
            },
          },
        },
        colors: ["#556ee6"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: !1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: [this.props.t("Deposit")],
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={[
            this.props.chartsData.balanceSinceLastMonthDifferencePercent,
          ]}
          type="radialBar"
          height="200"
          className="apex-charts"
        />
      </React.Fragment>
    );
  }
}

ApexRadial.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
};

export default withRouter(withTranslation()(ApexRadial));
