import * as url from "./backendUrl";
import { get, patch, post, put } from "../api_helper";

// Collector status Method
const getCollectorCollaborationRequestsSent = data =>
  get(url.GET_COLLECTOR_COLLABORATION_SENT, data);
const getClientsAsCollector = data =>
  get(url.GET_COLLECTOR_COLLABORATION_CLIENT, data);
const getCollectorCollaborationRequestsReceive = data =>
  get(url.GET_COLLECTOR_COLLABORATION_RECEIVE, { params: data });
const postCollectorCollaboration = data =>
  post(url.POST_COLLECTOR_COLLABORATION + "/" + data, null);
const patchCollectorCollaboration = data =>
  put(url.PATCH_COLLECTOR_COLLABORATION, null, { params: data });

export {
  getCollectorCollaborationRequestsSent,
  postCollectorCollaboration,
  getCollectorCollaborationRequestsReceive,
  patchCollectorCollaboration,
  getClientsAsCollector,
};
