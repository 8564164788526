import { call, put, takeEvery } from "redux-saga/effects";

//Account Redux states
import {
  REGISTER_MICROFINANCE,
  REGISTER_USER,
  REGISTER_VENDOR,
} from "./actionTypes";
import { registerUserFailed, registerUserSuccessful } from "./actions";

//Include Both Helper File with needed methods
import {
  postRegisterMicrofinance,
  postRegisterSaver,
  postRegisterVendor,
} from "../../../helpers/Api/authApi";

// Is user register successful then direct plot user in redux.
function* registerSaver({ payload: { user, history } }) {
  try {
    const response = yield call(postRegisterSaver, user);
    if (response.success) {
      yield put(registerUserSuccessful(response.body));
      history.push({
        pathname: "/username-verification",
        search: "?type=phone&username=" + response.body.phone,
      });
    } else {
      yield put(registerUserFailed(response.message));
    }
  } catch (error) {
    yield put(registerUserFailed("" + error));
  }
}

// Is user register successful then direct plot user in redux.
function* registerMicrofinance({ payload: { user, history } }) {
  try {
    const data = new FormData();

    for (const [key, value] of Object.entries(user)) {
      data.append(`${key}`, value);
    }

    const response = yield call(postRegisterMicrofinance, data);
    if (response.success) {
      yield put(registerUserSuccessful(response.body));
      console.log(response.body);
      history.push({
        pathname: "/username-verification",
        search: "?type=email&username=" + response.body.user.email,
      });
    } else {
      yield put(registerUserFailed(response.message));
    }
  } catch (error) {
    yield put(registerUserFailed("" + error));
  }
}

// Is user register successful then direct plot user in redux.
function* registerVendor({ payload: { user, history } }) {
  try {
    const data = new FormData();

    for (const [key, value] of Object.entries(user)) {
      data.append(`${key}`, value);
    }

    const response = yield call(postRegisterVendor, data);
    if (response.success) {
      yield put(registerUserSuccessful(response.body));
      console.log(response.body);
      history.push({
        pathname: "/username-verification",
        search: "?type=email&username=" + response.body.user.email,
      });
    } else {
      yield put(registerUserFailed(response.message));
    }
  } catch (error) {
    yield put(registerUserFailed("" + error));
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerSaver);
  yield takeEvery(REGISTER_MICROFINANCE, registerMicrofinance);
  yield takeEvery(REGISTER_VENDOR, registerVendor);
}

export default accountSaga;
