import {
  LOAN_REQUEST,
  LOAN_REQUEST_FAILURE,
  LOAN_REQUEST_SUCCESS,
  LOAN_REQUEST_UPDATE,
  LOAN_REQUEST_UPDATE_FAILURE,
  LOAN_REQUEST_UPDATE_SUCCESS
} from "./actionTypes";

export const receivedRequest = state => ({
  type: LOAN_REQUEST,
  payload: { state }
});

export const receivedRequestSuccess = body => ({
  type: LOAN_REQUEST_SUCCESS,
  payload: body
});

export const receivedRequestFailure = error => ({
  type: LOAN_REQUEST_FAILURE,
  payload: error
});

export const traitLoanRequest = (id, state, reason) => ({
  type: LOAN_REQUEST_UPDATE,
  payload: { id, state, reason }
});

export const traitLoanRequestSuccess = body => ({
  type: LOAN_REQUEST_UPDATE_SUCCESS,
  payload: body
});

export const traitLoanRequestFailure = error => ({
  type: LOAN_REQUEST_UPDATE_FAILURE,
  payload: error
});


