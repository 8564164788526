import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Alert, Col, Container, Label, Row } from "reactstrap";

// Redux
import { connect } from "react-redux";

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

//i18n
// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.apiError("");
  }

  render() {
    //meta title
    document.title = "Login | loan2cash";
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="/" className="d-block auth-logo">
                          <img
                            src={logodark}
                            alt=""
                            height="18"
                            className="auth-logo-dark"
                          />
                          <img
                            src={logolight}
                            alt=""
                            height="18"
                            className="auth-logo-light"
                          />
                        </Link>
                      </div>

                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p className="text-muted">
                            Sign in to continue to loan2cash.
                          </p>
                        </div>

                        <div className="mt-4">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              username:
                                (this.state && this.state.username) || "",
                              password:
                                (this.state && this.state.password) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              username: Yup.string().required(
                                "Please Enter Your username"
                              ),
                              password: Yup.string().required(
                                "Please Enter Valid Password"
                              ),
                            })}
                            onSubmit={values => {
                              this.props.loginUser(values, this.props.history);
                            }}
                          >
                            {({ errors, status, touched }) => (
                              <Form className="form-horizontal">
                                {this.props.error && this.props.error ? (
                                  <Alert color="danger">
                                    {this.props.error}
                                  </Alert>
                                ) : null}

                                <div className="mb-3">
                                  <Label for="email" className="form-label">
                                    Email or Phone
                                  </Label>
                                  <Field
                                    name="username"
                                    placeholder="Enter email or phone"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.username && touched.username
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mb-3">
                                  <div className="float-end">
                                    <Link
                                      to="/forgot-password"
                                      className="text-muted"
                                    >
                                      Forgot password?
                                    </Link>
                                  </div>
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <Field
                                    name="password"
                                    placeholder="Enter Password"
                                    type="password"
                                    autoComplete="true"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="remember-check"
                                  >
                                    Remember me
                                  </label>
                                </div>

                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    {this.props.loading ? (
                                      <i
                                        className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"
                                        if
                                      ></i>
                                    ) : (
                                      ""
                                    )}{" "}
                                    Log In{" "}
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>

                          <div className="mt-5 text-center">
                            <p>
                              Don&apos;t have an account ?{" "}
                              <a
                                href="register"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Signup now{" "}
                              </a>{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} loan2cash. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger"></i> by SAS 2
                          Cash Enterprise
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  error: PropTypes.any,
  apiError: PropTypes.any,
  user: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  const { error, loading, user } = state.Login;
  return { error, loading, user };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);
