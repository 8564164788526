export const GET_COUNTRY_RESOURCES = "GET_COUNTRY_RESOURCES";
export const GET_COUNTRY_RESOURCES_SUCCESS = "GET_COUNTRY_RESOURCES_SUCCESS";
export const GET_COUNTRY_RESOURCES_FAILURE = "GET_COUNTRY_RESOURCES_FAILURE";

export const GET_CITY_RESOURCES = "GET_CITY_RESOURCES";
export const GET_CITY_RESOURCES_SUCCESS = "GET_CITY_RESOURCES_SUCCESS";
export const GET_CITY_RESOURCES_FAILURE = "GET_CITY_RESOURCES_FAILURE";

export const GET_DISTRICT_RESOURCES = "GET_DISTRICT_RESOURCES";
export const GET_DISTRICT_RESOURCES_SUCCESS = "GET_DISTRICT_RESOURCES_SUCCESS";
export const GET_DISTRICT_RESOURCES_FAILURE = "GET_DISTRICT_RESOURCES_FAILURE";
