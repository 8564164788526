import {
  LOAN_MY_CLIENTS_FAILURE,
  LOAN_MY_CLIENTS_SUCCESS,
  LOAN_REQUEST,
  LOAN_REQUEST_FAILURE,
  LOAN_REQUEST_RECEIVE_FAILURE,
  LOAN_REQUEST_RECEIVE_SUCCESS,
  LOAN_REQUEST_SENT,
  LOAN_REQUEST_SENT_FAILURE,
  LOAN_REQUEST_SENT_SUCCESS,
  LOAN_REQUEST_SUCCESS,
  LOAN_REQUEST_UPDATE,
  LOAN_REQUEST_UPDATE_FAILURE,
  LOAN_REQUEST_UPDATE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  loanRequests: [],
  myClients: [],
  loanRequest: {},
  microfinanceList: [],
  loadingData: false,
  loadingMicrofinance: false,
  loadingSubscribe: false,
  loadingUpdate: false,
  close: false,
  error: "",
  statusError: "",
  updateError: "",
};

const loans = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAN_REQUEST:
      return {
        ...state,
        loadingSubscribe: true,
      };
    case LOAN_REQUEST_SUCCESS:
      return {
        ...state,
        loanRequests: action.payload,
        loadingSubscribe: false,
        close: true,
      };

    case LOAN_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingSubscribe: false,
      };
    case LOAN_REQUEST_UPDATE:
      return {
        ...state,
        loadingUpdate: true,
      };
    case LOAN_REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        loanRequests: [state.loanRequests],
        loadingUpdate: false,
        close: true,
      };

    case LOAN_REQUEST_UPDATE_FAILURE:
      return {
        ...state,
        updateError: action.payload,
        loadingUpdate: false,
      };

    case LOAN_REQUEST_RECEIVE_SUCCESS:
      return {
        ...state,
        loanRequests: action.payload,
      };

    case LOAN_REQUEST_RECEIVE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };


    case LOAN_MY_CLIENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case LOAN_MY_CLIENTS_SUCCESS:
      return {
        ...state,
        loadingData: false,
        myClients: action.payload,
      };

    case LOAN_REQUEST_SENT:
      return {
        ...state,
        loadingData: false,
      };

    case LOAN_REQUEST_SENT_SUCCESS:
      return {
        ...state,
        loadingData: false,
        loanRequests: action.payload,
      };

    case LOAN_REQUEST_SENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingData: false,
      };

    default:
      return state;
  }
};

export default loans;
