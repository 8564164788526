import { call, put, takeEvery } from "redux-saga/effects";
// Collector Redux States
import { LOAN_REQUEST, LOAN_REQUEST_SENT, LOAN_REQUEST_UPDATE } from "./actionTypes";
import {
  receivedRequestFailure,
  receivedRequestSuccess,
  traitLoanRequestFailure,
  traitLoanRequestSuccess
} from "./actions";

//Include Both Helper File with needed methods
import { getRequests, traitRequests } from "../../helpers/Api/loanApi";

function* getReceivedLoanRequests({ payload: { state } }) {
  try {
    const response = yield call(getRequests, {
      state: state
    });
    if (response.success) {
      yield put(receivedRequestSuccess(response.body));
    } else {
      yield put(receivedRequestFailure(response.message));
    }
  } catch (error) {
    yield put(receivedRequestFailure(error));
  }
}


function* update({ payload: { id, state, reason } }) {
  try {
    const response = yield call(traitRequests, {
      id: id,
      state: state,
      reason: reason
    });
    if (response.success) {
      yield put(traitLoanRequestSuccess(response.body));
      location.reload();
    } else {
      yield put(traitLoanRequestFailure(response.message));
    }
  } catch (error) {
    yield put(traitLoanRequestFailure(error));
  }
}

function* xpaylaterSaga() {
  yield takeEvery(LOAN_REQUEST, getReceivedLoanRequests);
  yield takeEvery(LOAN_REQUEST_UPDATE, update);
}

export default xpaylaterSaga;
