import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";

//Import Star Ratings
import StarRatings from "react-star-ratings";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import { getProductDetail } from "../../../store/e-commerce/actions";
import { withTranslation } from "react-i18next";

class EcommerceProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "0",
      product: {},
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.imageShow = this.imageShow.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      onGetProductDetail,
    } = this.props;
    if (params && params.id) {
      onGetProductDetail(params.id);
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  imageShow(img, id) {
    const expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = img;
  }

  render() {
    //meta title
    document.title = "XpayLater | loan2cash";

    const { product } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="XPayLater"
              breadcrumbItem={this.props.t("Product details")}
            />
            {!isEmpty(product) && (
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xl="6">
                          <div className="product-detai-imgs">
                            <Row>
                              <Col md="2" xs="3">
                                <Nav className="flex-column" pills>
                                  {!isEmpty(product.photos) &&
                                    product.photos.map((img, key) => (
                                      <NavItem key={key}>
                                        <NavLink
                                          className={classnames({
                                            active:
                                              this.state.activeTab === "0",
                                          })}
                                          onClick={() => {
                                            this.toggleTab(key);
                                          }}
                                        >
                                          <img
                                            src={img}
                                            alt=""
                                            onClick={() => {
                                              this.imageShow(img, key);
                                            }}
                                            className="img-fluid mx-auto d-block rounded"
                                          />
                                        </NavLink>
                                      </NavItem>
                                    ))}
                                </Nav>
                              </Col>
                              <Col md={{ size: 7, offset: 1 }} xs="9">
                                <TabContent activeTab={this.state.activeTab}>
                                  {!isEmpty(product.photos) &&
                                    product.photos.map((img, key) => (
                                      <TabPane tabId={key} key={key}>
                                        <div>
                                          <img
                                            src={img}
                                            alt=""
                                            id={"expandedImg" + key}
                                            className="img-fluid mx-auto d-block"
                                          />
                                        </div>
                                      </TabPane>
                                    ))}
                                </TabContent>
                                <div className="text-center">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn mt-2 me-1"
                                  >
                                    <i className="bx bx-cart me-2" />
                                    {this.props.t("Add to cart")}
                                  </Button>
                                  <Button
                                    type="button"
                                    color="success"
                                    className="ml-1 btn  mt-2"
                                  >
                                    <i className="bx bx-shopping-bag me-2" />
                                    {this.props.t("Buy now")}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col xl="6">
                          <div className="mt-4 mt-xl-3">
                            <Link to="#" className="text-primary">
                              {!isEmpty(product.categories) &&
                                product.categories.map((data, key) => (
                                  <span key={key}>{data.category.label}</span>
                                ))}
                            </Link>
                            <h4 className="mt-1 mb-3">{product.label}</h4>

                            <div className="text-muted float-start me-3">
                              <StarRatings
                                rating={4}
                                starRatedColor="#F1B44C"
                                starEmptyColor="#2D363F"
                                numberOfStars={5}
                                name="rating"
                                starDimension="14px"
                                starSpacing="3px"
                              />
                            </div>
                            <p className="text-muted mb-4">
                              ( {product.reviews} Customers Review )
                            </p>

                            {!!product.isOffer && (
                              <h6 className="text-success text-uppercase">
                                {product.offer} % Off
                              </h6>
                            )}
                            <h5 className="mb-4">
                              Price : <b>{product.price} XAF</b>
                            </h5>
                            <p className="text-muted mb-4">
                              {product.description}
                            </p>
                            <Row className="mb-3">
                              <Col md="6">
                                {product.features &&
                                  product.features.map((item, i) => (
                                    <div key={i}>
                                      <p className="text-muted">
                                        <i
                                          className={classnames(
                                            item.icon,
                                            " font-size-16 align-middle text-primary me-2"
                                          )}
                                        />
                                        {item.type && `${item.type}: `}
                                        {item.value}
                                      </p>
                                    </div>
                                  ))}
                              </Col>
                              <Col md="6">
                                {product.features &&
                                  product.features.map((item, i) => (
                                    <div key={i}>
                                      <p className="text-muted">
                                        <i
                                          className={classnames(
                                            item.icon,
                                            " font-size-16 align-middle text-primary me-2"
                                          )}
                                        />
                                        {item.type && `${item.type}:`}
                                        {item.value}
                                      </p>
                                    </div>
                                  ))}
                              </Col>
                            </Row>

                            <div className="product-color" hidden>
                              <h5 className="font-size-15">Color :</h5>
                              {product.colorOptions &&
                                product.colorOptions.map((option, key) => (
                                  <Link to="#" className="active" key={key}>
                                    <div className="product-color-item border rounded">
                                      <img
                                        src={productImages[option.image]}
                                        alt=""
                                        className="avatar-md"
                                      />
                                    </div>
                                    <p>{option.color}</p>
                                  </Link>
                                ))}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="mt-5" hidden>
                        <h5 className="mb-3">Specifications :</h5>

                        <div className="table-responsive">
                          <Table className="table mb-0 table-bordered">
                            <tbody>
                              {product.specification &&
                                product.specification.map(
                                  (specification, i) => (
                                    <tr key={i}>
                                      <th
                                        scope="row"
                                        style={{ width: "400px" }}
                                        className={"text-capitalize"}
                                      >
                                        {specification.type}
                                      </th>
                                      <td>{specification.value}</td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      {/*<Reviews hidden comments={product.comments} />*/}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {/*<RecentProducts hidden recentProducts={product.recentProducts} />*/}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

EcommerceProductDetail.propTypes = {
  product: PropTypes.object,
  match: PropTypes.object,
  onGetProductDetail: PropTypes.func,
  onAddToCart: PropTypes.func,
  t: PropTypes.any,
};

const mapStateToProps = ({ ecommerce }) => ({
  product: ecommerce.product,
});

const mapDispatchToProps = dispatch => ({
  onGetProductDetail: id => dispatch(getProductDetail(id)),
  onAddToCart: id => dispatch(getProductDetail(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(EcommerceProductDetail)));
