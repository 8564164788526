import {
  COLLECTOR_COLLABORATION_MY_CLIENTS_FAILURE,
  COLLECTOR_COLLABORATION_MY_CLIENTS_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST,
  COLLECTOR_COLLABORATION_REQUEST_FAILURE,
  COLLECTOR_COLLABORATION_REQUEST_RECEIVE_FAILURE,
  COLLECTOR_COLLABORATION_REQUEST_RECEIVE_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST_SENT,
  COLLECTOR_COLLABORATION_REQUEST_SENT_FAILURE,
  COLLECTOR_COLLABORATION_REQUEST_SENT_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST_STATUS_FAILURE,
  COLLECTOR_COLLABORATION_REQUEST_STATUS_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST_SUCCESS,
  COLLECTOR_COLLABORATION_REQUEST_UPDATE,
  COLLECTOR_COLLABORATION_REQUEST_UPDATE_FAILURE,
  COLLECTOR_COLLABORATION_REQUEST_UPDATE_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  collectorRequests: [],
  myClients: [],
  collectorRequest: {},
  microfinanceList: [],
  loadingData: false,
  loadingMicrofinance: false,
  loadingSubscribe: false,
  loadingUpdate: false,
  close: false,
  error: "",
  statusError: "",
  updateError: "",
};

const collectors = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COLLECTOR_COLLABORATION_REQUEST:
      return {
        ...state,
        loadingSubscribe: true,
      };
    case COLLECTOR_COLLABORATION_REQUEST_SUCCESS:
      return {
        ...state,
        collectorRequests: [...state.collectorRequests, action.payload],
        loadingSubscribe: false,
        close: true,
      };

    case COLLECTOR_COLLABORATION_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingSubscribe: false,
      };
    case COLLECTOR_COLLABORATION_REQUEST_UPDATE:
      return {
        ...state,
        loadingUpdate: true,
      };
    case COLLECTOR_COLLABORATION_REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        collectorRequests: [state.collectorRequests],
        loadingUpdate: false,
        close: true,
      };

    case COLLECTOR_COLLABORATION_REQUEST_UPDATE_FAILURE:
      return {
        ...state,
        updateError: action.payload,
        loadingUpdate: false,
      };

    case COLLECTOR_COLLABORATION_REQUEST_RECEIVE_SUCCESS:
      return {
        ...state,
        collectorRequests: action.payload,
      };

    case COLLECTOR_COLLABORATION_REQUEST_RECEIVE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case COLLECTOR_COLLABORATION_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
      };

    case COLLECTOR_COLLABORATION_MY_CLIENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case COLLECTOR_COLLABORATION_MY_CLIENTS_SUCCESS:
      return {
        ...state,
        loadingData: false,
        myClients: action.payload,
      };

    case COLLECTOR_COLLABORATION_REQUEST_STATUS_FAILURE:
      return {
        ...state,
        statusError: action.payload,
      };

    case COLLECTOR_COLLABORATION_REQUEST_SENT:
      return {
        ...state,
        loadingData: false,
      };

    case COLLECTOR_COLLABORATION_REQUEST_SENT_SUCCESS:
      return {
        ...state,
        loadingData: false,
        collectorRequests: action.payload,
      };

    case COLLECTOR_COLLABORATION_REQUEST_SENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loadingData: false,
      };

    default:
      return state;
  }
};

export default collectors;
