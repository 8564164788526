import {
  MANAGER_SUBSCRIBE,
  MANAGER_SUBSCRIBE_FAILURE,
  MANAGER_SUBSCRIBE_SUCCESS,
} from "./actionTypes";

export const subscribe = data => ({
  type: MANAGER_SUBSCRIBE,
  payload: { data },
});

export const subscribeSuccess = body => ({
  type: MANAGER_SUBSCRIBE_SUCCESS,
  payload: body,
});

export const subscribeFailure = error => ({
  type: MANAGER_SUBSCRIBE_FAILURE,
  payload: error,
});
