import { call, put, takeEvery } from "redux-saga/effects";
import { setAffiliate } from "../../helpers/session_helper";
// Saver Redux States
import {
  SAVER_AFFILIATE,
  SAVER_AFFILIATE_RECEIVE,
  SAVER_AFFILIATE_SENT,
  SAVER_AFFILIATE_STATUS,
  SAVER_AFFILIATE_UPDATE,
  SAVER_MICROFINANCE_LIST,
} from "./actionTypes";
import {
  affiliateFailure,
  affiliateStatusFailure,
  affiliateStatusSuccess,
  affiliateSuccess,
  getMicrofinanceListFailure,
  getMicrofinanceListSuccess,
  receiveAffiliateRequestsFailure,
  receiveAffiliateRequestsSuccess,
  sentAffiliateRequestsFailure,
  sentAffiliateRequestsSuccess,
  traiteRequestFailure,
  traiteRequestSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getMicrofinance,
  getSaverAffiliateRequestsReceive,
  getSaverAffiliateRequestsSent,
  getSaverAffiliateStatus,
  patchSaverAffiliate,
  postSaverAffiliate,
} from "../../helpers/Api/saverApi";

function* saverAffiliateStatus() {
  try {
    const response = yield call(getSaverAffiliateStatus);
    if (response.success) {
      setAffiliate();
      yield put(affiliateStatusSuccess(response.body));
    } else {
      yield put(affiliateStatusFailure(response.message));
    }
  } catch (error) {
    yield put(affiliateStatusFailure(error));
  }
}

function* getSentAffiliateRequests() {
  try {
    const response = yield call(getSaverAffiliateRequestsSent);
    if (response.success) {
      yield put(sentAffiliateRequestsSuccess(response.body));
    } else {
      yield put(sentAffiliateRequestsFailure(response.message));
    }
  } catch (error) {
    yield put(sentAffiliateRequestsFailure(error));
  }
}

function* getReceiveAffiliateRequests({ payload: { state } }) {
  try {
    const response = yield call(getSaverAffiliateRequestsReceive, {
      state: state,
    });
    if (response.success) {
      yield put(receiveAffiliateRequestsSuccess(response.body));
    } else {
      yield put(receiveAffiliateRequestsFailure(response.message));
    }
  } catch (error) {
    yield put(receiveAffiliateRequestsFailure(error));
  }
}

function* getMicrofinanceList() {
  try {
    const response = yield call(getMicrofinance);
    if (response.success) {
      const items = response.body.map((item, i) => {
        return { label: item.socialReason, value: item.id, key: item.id };
      });
      yield put(getMicrofinanceListSuccess(items));
    } else {
      yield put(getMicrofinanceListFailure(response.message));
    }
  } catch (error) {
    yield put(getMicrofinanceListFailure(error));
  }
}

function* subscribe({ payload: { microfinanceId } }) {
  try {
    const response = yield call(postSaverAffiliate, {
      microfinanceId: microfinanceId,
    });
    if (response.success) {
      yield put(affiliateSuccess(response.body));
    } else {
      yield put(affiliateFailure(response.message));
    }
  } catch (error) {
    yield put(affiliateFailure(error));
  }
}

function* update({ payload: { id, state, reason } }) {
  try {
    const response = yield call(patchSaverAffiliate, {
      id: id,
      state: state,
      reason: reason,
    });
    if (response.success) {
      yield put(traiteRequestSuccess(response.body));
    } else {
      yield put(traiteRequestFailure(response.message));
    }
  } catch (error) {
    yield put(traiteRequestFailure(error));
  }
}

function* saverSaga() {
  yield takeEvery(SAVER_AFFILIATE_STATUS, saverAffiliateStatus);
  yield takeEvery(SAVER_AFFILIATE_SENT, getSentAffiliateRequests);
  yield takeEvery(SAVER_AFFILIATE_RECEIVE, getReceiveAffiliateRequests);
  yield takeEvery(SAVER_MICROFINANCE_LIST, getMicrofinanceList);
  yield takeEvery(SAVER_AFFILIATE, subscribe);
  yield takeEvery(SAVER_AFFILIATE_UPDATE, update);
}

export default saverSaga;
