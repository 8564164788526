/* WALLET */
export const GET_TRANSACTION_HISTORY = "GET_TRANSACTION_HISTORY";
export const GET_TRANSACTION_HISTORY_SUCCESS = "GET_TRANSACTION_HISTORY_SUCCESS";
export const GET_TRANSACTION_HISTORY_FAIL = "GET_TRANSACTION_HISTORY_FAIL";

export const GET_CLIENT_TRANSACTION_HISTORY = "GET_CLIENT_TRANSACTION_HISTORY";
export const GET_CLIENT_TRANSACTION_HISTORY_SUCCESS = "GET_CLIENT_TRANSACTION_HISTORY_SUCCESS";
export const GET_CLIENT_TRANSACTION_HISTORY_FAIL = "GET_CLIENT_TRANSACTION_HISTORY_FAIL";
/* BALANCE */
export const GET_BALANCE = "GET_BALANCE";
export const GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS";
export const GET_BALANCE_FAIL = "GET_BALANCE_FAIL";
/* WITHDRAWAL */
export const MAKE_DEPOSIT = "MAKE_DEPOSIT";
export const MAKE_DEPOSIT_SUCCESS = "MAKE_DEPOSIT_SUCCESS";
export const MAKE_DEPOSIT_FAIL = "MAKE_DEPOSIT_FAIL";
/* WITHDRAWAL */
export const MAKE_INTERMEDIATE_DEPOSIT = "MAKE_INTERMEDIATE_DEPOSIT";
export const MAKE_INTERMEDIATE_DEPOSIT_SUCCESS =
  "MAKE_INTERMEDIATE_DEPOSIT_SUCCESS";
export const MAKE_INTERMEDIATE_DEPOSIT_FAIL = "MAKE_INTERMEDIATE_DEPOSIT_FAIL";
/* WITHDRAWAL */
export const MAKE_WITHDRAWAL_REQUEST = "POST_WITHDRAWAL_REQUEST";
export const MAKE_WITHDRAWAL_REQUEST_SUCCESS =
  "POST_WITHDRAWAL_REQUEST_SUCCESS";
export const MAKE_WITHDRAWAL_REQUEST_FAIL = "POST_WITHDRAWAL_REQUEST_FAIL";
/* FETCH WITHDRAWAL REQUEST */
export const GET_WITHDRAWAL_REQUEST = "GET_WITHDRAWAL_REQUEST";
export const GET_WITHDRAWAL_REQUEST_SUCCESS = "GET_WITHDRAWAL_REQUEST_SUCCESS";
export const GET_WITHDRAWAL_REQUEST_FAIL = "GET_WITHDRAWAL_REQUEST_FAIL";
/* FETCH WITHDRAWAL REQUEST */
export const GET_MICROFINANCE_WITHDRAWAL_REQUEST =
  "GET_MICROFINANCE_WITHDRAWAL_REQUEST";
export const GET_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS =
  "GET_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS";
export const GET_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL =
  "GET_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL";
/* TRAIT WITHDRAWAL REQUEST */
export const TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST =
  "TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST";
export const TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS =
  "TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_SUCCESS";
export const TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL =
  "TRAIT_MICROFINANCE_WITHDRAWAL_REQUEST_FAIL";
/* FETCH PAYMENT PROVIDER */
export const GET_PAYMENT_PROVIDER = "GET_PAYMENT_PROVIDER";
export const GET_PAYMENT_PROVIDER_SUCCESS = "GET_PAYMENT_PROVIDER_SUCCESS";
export const GET_PAYMENT_PROVIDER_FAIL = "GET_PAYMENT_PROVIDER_FAIL";
