import * as url from "./backendUrl";
import { del, get, patch, post, put } from "../api_helper";

// Collector status Method
const getMyCommittees = data =>
  get(url.GET_MONTHLY_COMMITTEE, data);
const createMyCommittee = data =>
  post(url.POST_MONTHLY_COMMITTEE, data);
const deleteMyCommittee = id =>
  del(url.DELETE_MONTHLY_COMMITTEE + "/" + id, null);
const updateMyCommittee = data =>
  put(url.PATCH_MONTHLY_COMMITTEE+"/"+data.id, data);

export {
  getMyCommittees,
  createMyCommittee,
  deleteMyCommittee,
  updateMyCommittee
};
