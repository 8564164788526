//AUTH LOGIN
export const POST_LOGIN = "/auth/v1/login";
//AUTH REGISTER
export const POST_REGISTER_SAVER = "/auth/v1/register";
export const POST_REGISTER_MICROFINANCE = "/auth/v1/register/microfinance";
export const POST_REGISTER_VENDOR = "/auth/v1/register/vendor";
//AUTH PASSWORD
export const POST_PASSWORD_FORGOT = "/auth/v1/password/forgot";
export const PUT_PASSWORD_RESET = "/auth/v1/password/reset";
//AUTH VERIFICATION
export const PUT_VERIFY_ACCOUNT = "/auth/v1/verify/account";
export const POST_VERIFY_ACCOUNT_CLAIM = "/auth/v1/verify/account/claim";

//RESOURCES
export const GET_COUNTRIES = "/country/resource";
export const GET_CITIES = "/city/resource";
export const GET_DISTRICTS = "/district/resource";
export const GET_MICROFINANCE = "/microfinance/resource";

//SAVER
export const POST_SAVER_AFFILIATE = "/saver/affiliate";
export const POST_MANAGER_ASSIGN = "/collector/manager/assign";
export const PATCH_SAVER_AFFILIATE = "/saver/affiliate";
export const GET_SAVER_AFFILIATE_SENT = "/saver/affiliate/sent";
export const GET_SAVER_AFFILIATE_RECEIVE = "/saver/affiliate/receive";
export const GET_SAVER_AFFILIATE_STATUS = "/saver/affiliate/status";

//XPAYLATER
export const GET_XPAYLATER_PRODUCTS = "/merchandise";
export const GET_XPAYLATER_LOAN_REQUESTS = "/loan/client/requests";
export const PUT_XPAYLATER_LOAN_REQUEST = "/loan/trait";

//COLLECTOR
export const POST_COLLECTOR_COLLABORATION = "/collector";
export const PATCH_COLLECTOR_COLLABORATION = "/microfinance/collector/treat";
export const GET_COLLECTOR_COLLABORATION_SENT = "/collector/requests";
export const GET_COLLECTOR_COLLABORATION_CLIENT = "/collector/clients";
export const GET_COLLECTOR_COLLABORATION_RECEIVE =
  "/microfinance/collector/requests";

//MONTHLY COMMITTEE
export const POST_MONTHLY_COMMITTEE = "/monthly-committee";
export const PATCH_MONTHLY_COMMITTEE = "/monthly-committee";
export const GET_MONTHLY_COMMITTEE = "/monthly-committee";
export const DELETE_MONTHLY_COMMITTEE = "/monthly-committee";

//ACCOUNT
export const GET_ACCOUNT_ME = "/account/me";
export const GET_ACCOUNT_STATS = "/account/stats/saver";
export const GET_MICROFINANCE_STATS = "/account/stats/microfinance";
export const GET_PARTNER_STATS = "/account/stats/vendor";
export const POST_ACCOUNT_LOGOUT = "/account/logout";

//WALLET
export const GET_TRANSACTIONS = "/saving/transactions";
export const GET_CLIENT_TRANSACTIONS = "/saving/transactions/client";
export const GET_BALANCE = "/saving/balance";
export const POST_SELF_DEPOSIT = "/saving/deposit/self";
export const POST_MANAGER_DEPOSIT = "/saving/deposit/intermediate";
export const POST_WITHDRAWAL_REQUEST = "/saving/withdrawal/request";
export const GET_WITHDRAWAL_REQUESTS = "/saving/withdrawal/requests";
export const GET_PAYMENT_PROVIDERS = "/payment-provider/resource";

export const GET_MICROFINANCE_WITHDRAWAL_REQUESTS =
  "/microfinance/withdrawal/requests";
export const PATCH_MICROFINANCE_WITHDRAWAL_REQUEST_TREAT =
  "/microfinance/withdrawal/treat";
