export const COMMITTEE = "COMMITTEE";
export const COMMITTEE_SUCCESS = "COMMITTEE_SUCCESS";
export const COMMITTEE_FAILURE = "COMMITTEE_FAILURE";

export const COMMITTEE_UPDATE = "COMMITTEE_UPDATE";
export const COMMITTEE_UPDATE_SUCCESS = "COMMITTEE_UPDATE_SUCCESS";
export const COMMITTEE_UPDATE_FAILURE = "COMMITTEE_UPDATE_FAILURE";


export const COMMITTEE_CREATE = "COMMITTEE_CREATE";
export const COMMITTEE_CREATE_SUCCESS = "COMMITTEE_CREATE_SUCCESS";
export const COMMITTEE_CREATE_FAILURE = "COMMITTEE_CREATE_FAILURE";

export const COMMITTEE_DELETE = "COMMITTEE_DELETE";
export const COMMITTEE_DELETE_SUCCESS = "COMMITTEE_DELETE_SUCCESS";
export const COMMITTEE_DELETE_FAILURE = "COMMITTEE_DELETE_FAILURE";
