import {
  API_SUCCESS,
  API_FAIL,
  GET_CHARTS_DATA,
  GET_SAVER_DASHBOARD,
  GET_SAVER_DASHBOARD_SUCCESS,
  GET_SAVER_DASHBOARD_FAIL,
  GET_MICROFINANCE_DASHBOARD,
  GET_MICROFINANCE_DASHBOARD_SUCCESS,
  GET_MICROFINANCE_DASHBOARD_FAIL, GET_CLIENT_DASHBOARD, GET_CLIENT_DASHBOARD_SUCCESS, GET_CLIENT_DASHBOARD_FAIL
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
});

export const getSaverDashboard = () => ({
  type: GET_SAVER_DASHBOARD,
});

export const getSaverDashboardSuccess = data => ({
  type: GET_SAVER_DASHBOARD_SUCCESS,
  payload: data,
});

export const getSaverDashboardFailure = error => ({
  type: GET_SAVER_DASHBOARD_FAIL,
  payload: error,
});

export const getClientDashboard = phone => ({
  type: GET_CLIENT_DASHBOARD,
  payload: { phone },
});

export const getClientDashboardSuccess = data => ({
  type: GET_CLIENT_DASHBOARD_SUCCESS,
  payload: data,
});

export const getClientDashboardFailure = error => ({
  type: GET_CLIENT_DASHBOARD_FAIL,
  payload: error,
});

export const getMicrofinanceDashboard = () => ({
  type: GET_MICROFINANCE_DASHBOARD,
});

export const getMicrofinanceDashboardSuccess = data => ({
  type: GET_MICROFINANCE_DASHBOARD_SUCCESS,
  payload: data,
});

export const getMicrofinanceDashboardFailure = error => ({
  type: GET_MICROFINANCE_DASHBOARD_FAIL,
  payload: error,
});
