import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { VERIFY_ACCOUNT, VERIFY_ACCOUNT_CLAIM } from "./actionTypes";
import {
  verifyAccountClaimSuccess,
  verifyAccountSuccess,
  verifyError,
} from "./actions";

//Include Both Helper File with needed methods
import {
  handleToken,
  postAccountVerificationClaim,
  putAccountVerification,
  setAuthenticatedUser,
} from "../../../helpers/Api/authApi";

function* verifyAccount({ payload: { username, code, history } }) {
  try {
    const response = yield call(putAccountVerification, {
      code: code,
      username: username,
    });
    if (response.success) {
      setAuthenticatedUser(response.body);
      handleToken(response.token);
      yield put(verifyAccountSuccess(response.body));
      location = "/dashboard";
    } else {
      yield put(verifyError(response.message));
    }
  } catch (error) {
    yield put(verifyError(error));
  }
}

function* verifyAccountClaim({ payload: { username } }) {
  try {
    const response = yield call(postAccountVerificationClaim, {
      username: username,
    });
    if (response.success) {
      yield put(verifyAccountClaimSuccess(response.message));
    } else {
      yield put(verifyError(response.message));
    }
  } catch (error) {
    yield put(verifyError(error));
  }
}

function* authSaga() {
  yield takeEvery(VERIFY_ACCOUNT, verifyAccount);
  yield takeEvery(VERIFY_ACCOUNT_CLAIM, verifyAccountClaim);
}

export default authSaga;
