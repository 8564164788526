import axios from "axios";
import { isUserAuthenticated, logoutAccount } from "./session_helper";
import i18n from "../i18n";

//apply base url for axios
const API_URL = process.env.REACT_APP_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

//Set token
axiosApi.defaults.headers.common["Authorization"] = getToken();

//Set language header
axiosApi.defaults.headers.common["Accept-Language"] = i18n.language;

axiosApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    let data = response.data;
    if (data.code >= 1 && data.code <= 10) {
      logoutAccount();
      location.reload();
    }
    return response;
  },
  function (error, status) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error, status);
    if (error.toString().includes("403")) {
      console.log(403);
      logoutAccount();
      location.reload();
    }
    return Promise.reject("" + error);
  }
);

export function handleToken(token) {
  localStorage.setItem("token", token);
}

export function getToken() {
  return isUserAuthenticated() ? "Bearer " + localStorage.getItem("token") : "";
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function postForm(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
