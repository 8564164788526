import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
// datatable related plugins
import "./datatables.scss";
import React, { Component } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { committeeCreate, committeeDelete, committees, committeeUpdate } from "../../store/committee/actions";

// datatable related plugins
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

class CommitteesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      formModal: false,
      formModalEdit: false,
      showInfo: false
    };
    this.toggleRequest = this.toggleRequest.bind(this);
  }

  componentDidMount() {
    const { onGetCommittees } = this.props;
    onGetCommittees();
  }

  toggleRequest() {
    this.setState({
      formModalEdit: !this.state.formModalEdit
    });
  }

  handleRequestClick = committee => {
    this.setState({
      committee: committee
    });
    console.log(committee);
    this.toggleRequest();
  };

  render() {
    const { SearchBar } = Search;

    let columns = [
      {
        dataField: "id",
        text: "#Ref",
        sort: true,
        formatter: (cellContent, row, idx) => (
          <>
            <span className="text-body fw-bold">#COM{row.id}</span>
          </>
        )
      },
      {
        dataField: "value",
        text: this.props.t("Value"),
        sort: true
      },
      {
        dataField: "minAmount",
        text: this.props.t("Minimum Amount"),
        formatter: (cellContent, row) => {
          return row.minAmount + " XAF";
        },
        sort: true
      },
      {
        dataField: "maxAmount",
        text: this.props.t("Max Amount"),
        formatter: (cellContent, row) => {
          return row.maxAmount + " XAF";
        },
        sort: true
      },
      {
        dataField: "type",
        text: this.props.t("Type"),
        sort: true,
        formatter: (cellContent, row) => {
          let color = "danger";
          switch (row.type) {
            case "PERCENTAGE":
              color = "info";
              break;
            case "FIXED_PRICE":
              color = "success";
              break;
          }
          return (
            <Badge
              className={"font-size-12 badge-soft-" + color}
              color={color}
              pill
            >
              {this.props.t(row.type)}
            </Badge>
          );
        }
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        formatter: (cellContent, committee) => (
          <>
            <Button className="btn-success"  onClick={() => this.handleRequestClick(committee)}>
              <i
                className="mdi mdi-pencil font-size-12"
                id="update"

              />
            </Button>
          </>
        )
      }
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "desc"
      }
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.committees.length, // replace later with size(customers),
      custom: true
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Settings")}
              breadcrumbItem={this.props.t("Committee")}
            />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Col md={3} className="float-end">
                      <Button className="btn-success"    onClick={() => this.handleRequestClick()}>
                        <i
                          className="mdi mdi-plus font-size-12"
                          id="create"
                        />
                        {this.props.t("Create New")}
                      </Button>
                    </Col>
                    <CardTitle className="h4">
                      {this.props.t("Committee")}
                    </CardTitle>
                    <p className="card-title-desc"> </p>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.committees}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.props.committees}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={true}
                                      defaultSorted={defaultSorted}
                                      //selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Modal
                                isOpen={this.state.formModalEdit}
                                className={this.props.className}
                              >
                                <ModalHeader toggle={this.toggleRequest} tag="h4">
                                  {this.props.t("Committee")}
                                </ModalHeader>
                                <ModalBody>
                                  <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                      minAmount: (this.state && this.state.committee && this.state.committee.minAmount) || "",
                                      maxAmount: (this.state && this.state.committee && this.state.committee.maxAmount) || "",
                                      type: (this.state && this.state.committee && this.state.committee.type) || "",
                                      value: (this.state && this.state.committee && this.state.committee.value) || ""

                                    }}
                                    validationSchema={Yup.object().shape({
                                      type: Yup.string().required(
                                        "Please Select a status"
                                      ),
                                      value: Yup.number().when(["type"], {
                                        is: type => type === "PERCENTAGE",
                                        then: Yup.number()
                                          .min(0)
                                          .max(100)
                                          .required()
                                      }),
                                      minAmount: Yup.number().min(0).required(),
                                      maxAmount: Yup.number().min(0).required()
                                    })}
                                    validateOnChange={true}
                                    onSubmit={values => {
                                      if (this.state.committee === undefined) {
                                        this.props.onCreateCommittee(values);
                                      } else
                                        this.props.onUpdateCommittee(
                                          { ...values, id: this.state.committee.id }
                                        );
                                    }}
                                  >
                                    {({
                                        errors,
                                        status,
                                        touched,
                                        setValues,
                                        values
                                      }) => (
                                      <Form>
                                        {this.props.error &&
                                        this.props.error ? (
                                          <Alert color="danger">
                                            {this.props.error}
                                          </Alert>
                                        ) : null}
                                        <Row>
                                          <p className="text-muted m-b-15">
                                            Les commissions dont les montants débités automatiquement des comptes de vos
                                            épargnants.<br />
                                            {" "}<code>La valeur</code>{" "}
                                            peut-être soit <code>fixe (ex : 500 qui représente le montant
                                            net)</code> ou <code>un pourcentage (ex : 2 qui représente le
                                            pourcentage)</code>, qui sera prélevé,
                                            suivant le cumul des dépôts compris dans la plage Min et Max fais sur un
                                            mois.
                                          </p>
                                          <Col className="col-6">
                                            <div className="mb-3">
                                              <Label>
                                                {this.props.t("Value")}
                                              </Label>
                                              <Field
                                                name="value"
                                                placeholder="EX : 2"
                                                type="number"
                                                autoComplete="true"
                                                className={
                                                  "form-control" +
                                                  (errors.value && touched.value
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name="value"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Col>
                                          <Col className="col-6">
                                            <div className="mb-3">
                                              <Label>{this.props.t("State")}</Label>
                                              <Select
                                                onChange={state =>
                                                  setValues({
                                                    ...values,
                                                    type: state.value
                                                  })
                                                }
                                                options={[
                                                  {
                                                    label: this.props.t("PERCENTAGE"),
                                                    value: "PERCENTAGE"
                                                  },
                                                  {
                                                    label: this.props.t("FIXED_PRICE"),
                                                    value: "FIXED_PRICE"
                                                  }
                                                ]}
                                                value={this.state.type}
                                                classNamePrefix="select2-selection"
                                                className={
                                                  errors.type && touched.type
                                                    ? " is-invalid"
                                                    : ""
                                                }
                                              />
                                              <ErrorMessage
                                                name="type"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Col>
                                          <Col className="col-6">
                                            <div className="mb-3">
                                              <Label>
                                                {this.props.t("Minimum Amount")}
                                              </Label>
                                              <Field
                                                name="minAmount"
                                                placeholder="EX : 500"
                                                type="number"
                                                autoComplete="true"
                                                className={
                                                  "form-control" +
                                                  (errors.minAmount && touched.minAmount
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name="minAmount"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Col>
                                          <Col className="col-6">
                                            <div className="mb-3">
                                              <Label>
                                                {this.props.t("Max Amount")}
                                              </Label>
                                              <Field
                                                name="maxAmount"
                                                placeholder="EX : 10000"
                                                type="number"
                                                autoComplete="true"
                                                className={
                                                  "form-control" +
                                                  (errors.maxAmount && touched.maxAmount
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name="maxAmount"
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="text-end">
                                              <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                              >
                                                {this.props.loading ? (
                                                  <i
                                                    className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"
                                                    if
                                                  ></i>
                                                ) : (
                                                  ""
                                                )}
                                                {this.props.t("Save")}
                                              </button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form>
                                    )}
                                  </Formik>
                                </ModalBody>
                              </Modal>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

CommitteesView.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  t: PropTypes.any,
  committees: PropTypes.any,
  onGetCommittees: PropTypes.func,
  onCreateCommittee: PropTypes.func,
  onUpdateCommittee: PropTypes.func,
  onDeleteCommittee: PropTypes.func
};

const mapStateToProps = state => ({
  committees: state.Committee.committees,
  loading: state.Committee.loading,
  error: state.Committee.error,
  formModalEdit: state.Committee.error
});
const mapDispatchToProps = dispatch => ({
  onGetCommittees: () => dispatch(committees()),
  onCreateCommittee: (committee) => dispatch(committeeCreate(committee)),
  onUpdateCommittee: (committee) => dispatch(committeeUpdate(committee)),
  onDeleteCommittee: (committeeId) => dispatch(committeeDelete(committeeId))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(CommitteesView))
);
