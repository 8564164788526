import { call, put, takeEvery } from "redux-saga/effects";
// Collector Redux States
import { COMMITTEE, COMMITTEE_CREATE, COMMITTEE_DELETE, COMMITTEE_UPDATE } from "./actionTypes";
import {
  committeeCreateFailure,
  committeeCreateSuccess,
  committeeDeleteFailure,
  committeeDeleteSuccess,
  committeesFailure,
  committeesSuccess,
  committeeUpdateFailure,
  committeeUpdateSuccess
} from "./actions";

//Include Both Helper File with needed methods
import {
  createMyCommittee,
  deleteMyCommittee,
  getMyCommittees,
  updateMyCommittee
} from "../../helpers/Api/committeeApi";

function* getCommittees() {
  try {
    const response = yield call(getMyCommittees);
    if (response.success) {
      yield put(committeesSuccess(response.body));
    } else {
      yield put(committeesFailure(response.message));
    }
  } catch (error) {
    yield put(committeesFailure(error));
  }
}

function* deleteCommittee({ payload: { microfinanceId } }) {
  try {
    const response = yield call(deleteMyCommittee, microfinanceId);
    if (response.success) {
      yield put(committeeDeleteSuccess(response.body));
      location.reload();
    } else {
      yield put(committeeDeleteFailure(response.message));
    }
  } catch (error) {
    yield put(committeeDeleteFailure(error));
  }
}

function* updateCommittee({ payload: { committee } }) {
  try {
    const response = yield call(updateMyCommittee, committee);
    if (response.success) {
      yield put(committeeUpdateSuccess(response.body));
      location.reload();
    } else {
      yield put(committeeUpdateFailure(response.message));
    }
  } catch (error) {
    yield put(committeeUpdateFailure(error));
  }
}

function* createCommittee({ payload: { committee } }) {
  try {
    const response = yield call(createMyCommittee, committee);
    if (response.success) {
      yield put(committeeCreateSuccess(response.body));
      location.reload();
    } else {
      yield put(committeeCreateFailure(response.message));
    }
  } catch (error) {
    yield put(committeeCreateFailure(error));
  }
}

function* committeeSaga() {
  yield takeEvery(COMMITTEE, getCommittees);
  yield takeEvery(COMMITTEE_CREATE, createCommittee);
  yield takeEvery(COMMITTEE_UPDATE, updateCommittee);
  yield takeEvery(COMMITTEE_DELETE, deleteCommittee);
}

export default committeeSaga;
